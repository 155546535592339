define("apollo/services/route-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    async createTransportFromRoute(route) {
      const transport = this.get('store').createRecord('transport', {
        transportType: route.get('transportType'),
        routeTemplate: route
      });
      const defaultTruckTypes = await this.store.query('truckType', {
        transportTypeId: transport.get('transportType.id')
      });
      transport.set('truckTypes', defaultTruckTypes);

      this._createAdvice(transport);

      this._createSteps(transport);

      this._createCustomFields(transport);

      return transport;
    },

    _createAdvice(transport) {
      const transportType = transport.get('transportType');

      if (transportType.get('requiresTimeWindow') && !transportType.get('adviceSeparatedFromWindow') || transportType.get('adviceSavedWithTransport')) {
        const advice = this.get('store').createRecord('advice', {
          transport
        });
        transport.set('advice', advice);
      }
    },

    _createSteps(transport) {
      transport.get('routeTemplate').get('routeAddresses').forEach(ra => {
        const step = this.get('store').createRecord('step', {
          transport,
          shouldHaveContractor: true,
          stepTypeName: ra.get('stepType'),
          idx: ra.get('idx'),
          company: ra.get('company'),
          address: ra.get('address'),
          orderInTransport: ra.get('idx')
        });
        step.setArrivalDate();
        transport.get('steps').pushObject(step);
      });
    },

    _createCustomFields(transport) {
      transport.get('routeTemplate').get('routeCustomFields').forEach(rcf => {
        const cf = this.get('store').createRecord('customFieldValue', {
          transport,
          definition: rcf.get('definition'),
          value: rcf.get('value'),
          createdFromRoute: true
        });

        if (rcf.get('option.id')) {
          cf.set('option', rcf.get('option'));
        }

        transport.get('customFields').pushObject(cf);
      });
    }

  });

  _exports.default = _default;
});