define("apollo/pods/components/planning/planned-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    modalService: Ember.inject.service('modal-service'),
    intl: Ember.inject.service(),
    deliveryService: Ember.inject.service('delivery-service'),
    store: Ember.inject.service(),
    currentTransports: Ember.computed('transports.length', function () {
      return this.get('transports');
    }),
    validateDeliveryLoadCity: function (transport, delivery) {
      return transport.get('deliveries').every(d => {
        return d.get('loadStep.warehouse.address.city') === delivery.get('loadStep.warehouse.address.city');
      });
    },
    actions: {
      openModal() {
        this.get('modalService').showModal(this.get('modalService').MODALS.DELIVERIES.ADD_TRANSPORT, {
          transportType: this.get('transportType'),
          customFields: this.get('customFields'),
          dummyTransport: this.get('store').createRecord('transport', {
            transportType: this.get('transportType')
          })
        });
      },

      async addDelivery(transport, obj) {
        if (!this.validateDeliveryLoadCity(transport, obj)) {
          this.actionProgressToast.showErrorToast(this.get('intl').t('notificationToast.deliveryTransport.ADD_DELIVERY_TO_TRANSPORT.error'));
          console.error('Niezgodne miasta na załadunku !');
          return;
        }

        await this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.ADD_DELIVERY_TO_TRANSPORT, null, {
          body: JSON.stringify({
            transportId: transport.get('id'),
            deliveryId: obj.get('id')
          })
        });
      }

    }
  });

  _exports.default = _default;
});