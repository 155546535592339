define("apollo/pods/components/schedule-legend/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    specialWindows: Ember.computed('', function () {
      return [{
        label: this.get('intl').t('common.grayWindow'),
        color: '#444'
      }, {
        label: this.get('intl').t('common.lateWindow'),
        color: 'red'
      }, {
        label: this.get('intl').t('common.dedicatedWindow'),
        color: 'rgba(223,223,203,.7)'
      }, {
        label: this.get('intl').t('common.finishedWindow'),
        color: 'silver'
      }, {
        label: this.get('intl').t('common.transportCombiningIndicator'),
        color: '#34ebb7'
      }];
    }),
    tasks: Ember.computed('', function () {
      return this.get('store').peekAll('task').filterBy('enabled').filter(t => {
        return t.get('taskGroupIdentifier') !== 0 || t.get('idxInGroup') !== 0;
      });
    }),
    columns: Ember.computed('legendObjects', function () {
      const legendObjects = this.get('legendObjects');
      const length = legendObjects.get('length');
      const partIndex = length / 2;
      return [legendObjects.slice(0, partIndex), legendObjects.slice(partIndex, length)];
    }),
    legendObjects: Ember.computed('tasks', function () {
      const tasks = this.get('tasks').sort(function (a, b) {
        return a.get('taskGroupIdentifier') - b.get('taskGroupIdentifier');
      });
      const uniqueColors = tasks.getEach('color').uniq();
      const legendObjects = uniqueColors.map(color => this.mapToLegendObjects(color, tasks));
      return [...this.get('specialWindows'), ...legendObjects];
    }),
    mapToLegendObjects: function (color, tasks) {
      const tasksWithSameColor = tasks.filter(task => task.get('color') === color);
      const label = tasksWithSameColor.map(task => this.findAppropriateLegendString(task, tasksWithSameColor)).uniq().join(', ');
      const transportTypesNames = tasksWithSameColor.map(task => this.findTransportTypesNames(task, tasksWithSameColor)).uniq().join(', ');
      return {
        label,
        color,
        transportTypesNames
      };
    },
    findAppropriateLegendString: function (task, tasksWithSameColor) {
      const tasksWithSameStageName = tasksWithSameColor.filter(t => {
        return t.get('stageNameInAppropriateLanguage') === task.get('stageNameInAppropriateLanguage');
      }).get('length') > 1;
      return tasksWithSameStageName ? task.get('stageNameInAppropriateLanguage') : task.get('legendString');
    },
    findTransportTypesNames: function (task, tasksWithSameColor) {
      const tasksWithSameNameAndDifferentTransportTypes = tasksWithSameColor.filter(t => {
        return t.get('stageNameInAppropriateLanguage') === task.get('stageNameInAppropriateLanguage') && t.get('workflow.transportType.nameInAppropriateLanguage') !== task.get('workflow.transportType.nameInAppropriateLanguage');
      }).get('length') !== 0;
      if (tasksWithSameNameAndDifferentTransportTypes) return task.get('workflow.transportType.nameInAppropriateLanguage');
    }
  });

  _exports.default = _default;
});