define("apollo/pods/components/unit-amount-type-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TQnEVOOQ",
    "block": "{\"symbols\":[\"unit\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"col-xs-5 control-label\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"transaction.unitAmountType\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[12,\"class\",[28,[\"col-xs-7 no-padding text-left \",[27,\"if\",[[23,[\"model\",\"errors\",\"unitAmountType\"]],\"has-error\"],null]]]],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"selected\",\"options\",\"renderInPlace\",\"onchange\"],[\"form-control\",[23,[\"model\",\"unitAmountType\"]],[23,[\"unitAmountTypes\"]],true,[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"model\",\"unitAmountType\"]]],null]],null]]],{\"statements\":[[0,\"                \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n            \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"model\"]],\"unitAmountType\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/unit-amount-type-select/template.hbs"
    }
  });

  _exports.default = _default;
});