define("apollo/services/modal-service", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    MODALS: Object.freeze({
      DELIVERIES: {
        CREATE: 'planning.modals.create',
        ADD_TRANSPORT: 'components.planning.modals.add-transport'
      },
      TRUCK_TYPE: {
        FORM: 'truck-types.modals.form'
      },
      DELIVERY_PACKAGING: {
        FORM: 'components.transport.delivery-section.delivery-packaging-form'
      },
      TRANSPORT: {
        DETACH_ALL_DELIVERIES: 'components.transport.detach-all-deliveries'
      },
      TRANSACTION_UNIT_AMOUNT_TYPE: {
        FORM: 'transaction-unit-amount-types.modals.form'
      }
    }),

    showModal(modalName, model, outletName = 'main-modal') {
      const app = Ember.getOwner(this).lookup('route:application');
      console.debug(`Pokazujemy modal ${name}, ${outletName}...`);
      app.render(modalName, {
        into: 'application',
        outlet: outletName,
        model
      });
    },

    hideModal(outletName = 'main-modal') {
      console.debug(`Ukrywamy i usuwamy modal #${outletName} (application)...`);
      (0, _jquery.default)(`#${outletName}`).modal('hide');
      (0, _jquery.default)('.modal-backdrop').remove();
    },

    refreshModel() {
      const app = Ember.getOwner(this).lookup('route:application');
      app.send('refreshModel');
    }

  });

  _exports.default = _default;
});