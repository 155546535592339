define("apollo/translations/cs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "AuthorityGroup": {
      "ADVICE": "Rada",
      "ANNOUNCEMENT": "Oznámení",
      "API": "API",
      "APPLIES": "Aplikace operátora",
      "ASSORTMENT_GROUP": "Skupiny sortimentu",
      "AUCTION": "Předávání objednávek, aukce",
      "COMPANY": "Firmy",
      "COURIER": "Kurýři",
      "CUSTOM_FIELD": "Vlastní pole",
      "DELIVERY": "Plánování odeslání",
      "INDEX": "Indexy",
      "MAIN_TRANSPORT_DATA": "Hlavní pole objednávkového formuláře",
      "MODE_OF_TRANSPORTATION": "Způsob ověření přepravy",
      "NOTES_AND_DOCUMENTS": "Poznámky, dokumenty",
      "OTHERS": "Ostatní",
      "PACKAGE": "Balíčky",
      "PRICE_LIST": "Ceny",
      "PRODUCT": "Produkty",
      "QUEUE": "Fronty",
      "SETTINGS": "Nastavení",
      "TASKS": "Kontrolní body",
      "TIME_WINDOW": "Časové úseky",
      "TRANSPORT": "Doprava, objednávka",
      "USER": "Uživatelé",
      "WAREHOUSE": "Sklady",
      "WEIGHT": "Vážení"
    },
    "CARRIER": {
      "panel": {
        "label": "Karta dopravce"
      }
    },
    "CLIENT": {
      "panel": {
        "label": "Karta klienta"
      }
    },
    "SERVICE": {
      "panel": {
        "label": "Karta servisu"
      }
    },
    "SERVICEMAN": {
      "panel": {
        "label": "Karta servisního technika"
      }
    },
    "SUPPLIER": {
      "panel": {
        "label": "Karta dodavatele"
      }
    },
    "address": {
      "addNewAddress": "Přidat novou adresu",
      "autoCompletePlaceholder": "Začněte psát a vyhledejte město, ulici atd.",
      "chooseCompanyFirst": "Nejprve vyberte společnost",
      "city": "Město",
      "country": "Země",
      "deleteAddress": "Opravdu chcete tuto adresu odstranit?",
      "errors": {},
      "label": "Adresa",
      "newAddress": "Nová adresa",
      "postal": "PSČ",
      "recipient": "Příjemce",
      "removeAddress": "Odebrat adresu",
      "street": "Ulice"
    },
    "administration": {
      "newPrincipal": {
        "aliasHint": "Alias musí obsahovat alespoň jedno slovo (nejlépe název zákazníka) malými písmeny (např. logintegra)",
        "baseData": "Základní údaje o společnosti",
        "create": "Vytvořit nového zadavatele",
        "creation": "Vytváření nové společnosti zadavatele",
        "successMsg": "Nová společnost zadavatele byla úspěšně uložena. Nyní se můžete přihlásit k účtu: {username}",
        "userData": "Údaje uživatele (SUPER_ADMIN)"
      }
    },
    "advice": {
      "add": "Přidat avízo",
      "carRegistrationNumber.placeholder": "např. GDA12345",
      "carRegistrationNumberLabel": {
        "TRUCK": "Registrační číslo vozidla",
        "VEHICLE": "Registrační číslo vozidla"
      },
      "carRegistrationNumberSimple": "Registrační číslo vozidla",
      "creator": "Tvůrce avíza",
      "dateCreated": "Vytvořeno v",
      "documentNumber": "Číslo dokladu řidiče",
      "documentNumber.placeholder": "např. 78646003",
      "documentType": {
        "ID_CARD": "OP",
        "PASSPORT": "Cestovní pas"
      },
      "driverId": "Číslo ID řidiče",
      "driverLanguage": "Jazyk řidiče",
      "driverMobilePhoneNumber": "Mobilní telefonní číslo",
      "driverMobilePhonePrefix": "Předvolba mobilního telefonu řidiče",
      "driverName": "Jméno a příjmení řidiče",
      "driverName.placeholder": "např. Jan Kowalski",
      "errors": {
        "carRegistrationNumber": {
          "regex": "Registrační číslo musí mít délku mezi 4 a 50 znaky a může obsahovat jen písmena, čísla a znaky . , ; / \\\\ & _"
        },
        "driverMobilePhoneNumber": {
          "regex": "Telefonní číslo v Polsku se musí skládat z devíti číslic"
        },
        "rodoAccepted": {
          "notSelected": "Údaje musí být zpracovávány v souladu se zákonem"
        },
        "saveAsResource": {
          "info": "Chcete-li uložit řidiče do seznamu zdrojů, je třeba vyplnit povinná pole v oddílu avíza."
        },
        "termsOfConditionsAccepted": {
          "notSelected": "Přijetí podmínek je pro vytvoření avíza nezbytné"
        },
        "trailerRegistrationNumber": {
          "regex": "Registrační číslo přívěsu/návěsu musí mít délku mezi 4 a 50 znaky a může obsahovat jen písmena, čísla a znaky . , ; / \\\\ & _"
        }
      },
      "label": "Avízo",
      "pasteFromExcel": "Vložte data avíza",
      "rodo": "Údaje zadané do formuláře mohou být společností Logintegra sp. z o.o. legálně zpracovávány.",
      "saveAsResource": "Uložit údaje o řidiči a vozidle do seznamu zdrojů",
      "termsOfConditionsAccepted": "Přijímám ",
      "trailerRegistrationNumber": "Registrační číslo přívěsu/návěsu",
      "trailerRegistrationNumber.placeholder": "např. GDA6789",
      "transport": "Přeprava"
    },
    "afterRegistration": {
      "addWarehouseButton": "Přidat sklad",
      "info": "Spusťte svůj vlastní sklad a přizvěte k plánování přepravy a avíz své spolupracovníky.",
      "invitationButton": "Seznam pozvání",
      "label": "Zdravím vás!",
      "title": "Jste na správné cestě dát sbohem frontám!"
    },
    "algorithmSettings": {
      "deleteSetting": "Opravdu chcete toto nastavení odstranit?",
      "deletionSuccessMessage": "Úspěšně jsme odstranili nastavení algoritmu pro {n} palet",
      "label": "Nastavení algoritmu pro výpočet velikosti časového okna",
      "loadTimeInMinutes": "Doba nakládky v minutách",
      "numberOfPallets": "Počet palet (horní limit)",
      "saveSuccessMessage": "Nastavení algoritmu jsme úspěšně uložili."
    },
    "announcement": {
      "errors": {
        "roleGroups": {
          "chooseAtLeastOneRoleGroup": "Vyberte alespoň jednu skupinu rolí"
        }
      }
    },
    "announcements": {
      "add": "Přidat obecné oznámení",
      "announcementInEnglish": "Obecné oznámení v angličtině",
      "content": "Obsah zprávy",
      "contentEn": "Obsah zprávy (EN)",
      "dateCreated": "Datum vytvoření",
      "dateFrom": "Datum platnosti od",
      "dateTo": "Datum platnosti do",
      "delete": "Opravdu chcete toto obecné oznámení odstranit?",
      "edit": "Upravit obecné oznámení",
      "expirationDates": "Data pro zobrazování zprávy",
      "label": "Obecná oznámení",
      "lastEditor": "Autor posledního vydání",
      "lastUpdated": "Naposledy upraveno",
      "manage": "Správa obecných oznámení",
      "noActiveAnnouncements": "Žádná obecná oznámení nejsou k dispozici.",
      "noAnnouncements": "Žádná obecná oznámení",
      "roleGroups": "Uživatelské role, které obecné oznámení uvidí:",
      "subject": "Předmět zprávy",
      "subjectEn": "Předmět zprávy (EN)"
    },
    "assortmentGroup": {
      "add": "Přidat skupinu sortimentu",
      "adviceFromTime": "Avízo od",
      "adviceToTime": "Avízo do",
      "confirmDeletion": "Opravdu chcete odstranit skupinu sortimentu?",
      "confirmEdition": "Pozor. Hodiny pro avízo skupin sortimentu byly změněny. Opravdu chcete pokračovat?",
      "errors": {
        "adviceFromTime": {
          "invalidAssortmentGroupAdviceFromTime": "Avízo musí spadat do pracovních hodin skladu."
        },
        "adviceToTime": {
          "invalidAssortmentGroupAdviceToTime": "Avízo musí spadat do pracovních hodin skladu."
        },
        "nameEn": {
          "minLength": "This entry must be at least 3 characters long."
        },
        "namePl": {
          "minLength": "Tato položka musí obsahovat alespoň 3 znaky."
        }
      },
      "label": "Skupina sortimentu",
      "listLabel": "Skupiny sortimentu",
      "transportTypes": "Typy přepravy, pro které má být skupina sortimentu k dispozici"
    },
    "auction": {
      "AUCTION": {
        "actualFinishTime": "Skutečný čas ukončení",
        "buttonLabel": "+ aukce",
        "creator": "Tvůrce aukce",
        "currency": "Měna",
        "dateCreated": "Vytvořeno v",
        "endedWithoutOffers": "Aukce skončila bez nabídek",
        "finish": "Ukončit aukci",
        "inProgress": "Aukce končí",
        "initialFinishTime": "Plánovaný čas ukončení",
        "label": "Aukce",
        "noPreviousAuctions": "Žádné předchozí aukce",
        "participants": "Společnosti zúčastněné v aukci",
        "plannedFinishTime": "Datum ukončení aukce",
        "previousAuctions": "Předchozí aukce",
        "reject": {
          "alert": "Opravdu chcete tuto aukci zamítnout?",
          "fail": {
            "CannotRejectOffersExist": "Tuto aukci nemůžete zamítnout – vaše společnost již učinila nabídku."
          },
          "label": "Nemám zájem",
          "success": "Aukce byla úspěšně zamítnuta"
        },
        "save": "Uložit aukci",
        "savingInProgress": "Ukládání aukce…",
        "selectParticipants": "Vyberte skupinu dopravních společností"
      },
      "INQUIRY": {
        "actualFinishTime": "Skutečný čas ukončení",
        "buttonLabel": "Poptávka",
        "creator": "Tvůrce poptávky",
        "currency": "Měna",
        "dateCreated": "Vytvořeno v",
        "endedWithoutOffers": "Poptávka skončila bez nabídek",
        "finish": "Dokončit poptávku",
        "inProgress": "Datum vypršení platnosti poptávky",
        "initialFinishTime": "Plánovaný čas ukončení",
        "label": "Poptávka",
        "noPreviousAuctions": "Žádné předchozí poptávky",
        "participants": "Společnosti zúčastněné na poptávce",
        "plannedFinishTime": "Datum ukončení poptávky",
        "previousAuctions": "Předchozí poptávky",
        "reject": {
          "alert": "Opravdu chcete tuto poptávku zamítnout?",
          "fail": {
            "CannotRejectOffersExist": "Tuto poptávku nemůžete zamítnout – vaše společnost již učinila nabídku."
          },
          "label": "Nemám zájem",
          "success": "Poptávka byla úspěšně zamítnuta"
        },
        "save": "Vytvořit poptávku",
        "savingInProgress": "Ukládání poptávky…",
        "selectParticipants": "Vyberte skupinu dopravních společností"
      },
      "VALUATION": {
        "actualFinishTime": "Skutečný čas ukončení",
        "buttonLabel": "+ žádost o cenovou nabídku",
        "creator": "Tvůrce cenové nabídky",
        "currency": "Měna",
        "dateCreated": "Vytvořeno v",
        "endedWithoutOffers": "Žádost o cenovou nabídku skončila bez předložení nabídek",
        "finish": "Ukončit žádost o cenovou nabídku",
        "inProgress": "Žádost o cenovou nabídku končí",
        "initialFinishTime": "Plánovaný čas ukončení",
        "label": "Žádost o cenovou nabídku",
        "noPreviousAuctions": "Žádné předchozí žádosti o cenovou nabídku",
        "participants": "Společnosti zahrnuté do předkládání nabídek",
        "plannedFinishTime": "Datum ukončení předkládání nabídek",
        "previousAuctions": "Předchozí žádosti o cenovou nabídku",
        "reject": {
          "alert": "Opravdu chcete tuto žádost o cenovou nabídku zamítnout?",
          "fail": {
            "CannotRejectOffersExist": "Tuto žádost o cenovou nabídku nemůžete zamítnout – vaše společnost již učinila nabídku."
          },
          "label": "Nemám zájem",
          "success": "Žádost o cenovou nabídku byla úspěšně zamítnuta"
        },
        "save": "Uložit žádost o cenovou nabídku",
        "savingInProgress": "Ukládání žádosti o cenovou nabídku…",
        "selectParticipants": "Vyberte skupinu dopravních společností"
      },
      "bid": "Nabídka",
      "blockedCarriers": "Blokované přepravní společnosti",
      "buttonLabel": "+ aukce",
      "buyNow": "Přijmout",
      "buyNowConfirmation": "Opravdu chcete přijmout objednávku {transportIdentifier} za cenu {minPrice} {currencySymbol}?",
      "buyNowHeader": "Ihned přijmout nabídku a získat objednávku za minimální cenu",
      "buyNowLabel": "Minimální nabídka",
      "create": "Vytvořit aukci",
      "createTitle": "Vytváření aukce na přepravu {transportIdentifier}",
      "currentOffer": "Aktuální nabídka",
      "details": "Historie",
      "errors": {
        "companies": {
          "empty": "Přidejte alespoň jednu společnost"
        },
        "maxPrice": {
          "invalidNumber": "Maximální cena musí být číslo větší než nula.",
          "maxPriceLowerThanMinPrice": "Maximální cena nesmí být menší než minimální cena."
        },
        "minPrice": {
          "invalidNumber": "Minimální cena musí být číslo větší nebo rovno nule."
        },
        "plannedFinishTime": {
          "isInPast": "Datum ukončení musí být v budoucnosti."
        }
      },
      "finishTimeChanged": "Koncový čas byl změněn z {date}.",
      "label": "Aukce",
      "lastUpdated": "Poslední aktualizace",
      "maxPrice": "Max. cena",
      "minPrice": "Min. cena",
      "noOffers": "Žádné platné nabídky",
      "plannedFinishTimeAbbr": "harmonogram.",
      "priceRange": "Cenové rozpětí",
      "selectGroup": "Vyberte skupinu ",
      "transport": "Přeprava"
    },
    "auth": {
      "alreadyRegistered": "Máte už účet?",
      "changePassword": {
        "false": "Změnit heslo",
        "true": "Neměnit heslo"
      },
      "confirmPassword": "Potvrďte heslo",
      "description": "Logistická platforma",
      "emailPlaceholder": "Zadejte e-mailovou adresu",
      "fail": "Přihlášení se nezdařilo",
      "footer": "Vyhotovil",
      "impersonate": "Přihlásit se k účtu",
      "invalidLoginOrPassword": "Neplatné uživatelské jméno nebo heslo.",
      "login": "Přihlášení",
      "loginPlaceholder": "Zadejte e-mail",
      "logout": "Odhlášení",
      "newPassword": "Nové heslo",
      "noLoginAndPassword": "Zadejte přihlašovací jméno a heslo.",
      "notRegistered": "Ještě nemáte účet?",
      "oldPassword": "Původní heslo",
      "password": "Heslo",
      "passwordChanged": "Heslo uživatele {username} bylo změněno.",
      "passwordPlaceholder": "Zadejte heslo",
      "passwordRemindedMessage": "Pokud zadaná adresa {email} existuje, bude zpráva odeslána.",
      "remind": "Připomenutí",
      "remindEmail": "Zadejte e-mail propojený s vaším účtem. Pošleme vám na něj odkaz pro resetování hesla.",
      "remindFail": "Operace se nezdařila.",
      "remindHeader": "Připomenout heslo",
      "remindLink": "Zapomněli jste heslo?",
      "remindingInProgress": "Posíláme vám e-mail",
      "repeatNewPassword": "Potvrdit nové heslo",
      "repeatPassword": "Potvrďte heslo",
      "serverError": "Server neodpovídá. Zkuste to znovu za několik minut nebo nás kontaktujte na adrese info@logintegra.com.",
      "username": "Uživatelské jméno"
    },
    "blockades": {
      "invalidSection": "Zamítnutý nebo neplatný oddíl",
      "noActiveBlockades": "Žádné aktivní blokace"
    },
    "checkpoint": {
      "confirmator": "Potvrzující",
      "executionDate": "Datum potvrzení stavu",
      "reject": "Zamítnout",
      "rejectConfirm": "Opravdu chcete tento stav zamítnout? Zamítnutí stavu znamená, že pošlete řidiče zpět a odešlete e-mailová upozornění zákazníkovi a dopravci.",
      "rejectionDate": "Datum posledního zamítnutí: ",
      "revokeAdviceFirst": "Chcete-li tento krok odvolat, musíte nejprve odvolat kroky spojené s radou a/nebo časovým oknem."
    },
    "checkpoints": {
      "confirm": "Potvrdit",
      "confirmStatus": "Potvrdit stav",
      "confirmedMessage": "potvrz. {date}",
      "confirmedMessageBy": "provedl: {person}",
      "empty": "Žádné stavy",
      "plan": "harmonogram.",
      "processFinished": "Proces je dokončen!",
      "revokeConfirm": "Opravdu chcete odvolat potvrzení tohoto stavu?",
      "revokeManyConfirm": "Varování: Protože se nejedná o poslední potvrzený stav, odvoláme také potvrzení všech pozdějších stavů. Opravdu chcete tato potvrzení odvolat?"
    },
    "classifications": {
      "successMessage": "Vozidlo {registrationNumber} bylo přiřazeno do fronty: {squareName} – {queueName}"
    },
    "columns": {
      "ADVICE": "Avízo",
      "ALT_IDENTIFIER": "Alternativní identifikátor",
      "ARRIVAL_DATE": "Dodání",
      "CAR": "Vozidlo",
      "CARRIER": "Dopravce",
      "CONTRACTOR": "Dodavatel",
      "CREATION_DATE": "Datum vytvoření",
      "CUSTOM_FIELDS": "Volitelná pole",
      "DELIVERY_INCOTERMS": "Incoterms",
      "DELIVERY_INVOICE": "Dodací faktura",
      "DELIVERY_NETTO_WEIGHT": "Čistá hmotnost [kg]",
      "DELIVERY_NUMBER": "Číslo dodávky",
      "DOCUMENTS": "Dokumenty",
      "DOCUMENT_IDENTIFIER": "ID dokumentu",
      "DRIVER": "Řidič",
      "DRIVER_IDENTIFIER": "ID řidiče",
      "DRIVER_PHONE": "Telefon",
      "EMAIL_ADVICE": "E-mail avízo",
      "IDENTIFIER": "ID",
      "INDEX_DOCUMENT_NUMBER": "Číslo objednávky",
      "INDEX_TRANSPORT_NUMBER": "Č. přepravy",
      "INVOICE": "Faktura",
      "LOAD_CITY": "Město",
      "LOAD_COUNTRY": "Země",
      "LOAD_PACKAGES_QUANTITY": "Balicky nakl.",
      "LOAD_PLACE": "Místo nakládky",
      "LOAD_QUANTITY": "Nakládka palet",
      "LOAD_TIME_WINDOW": "Nakládka",
      "LOAD_WAREHOUSE": "Sklad",
      "LOCATION": "Umístění",
      "NOTES": "Poznámky",
      "PERIODIC_TRANSPORT_INTERNAL_INDEX": "Č. pravidelného avíza",
      "PRICE": "Cena",
      "QUANTITY": "Počet palet",
      "RAMP": "Č. rampy",
      "SERVICE_COMPANY_SHORT_NAME": "Služba",
      "STATUS": "Stav",
      "STORAGE_ZONE": "Zóna skladu",
      "TRAILER": "Přívěs/návěs",
      "TRANSPORT_SUPERVISOR": "Kontaktní osoba",
      "TRUCK_TYPE": "Typ dopravy",
      "UNLOAD_CITY": "Město",
      "UNLOAD_COUNTRY": "Země",
      "UNLOAD_PACKAGES_QUANTITY": "Balicky vykl.",
      "UNLOAD_PLACE": "Místo dodání",
      "UNLOAD_QUANTITY": "Vykládka palet",
      "UNLOAD_TIME_WINDOW": "Vykládka",
      "UNLOAD_WAREHOUSE": "Sklad",
      "VEHICLE_TRUCK_TYPE": "Typ vozidla",
      "disableCustomFieldInfo": "Zapnutí/vypnutí této položky povolí/zakáže všechna pole customField viditelná v tabulce.",
      "label": "Viditelnost sloupců"
    },
    "common": {
      "accept": "Přijmout",
      "accepted": "Přijato",
      "active": "Aktivní",
      "add": "Přidat",
      "addedBy": "přidal",
      "address": "Adresa",
      "allRightsReserved": "Všechna práva vyhrazena",
      "and": "a",
      "andBetween": "a",
      "appVersion": "Verze",
      "autoCompleteBasicPlaceholder": "Začněte psát do vyhledávače...",
      "back": "Zpět",
      "browseAndUploadDocuments": "Procházení a nahrávání dokumentů",
      "by": "kým",
      "byYou": "vámi",
      "cancel": "Zrušit",
      "cancelProcess": "Zrušit proces",
      "changeOfField": "Změna pole",
      "check": "kontrola",
      "clear": "Smazat",
      "clearFilters": "Vymazat filtry",
      "clearTextArea": "Vymazat obsah tohoto textového pole",
      "client": "Klient",
      "close": "Zavřít",
      "company": "Společnost",
      "confirmMessage": "Opravdu?",
      "createSimilar": "Vytvořit podobné",
      "currency": "Měna",
      "dateCreated": "Datum vytvoření",
      "day": "Den",
      "days2": "2 dny",
      "days4": "4 dny",
      "daysOfWeek": {
        "1": "Po",
        "2": "Út",
        "3": "St",
        "4": "Čt",
        "5": "Pá",
        "6": "So",
        "7": "Ne",
        "label": "na dny"
      },
      "dedicatedWindow": "Vyhrazené okno",
      "delete": "Odstranit",
      "deleteTitle": "Odstranit tuto přepravu (odstraněná přeprava se přesune do archivu).",
      "deletingProgress": "odstraňování",
      "desc": "Popis",
      "description": "Popis",
      "details": "podrobnosti",
      "disable": "Zakázat",
      "disableXLS": "Chcete-li exportovat soubor do formátu XLS, je nutné zadat rozsah dat nebo omezit vyhledávání pomocí filtrů.",
      "download": "Stáhnout {name}",
      "downloadFile": "Stáhnout soubor",
      "downloadManualHeader": "Stáhnout příručku",
      "driverMobilePhoneNumber.placeholder": "např. 111222333",
      "driverMobilePhonePrefix.placeholder": "např. 48",
      "edit": "Upravit",
      "enable": "Povolit",
      "english": {
        "abbr": "angl.",
        "label": "angličtina"
      },
      "export": "Export",
      "exportAsXLS": "Exportovat jako XLS.",
      "finishedWindow": "Proces dokončen",
      "forDateRange": {
        "M": "měsíc",
        "Y": "rok"
      },
      "from": "od",
      "fromCompany": "od společnosti",
      "further": "Další",
      "grayWindow": "Blokované okno",
      "grossWeightKg": "Hrubá hmotnost [kg]",
      "hint": "Tip:",
      "hour": "Hodina",
      "id": "Id",
      "idx": "Index",
      "instructionalVideo": "instruktážní video",
      "interval": {
        "M": "{count} {count, plural, one {měsíc} few {měsíce} other {měsíců}}",
        "d": "{count} {count, plural, one {den} few {dny} other {dnů}}"
      },
      "language": {
        "ENG": "ENG",
        "PL": "CZ"
      },
      "lastUpdated": "Datum poslední změny",
      "lateWindow": "Zpožděné okno",
      "legend": "Popisek",
      "loadingData": "Načítání dat…",
      "max": "Max",
      "maxDate": "Max. datum",
      "message": "Zpráva",
      "min": "Min",
      "minDate": "Min. datum",
      "more": "Více",
      "name": "Název",
      "nameEn": "English name",
      "namePl": "Český název",
      "netWeightKg": "Čistá hmotnost [kg]",
      "newMessagesTitle": "Máte nové zprávy!",
      "next": "Další",
      "no": "Ne",
      "noAdviceAddedYet": "Zatím nebylo přidáno žádné avízo",
      "noAuthority": "Nemáte oprávnění k provedení této akce",
      "noData": "Žádná data",
      "noResults": "Žádné výsledky",
      "notSpecified": "Neurčeno",
      "ok": "OK",
      "oldBrowser": "Používáte zastaralý prohlížeč. Naše aplikace bude fungovat lépe v moderním prohlížeči, jako je Google Chrome, Firefox, Opera, Vivaldi, Safari nebo IE Edge.",
      "onDay": "v den",
      "operationInProgress": "Probíhá operace…",
      "options": "Možnosti",
      "or": "nebo",
      "order": "Objednávka",
      "orderNumber": "Č. obj.",
      "otherRequirements": "Další požadavky",
      "otherRequirementsVisibleToCarrier": "Další požadavky (viditelné pro dopravce)",
      "outOf": "ze",
      "perPage": "na stránku",
      "phone": "Telefon",
      "plan": "Vytvořit",
      "pleaseWait": "Čekejte prosím…",
      "previous": "Předchozí",
      "recipient": "Příjemce",
      "refresh": "Obnovit",
      "register": "Přihlásit se",
      "reject": "Zamítnout",
      "rejected": "Zamítnuto",
      "reset": "Resetovat",
      "restore": "Obnovit",
      "role": "Typ oprávnění",
      "save": "Uložit",
      "saveAndClose": "Uložit a zavřít",
      "saveSuccessAdditionalMessage": "Změny se zobrazí po obnovení stránky",
      "saveSuccessMessage": "Změny jsme v pořádku uložili",
      "saving": "Ukládání",
      "scrollToClosestTransport": "Přejděte na přepravu s nejbližším datem",
      "search": "Vyhledávání",
      "selectDate": "Vyberte datum",
      "selectDeselectAll": "Vybrat/Zrušit výběr všech",
      "send": "Odeslat",
      "settings": "Nastavení",
      "shortName": "Krátký název",
      "shortNameEn": "English short name",
      "shortNamePl": "Zkrácený český název",
      "start": "Start",
      "stop": "Zastavte",
      "sum": "Celkem",
      "synchroniseCarriersData": "Synchronizace dat nosiče",
      "timeSign": {
        "after": "po",
        "before": "před"
      },
      "timeUnit": {
        "d": "dny",
        "h": "hodiny",
        "m": "minuty",
        "s": "sekundy"
      },
      "to": "do",
      "today": "Dnes",
      "transportDeletionDisabled": "Tuto přepravu nelze odstranit. Obraťte se na správce.",
      "transportEditionDisabled": "Nemáte oprávnění k úpravám této přepravy.",
      "type": "Typ",
      "update": "Aktualizovat",
      "uploadFile": "Nahrát soubor",
      "value": "Hodnota",
      "waiting": "Čeká",
      "week": "Týden",
      "weightAndSave": "Zvážit a zavřít",
      "yes": "Ano"
    },
    "company": {
      "address": "Adresa",
      "addresses": "Adresy",
      "alias": "Alias",
      "chooseCompanyTypeHint": "Vyplňte prosím typ společnosti",
      "contractorNumber": "Číslo dodavatele",
      "country": "Země",
      "createCompany": "Vytvořit společnost",
      "deletedMessage": "Společnost {companyName} byla odstraněna.",
      "descriptionOfTheFleet": "Popis vozového parku",
      "editCompany": "Upravit společnost",
      "employees": "Zaměstnanci",
      "errors": {},
      "krs": "KRS/CEiDG",
      "label": "Společnost",
      "legalStatus": {
        "businessActivity": "Obchodní činnost",
        "label": "Právní stav",
        "limitedCompany": "Společnost s ručením omezeným"
      },
      "list": "Seznam společností",
      "name": "Název",
      "noEmployees": "Tato společnost nemá žádné zaměstnance",
      "noPartnershipWarning": "Vaše společnost nezahájila žádná partnerství. Může to způsobit problémy s výběrem dodavatelů.",
      "partnershipStatus": {
        "ACCEPTED": "Přijato",
        "AWAITING_VERIFICATION": "Čeká na ověření",
        "BLOCKED": "Blokováno",
        "CREATED_FROM_API": "Automaticky přidáno",
        "NO_INVITATION": "Žádné pozvání",
        "REGISTRATION_IS_IN_PROGRESS": "Probíhá registrace",
        "REJECTED": "Zamítnuto",
        "UPDATED": "Aktualizováno",
        "WAITING": "Čeká",
        "label": "Stav"
      },
      "partnershipTable": {
        "awaitingLabel": "Seznam čekajících pozvání",
        "companyName": "Název společnosti",
        "label": "Seznam přidružených společností"
      },
      "patron": "Klient",
      "patronWillChange": "Klient bude změněn v objednávkách {transportIds}. Opravdu? Pokud vyberete možnost „Zrušit“, změny se neuloží.",
      "possessedCertificates": "Vlastněné certifikáty",
      "rampEdition": {
        "header": "Verze ramp pro uživatele společnosti {companyName}",
        "label": "Verze ramp",
        "updateForAllUsers": "Aktualizace ramp pro všechny uživatele této společnosti"
      },
      "registrationDate": "Datum registrace",
      "regon": "REGON",
      "saveSuccessMessage": "Společnost byla úspěšně uložena.",
      "sections": "Oddíly",
      "selectCountry": "Vyberte zemi",
      "selectType": "Vybrat typ",
      "shortName": "Krátký název",
      "taxIdNumber": "Daňové identifikační č.",
      "type": {
        "CARRIER": "Dopravce",
        "CLIENT": "Klient",
        "SERVICE": "Servis",
        "SERVICEMAN": "Servisní technik",
        "SUPPLIER": "Dodavatel \\\\ Příjemce",
        "SUPPLIER.CLIENT": "Klient",
        "SUPPLIER.COLLECTION": "Příjemce",
        "SUPPLIER.COMPANY": "Společnost",
        "SUPPLIER.COURIER": "Kurýři",
        "SUPPLIER.DELIVERY": "Dodavatel",
        "label": "Typ",
        "producer": "Výrobce"
      },
      "update": "Aktualizovat"
    },
    "companyDisabledIcon": {
      "bothInsurancesMissing": "Blokovaný. Žádné aktivní pojištění OCP a OCS",
      "insuranceAmountTooLow": "Blokovaný. Výše krytí "
    },
    "companyRamp": {
      "notExists": "Společnost {companyName} nemá dosud nastaven přístup k žádné výchozí rampě.",
      "updateForAllUsersConfirmMsg": "Opravdu chcete přepsat přístup k rampám pro všechny uživatele z této společnosti?",
      "usersWithIndividualRampAccess": "Seznam uživatelů s jiným přístupem na rampu"
    },
    "contractors-group": {
      "addGroup": "Přidat skupinu",
      "description": {
        "details": "Podrobnosti",
        "ifYouDoNotHaveDocument": "Pokud požadovaný dokument nemáte, vypněte zvolenou skupinu.",
        "label": "Výběr skupiny vyžaduje doplnění příslušné licence a pojistných dokumentů",
        "notApplicable": "Nerelevantní",
        "optionalDocuments": "a jeden z následujících dokumentů:"
      },
      "errors": {
        "obligatoryDocuments": "Pro účast ve skupině {groupName} musí mít vaše společnost tyto dokumenty: {documents}.",
        "optionalDocuments": "Pro účast ve skupině {groupName} musí mít vaše společnost jeden z těchto dokumentů: {documents}."
      },
      "label": "Seskupování",
      "name": "Název",
      "nameEn": "English name",
      "selectADRInfo": "Po výběru této možnost se společnost připojí do skupiny dopravců oprávněných k odbavování objednávek na nebezpečné zboží. Ujistěte se, že vaše společnost je oprávněna k nakládání s nebezpečnými materiály.",
      "shortName": "Krátký název",
      "shortNameEn": "English short name"
    },
    "contractors-group-type": {
      "ADR": "Rozdělení podle přepravy zboží a nebezpečných nákladů",
      "AVAILABLE_VEHICLES": "Rozdělení podle dostupných vozidel",
      "PRIVATE": "Soukromé skupiny, neviditelné pro dodavatele",
      "SUPPORTED_REGIONS": "Rozdělení podle podporovaných regionů",
      "SUPPORTED_VOIVODESHIPS": "Rozdělení podle podporovaných vojvodství",
      "TERRITORIAL_DIVISION": "Územní členěn",
      "TYPES_OF_BUSINESS": "Rozdělení podle obchodní činnosti",
      "TYPES_OF_TRANSPORT": "Rozdělení podle podporovaných typů přepravy",
      "errors": {
        "canSelectOnlyOneContractorsGroup": "Vyberte jen jednu skupinu",
        "requiresContractorsGroup": "Vyberte v oddílu {groupType} alespoň jednu skupinu."
      }
    },
    "courier": {
      "COURIER_PACKAGE": {
        "add": "Přidat",
        "description": "Obsah balení",
        "disableConfirm": "Opravdu chcete smazat tuto položku?",
        "edit": "Upravit",
        "height": "Výška [cm]",
        "internalId": "ID",
        "length": "Délka[cm]",
        "numberOfPackagesTotal": "Celková částka:",
        "packageIdPlaceholder": "ID",
        "packageType": "Typ",
        "packageTypePlaceholder": "Typ",
        "packageWeightTotal": "Celková hmotnost:",
        "pallet": "PALLET",
        "parcel": "BALENÍ",
        "quantity": "Množství",
        "sectionLabel": "Balíčky",
        "weight": "Hmotnost [kg]",
        "width": "Šířka [cm]"
      },
      "documents": {
        "connote": "Přepravní list",
        "label": "Označení",
        "manifest": "Manifest"
      },
      "validation.float.range": "Hodnota musí být číslo mezi 0.001 - 999999999999",
      "validation.integer.range": "Hodnota musí být celé číslo mezi 1 - 9999999",
      "validation.minimumOnePackage": "Musíte přidat alespoň jeden platný balíček",
      "validation.range": "Hodnota musí být číslo mezi 1 - 9999999"
    },
    "custom-field-definition": {
      "addNewField": "Přidat nové pole",
      "alias": "Alias",
      "amountOfDelayedTimeForTaskToBeRequired": "Množství času zpoždění etapy, které způsobuje, že je pole vyžadováno",
      "autocompleteEnabled": "Automatické dokončování aktivováno",
      "cancel": "Opravdu chcete tyto změny zrušit?",
      "create": "Vytvořit definici pole formuláře",
      "delete": "Opravdu chcete toto pole odstranit?",
      "deleteSuccessMessage": "Nastavení pole jsme úspěšně odstranili.",
      "deliveryAlias": "Alias pro integraci MSP",
      "englishName": "Anglický název",
      "errors": {
        "options": {
          "empty": "Zadejte alespoň jednu možnost"
        },
        "textAreaRowsNumber": {
          "textAreaSize": "Počet řádků musí být větší než 0"
        }
      },
      "filterInArchive": "Filtrovat v archivu",
      "filterLabel": "Volitelné filtry:",
      "includedInPriceList": "Zahrnout do ceníku",
      "makeFieldDependentOn": {
        "definitionPlaceholder": "Vybrat název závislého pole",
        "fieldCompleted": "Pole dokončeno",
        "hint": {
          "obligatory": "Pokud je podmínka splněná, pole bude viditelné a povinné",
          "voluntary": "Pokud je podmínka splněná, pole bude viditelné a dobrovolné"
        },
        "title": "Nastavit viditelnost pole závisle na hodnotě jiného pole"
      },
      "name": "Název",
      "noTaskSelected": "Bez ohledu na kontrolní bod",
      "numberDecimalFormat": {
        "comma": "Oddělovač seskupení",
        "dot": "Oddělovač desetinných míst nebo peněžní oddělovač desetinných míst",
        "hash": "Číslice, nula ukazuje jako nepřítomný",
        "label": "Number format",
        "meaning": "Význam",
        "minus": "Znaménko mínus",
        "symbol": "Symbol",
        "zero": "Číslice"
      },
      "obligatory": "Povinné",
      "requiredInDelayedTransport": "Vyžadováno při zpoždění",
      "requiredTaskInDelayedTransport": "Pole vyžadované při potvrzení etapy, pokud je etapa zpožděná",
      "saveSuccessMessage": "Nastavení polí jsme úspěšně uložili.",
      "saveSuccessMessageForTimeWindow": "Nastavení polí jsme úspěšně uložili. Změny budou viditelné za několik minut.",
      "settingsFields": "Pole nastavení",
      "side": "Strana pole, kdy formulář obsahuje více než {number} volitelných polí",
      "sideLeft": "Vlevo",
      "sideRight": "Vpravo",
      "sideShort": "Strana",
      "task": "Povinné pro pole během a po potvrzení kontrolního bodu",
      "taskHint": "Chcete-li nastavit povinnost pole „Bez ohledu na kontrolní bod“, klikněte na křížek vedle výše zvolené fáze.",
      "textAreaRowsNumber": "Počet řádků pole",
      "type": {
        "date": "Datum",
        "number": "Počet",
        "select": "Vybrat",
        "text": "Text",
        "text_area": "Textová oblast"
      },
      "typeField": "Typ pole",
      "visibilityDependsOnDefinition": "Název závislého pole",
      "visibilityDependsOnOption": "Hodnota závislého pole",
      "visibleDuringTransportCreation": "Viditelné při vytváření přepravy",
      "visibleInAdviceSection": "Viditelné ve formuláři přepravy",
      "visibleInDiscrepancyReportSection": "Viditelné v oddílu „Hlášení o neshodě“",
      "visibleInTable": "Viditelné v tabulce",
      "visibleInTransportWeightReceipt": "Viditelné v potvrzení o přepravní hmotnosti",
      "visibleInWeightSection": "Viditelné v oddílu „Hmotnost“",
      "visibleOnTimeWindow": "Viditelné v časovém okně"
    },
    "custom-field-option": {
      "add": "Přidat možnost",
      "errors": {},
      "remove": "Odebrat možnost"
    },
    "custom-field-value": {
      "autoCompletePlaceholder": "Začněte psát a vyhledejte možnost.",
      "errors": {
        "value": {
          "notProperValueType": "Nesprávný typ hodnoty, je třeba číslo"
        }
      }
    },
    "dateFilterProperty": {
      "DATE_CREATED": "Datum vytvoření",
      "DEPARTURE_DATE": "Datum odjezdu",
      "TIME_RANGE_FOR_ADVICE_START": "Počátek časového rozsahu avíza",
      "TIME_RANGE_FOR_ADVICE_STOP": "Konec časového rozsahu avíza",
      "TIME_WINDOW_DATES": "Počátek nebo konec časového okna",
      "TIME_WINDOW_START": "Počátek časového okna",
      "TIME_WINDOW_STOP": "Konec časového okna",
      "hint": "Pokud typ přepravy umožňuje vytvořit objednávku bez časového okna, nebude taková objednávka zahrnutá do filtrů, které jsou závislé na čase vlastností okna (např. Počátek časového okna)."
    },
    "deliveries": {
      "add": "Přidat dodání",
      "label": "Dodání"
    },
    "delivery": {
      "delete": "Opravdu chcete smazat depeši?",
      "detachAllDeliveries": "Odpojení dodávek",
      "detachDeliveriesModalText": "Jste si jisti, že chcete odpojit všechny dodávky od přepravy?",
      "detachFromTransport": "Odpojit dodávku",
      "label": "Dodání",
      "weightAutoCreated": "Dodání: {deliveryNumber} byla automaticky převedena na přepravu z důvodu překročení definované hmotnosti: {weight}"
    },
    "deliveryPackaging": {
      "description": "Popis",
      "height": "Výška [cm]",
      "ldmLabel": "Nakládací metry (ldm)",
      "length": "Délka[cm]",
      "modalTitle": "Obaly",
      "packageType": "Typ obalu",
      "quantity": "Množství",
      "sectionTitle": "Obaly",
      "stackingFactor": "Stohovatelné",
      "weight": "Hmotnost [kg]",
      "width": "Šířka [cm]"
    },
    "deliverySettings": {
      "add": "Přidat nastavení doručení",
      "fields": {
        "autoGenerateDeliveryNumber": "Automatické generování dodacího čísla.",
        "requireAtLeastOnePackaging": "Vyžadovat alespoň jedno balení pro uložení doručení",
        "requireFreightCost": "Vyžadovat náklady na přepravu.",
        "requireGrossWeight": "Vyžadovat hrubou hmotnost.",
        "requireNetWeight": "Vyžadujte čistou hmotnost.",
        "requireStackingFactor": "Vyžaduje stohovatelnost.",
        "showSimplePackagingSection": "Zobrazení zjednodušeného pohledu na balení"
      },
      "label": "Nastavení doručení",
      "noSettings": "Zatím není k dispozici žádné nastavení doručení"
    },
    "dev": {
      "confirm": "Potvrdit",
      "performSaveAt": "Uložit"
    },
    "discrepancyReport": {
      "generate": "Vygenerovat hlášení o neshodě",
      "label": "Hlášení o neshodě",
      "noAdviceInfo": "Hlášení o neshodě bude možné vygenerovat po přidání avíza",
      "noDiscrepanciesFound": "Žádná neshodna nebyla nalezena",
      "noDiscrepanciesInfo": "Protokol bude možné vygenerovat po nalezení neshod"
    },
    "document": {
      "INSURANCE": {
        "create": "Přidat pojištění",
        "expirationDate": "Platnost pojištění do",
        "expirationDateFrom": "Platnost pojištění od",
        "new": "Nové {typeLabel}",
        "number": "Číslo pojištění",
        "save": "Uložit pojištění",
        "savingInProgress": "Ukládání pojištění…",
        "showArchived": "Zobrazit archivovaná pojištění"
      },
      "LICENCE": {
        "create": "Přidat licenci",
        "expirationDate": "Datum vypršení platnosti",
        "new": "Nová {typeLabel}",
        "number": "Číslo licence",
        "save": "Uložit licenci",
        "savingInProgress": "Ukládání licence…",
        "showArchived": "Zobrazit archivované licence"
      },
      "amount": "Pojistné krytí",
      "currency": "Měna",
      "disableConfirm": "Opravdu chcete tento dokument přesunout do archivu? Archivní dokumenty nejsou při kontrole platnosti zohledňovány.",
      "documentType": "Rozsah",
      "documentType.placeholder": "Vybrat rozsah",
      "errors": {
        "expirationDate": {
          "isInPast": "Datum ukončení nemůže být v minulosti."
        },
        "expirationDateFrom": {
          "afterExpirationDate": "Datum platnosti „od“ musí být dřívější než datum „do“."
        }
      },
      "expired": "Platnost tohoto dokumentu vypršela!",
      "files": "Přidané soubory",
      "noFilesInfo": "K tomuto dokumentu nebyl přidán žádný soubor",
      "noFilesWarning": "Přidejte naskenovaný soubor dokumentu!",
      "scopeOfInsurance": "Územní krytí"
    },
    "driver": {
      "addDriver": "Přidat řidiče",
      "country": "Země",
      "disableConfirm": "Opravdu chcete odstranit tohoto řidiče?",
      "documentNumber": "Číslo dokumentu",
      "documentType": {
        "ID_CARD": "OP",
        "PASSPORT": "Cestovní pas"
      },
      "errors": {
        "phoneNumber": {
          "regex": "Číslo musí obsahovat 9 až 12 číslic."
        }
      },
      "label": "Řidiči",
      "name": "Jméno a příjmení",
      "nationality": "Národnost",
      "phoneNumber": "Telefonní číslo",
      "timeWindow": "Časové okno"
    },
    "errors": {
      "blank": "Zadejte hodnotu.",
      "blankCheckbox": "Toto pole musí být zaškrtnuté",
      "blankSelect": "Vyberte hodnotu.",
      "emptyFile": "Nahraný soubor je prázdný, vyberte jiný soubor",
      "fileUploadError": "Při nahrávání souboru došlo k chybě",
      "filterRequestTimeout": "Během vyhledávání došlo k chybě. Zúžte prosím rozsah data hledání a zkuste to znovu.",
      "invalidDatesSequence": "Datum „od“ musí být dřívější než datum „do“",
      "minimumOnePackage": "Je třeba přidat alespoň jeden platný obal",
      "number": "Tato položka může obsahovat pouze čísla",
      "positiveNumber": "Tato položka musí být kladné číslo",
      "tooLong": "Tato položka musí obsahovat maximálně %@ znaků",
      "tooShort": "Tato položka musí obsahovat alespoň %@ znaků"
    },
    "file": {
      "confirmDeletion": "Opravdu chcete odstranit tuto položku?"
    },
    "freeSubsequentSpots": {
      "infoAboutCurrentSpot": "Vybraná rampa: <b>{currentRamp}</b>, čas: <b>{currentStart}</b>",
      "infoAboutWindowSize": "Vaše časové okno se do zvoleného prostoru nevejde. Čas potřebný pro vykládku: <b>{windowSizeInMinutes}</b> minut",
      "showSearchSpotsButton": "Zobrazit dostupná místa v harmonogramu",
      "suggestionsHeader": "Nejdřívější možný čas avíza je:"
    },
    "help": {
      "changePrincipalSpaceInfo": "Poznámka: Stránka, na které se nacházíte, bude obnovena. Před dokončením této akce uložte provedené změny.",
      "contactUs": "nebo se obraťte na nás",
      "contactWithWarehouse": "Kontakt na sklad",
      "label": "Nápověda",
      "newMessagesTitle": "Máte %@1 nových zpráv!",
      "selectRamp": "Vyberte skupinu nakládky.",
      "statute": "Stav"
    },
    "index": {
      "INDEX": {
        "add": "Přidat index",
        "disableConfirm": "Opravdu chcete odstranit tento index?",
        "enabled": "Index je povolen",
        "globalNumber": "Indexové číslo",
        "globalNumber.abbr": "Č. indexu",
        "label": "Index",
        "localNumber": "Místní číslo",
        "localNumber.abbr": "Místní č.",
        "name": "Název produktu",
        "name.abbr": "Název",
        "notes": "Poznámky k indexu",
        "product": "Produkt",
        "sectionLabel": "Indexy",
        "shortName": "Krátký název",
        "step": "Přiřazení indexu ke kroku"
      },
      "PALLETS": {
        "add": "Přidat paletu",
        "disableConfirm": "Opravdu chcete odstranit tuto položku?",
        "enabled": "Položka povolena",
        "globalNumber": "Číslo palety",
        "globalNumber.abbr": "Č. palety",
        "label": "Palety",
        "localNumber": "Místní číslo",
        "localNumber.abbr": "Místní č.",
        "name": "Název produktu",
        "name.abbr": "Název",
        "notes": "Poznámky k paletám",
        "product": "Produkt",
        "sectionLabel": "Palety",
        "shortName": "Krátký název",
        "step": "Přiřazení palet ke kroku"
      },
      "company": "Dodavatel",
      "companyRecipient": "Odběratel zákazníka",
      "dateCreated": "Datum vytvoření",
      "documentNumber": "Číslo objednávky",
      "documentNumber.abbr": "Číslo objednávky",
      "exportDisabledHint": "Export bude možný po uložení změn.",
      "selectCompany": "Vyberte zákazníka",
      "selectCompanyHint": "Nejprve vyberte zákazníka ve formuláři objednávky",
      "selectRecipient": "Odběratel zákazníka",
      "transportNumber": "Typ objednávky",
      "transportNumber.abbr": "Typ objednávky"
    },
    "index-quantity": {
      "errors": {
        "quantity": {
          "conditionallyObligatory": "Zadejte jednu z hodnot: {names}.",
          "invalidNumber": "Zadejte správné kladné číslo.",
          "obligatory": "Zadejte množství."
        }
      },
      "incompleteLoadingRulesNotMet": "Tuto hodnotu lze zadat jen v případě neúplné nakládky."
    },
    "invitation": {
      "acceptedByAnotherPerson": "Pozvánka již byla přijata jinou osobou ve vaší společnosti.",
      "acceptedSummary": "Přijato",
      "alreadyAccepted": "Tato pozvánka je již přijata.",
      "alreadyHandled": "Tato pozvánka byla zkontrolována",
      "companyRegistration": "Registrace společnosti",
      "createdBy": "{personName} vás zve do systému",
      "creator": "Vytvořil",
      "email": "E-mail",
      "errors": {},
      "expirationDate": "Datum vypršení platnosti",
      "for": {
        "CARRIER": "Dopravce",
        "CLIENT": "Klient",
        "JOIN_COMPANY": "K vaší společnosti",
        "SERVICEMAN": "Servisní technik",
        "SUPPLIER": "Dodavatel \\\\ Příjemce"
      },
      "hasExpired": "Vypršela platnost",
      "instruction": {
        "label": "Přečetl(a) jsem si:",
        "linkName": "PODMÍNKY POSKYTOVÁNÍ SLUŽEB"
      },
      "invite": "Pozvat",
      "label": "Pozvání",
      "notFound": "Pozvánka neexistuje nebo vypršela její platnost.",
      "ofCompany": "společnosti {companyName}",
      "registeredAccount": "Registrovaný účet",
      "registrationComplete": "Děkujeme. Váš účet byl zaregistrován. Přihlaste se pomocí uživatelského jména {username}.",
      "rejectedSummary": "Zamítnuto",
      "sendTo": "Odeslat pozvánku",
      "sent": "Pozvánka odeslána na {email}.",
      "taxIdNumberExist": {
        "info": "Daňové identifikační číslo již v databázi existuje. Kontaktujte společnost Logintegra."
      },
      "toCompanyWithRole": "abyste se připojili ke společnosti {companyName} jako {roleName}",
      "toJoinExistingCompany": "ke společnosti {companyName}",
      "toPartnership": "{personeName} vás zve, abyste se připojili ke společnosti {companyName} jako {roleName}.",
      "type": {
        "CARRIER": "Připojit se jako dopravce",
        "CLIENT": "Připojit se jako klient",
        "JOIN_COMPANY": "Připojit se ke společnosti",
        "SERVICEMAN": "Připojit se jako servisní technik",
        "SUPPLIER": "Připojit se jako dodavatel \\\\ příjemce"
      },
      "userRegistration": "Registrace uživatele"
    },
    "invitations": {
      "cannotInviteAnyRoleGroup": "Nemáte oprávnění pozvat jakéhokoli uživatele.",
      "emptyReceived": "Dosud jste neobdrželi pozvánku.",
      "emptySent": "Dosud jste neodeslali pozvánku.",
      "label": "Pozvánky",
      "noRoleGroupsFound": "Nemáte oprávnění pozvat skupinu rolí se zvoleným typem partnerství.",
      "received": "Přijato",
      "sent": "Odesláno",
      "types": {
        "ACCEPTED": "Přijato",
        "EXPIRED": "Platnost vypršela",
        "REJECTED": "Zamítnuto",
        "WAITING": "Čeká"
      }
    },
    "invoice": {
      "number": "Číslo faktury",
      "price": "Částka"
    },
    "log": {
      "actor": "Autor změny",
      "change": "Změna",
      "dateCreated": "Datum",
      "fieldChange": "Změna pole"
    },
    "logintegraEmail": "info@logintegra.com",
    "logintegraPhone": "+48 585 357 806",
    "logs": {
      "DELETE": {
        "Delivery": "Dodávka smazána: "
      }
    },
    "main-section": {
      "errors": {
        "hasErrors": "Hlavní datový oddíl obsahuje chyby. Vyplňte požadovaná pole správně.",
        "krsFile": "Chcete-li odeslat požadavek na ověření, musíte přidat požadovaný dokument potvrzující č. KRS (Obchodní rejstřík)",
        "nipFile": "Chcete-li odeslat požadavek na ověření, musíte přidat požadovaný dokument potvrzující daňové identifikační číslo",
        "regonFile": "Chcete-li odeslat požadavek na ověření, musíte přidat požadovaný dokument potvrzující č. REGON"
      }
    },
    "managableJob": {
      "title": "Správa pracovních míst"
    },
    "max-pallet": {
      "errors": {},
      "label": "Limit palet",
      "title": "Limit palet za den"
    },
    "modeOfTransportatios": {
      "documents": {
        "disabled": "K dopravnímu prostředku se zdravotním postižením nelze přidávat doklady"
      }
    },
    "noAccessibleStatisticsReports": "Nemáte přístup k žádnému přehledu",
    "noAccessibleTransportTypes": "Žádné dostupné typy přepravy",
    "note": {
      "autoGenerated": "Vygenerováno automaticky",
      "creator": "Vytvořil",
      "dateCreated": "Vytvořeno v",
      "label": "Poznámka",
      "note": "Poznámka",
      "transport": "Přeprava"
    },
    "notification": {
      "ADVICE_CONFIRMATION": {
        "desc": "Oznámení zaslaná tvůrci po uložení avíza.",
        "label": "Potvrzení vytvoření avíza: {transportTypeName}"
      },
      "ADVICE_CREATED": {
        "desc": "Oznámení zaslané po uložení avíza.",
        "label": "Vytvoření avíza: {transportTypeName}"
      },
      "ADVICE_UPDATED": {
        "desc": "Oznámení odesílané zúčastněným stranám (např. když je tvůrce avíza dodavatel\\\\ příjemce, bude odeslán e-mail logistice a dopravcům) po změně avíza.",
        "label": "Změna avíza: {transportTypeName}"
      },
      "AUCTION_ENDED_WITHOUT_OFFERS": {
        "desc": "Oznámení zasílané, když aukce skončí bez nabídek uživatelům, kteří mohou vytvářet aukci.",
        "label": "Aukce skončila bez nabídek: {transportTypeName}"
      },
      "AUCTION_LOST": {
        "desc": "Oznámení odesílané v případě prohry v aukci.",
        "label": "Prohraná aukce: {transportTypeName}"
      },
      "AUCTION_REMINDER": {
        "desc": "Oznámení odesílané jako připomenutí, že se blíží konec aukce.",
        "label": "Připomínka ukončení aukce: {transportTypeName}"
      },
      "AUCTION_WON": {
        "desc": "Oznámení odesílané v případě vítězství v aukci.",
        "label": "Vyhraná aukce: {transportTypeName}"
      },
      "CARRIER_CHOSEN": {
        "desc": "Oznámení odesílané po výběru nebo změně dopravce.",
        "label": "Byl vybrán dopravce: {transportTypeName}"
      },
      "CONFIRMED_CHECKPOINT": {
        "desc": "Oznámení odesílané po potvrzení stavu {CheckpointName}.",
        "label": "Stav potvrzení {checkpointName} pro typ přepravy {transportTypeName}"
      },
      "DISCREPANCY_PROTOCOL_CREATED": {
        "desc": "Oznámení odesílané uživatelům, kteří mají přístup k objednávce, ke které byl protokol přidán, po vytvoření protokolu o neshodě.",
        "label": "Vytvoření protokolu o neshodě pro {transportTypeName}"
      },
      "LATE_ADVICE": {
        "desc": "Oznámení odesílané v případě zpoždění příjezdu řidiče.",
        "label": "Zpoždění avíza: {transportTypeName}"
      },
      "LATE_DOCUMENT_EMAIL": {
        "desc": "Oznámení je zasláno, pokud dodavatel nepřidá přepravní doklady včas.",
        "label": "Zpoždění při předávání dokumentů: {transportTypeName}, {checkpointName}"
      },
      "NEW_ADDED_OFFER": {
        "desc": "Oznámení bylo odesláno, když dopravce přidá novou nabídku.",
        "label": "Přidání nové nabídky: {transportTypeName}"
      },
      "NEW_AUCTION": {
        "desc": "Oznámení zasílané uživatelům, kteří mohou vytvořit aukci, a uživatelům společností zvolených při vytváření této zprávy.",
        "label": "Nová aukce: {transportTypeName}"
      },
      "NEW_OFFER": {
        "desc": "Oznámení, které bude odesláno uživatelům ze společností vybraných při vytváření poptávky.",
        "label": "Nová poptávka na přepravu: {transportTypeName}"
      },
      "OFFER_HAS_BEEN_OUTBID": {
        "desc": "Oznámení odesílané, když dojde ke změně prvního umístění v aukci.",
        "label": "Nabídka byla přebitá: {transportTypeName}"
      },
      "OFFER_REJECTED": {
        "desc": "Oznámení, které bude odesláno uživatelům ze společností vybraných při vytváření poptávky v případě, že nedojde k výběru nabídky.",
        "label": "Bez výběru nabídky u poptávky: {transportTypeName}"
      },
      "ORDER_PASSED": {
        "desc": "Oznámení odesílané uživatelům ze společnosti dopravce po předání objednávky dopravci.",
        "label": "Objednávka byla schválena: {transportTypeName}"
      },
      "ORDER_WAITING_FOR_CONFIRMATION": {
        "desc": "Oznámení odesílané uživatelům ze společnosti dopravce po předání objednávky dopravci, která čeká na jeho potvrzení.",
        "label": "Objednávka čeká na schválení: {transportTypeName}"
      },
      "REJECTED_CHECKPOINT": {
        "desc": "Oznámení odesílané po zamítnutí stavu {checkpointName}.",
        "label": "Zamítnutí stavu {checkpointName} pro typ přepravy {transportTypeName}"
      },
      "TRANSPORT_CREATED": {
        "desc": "Oznámení zaslaná smluvním partnerům po uložení přepravy.",
        "label": "Vytvoření přepravy {transportTypeName}"
      },
      "roleGroups": {
        "desc": "Uživatelé s danou rolí budou moci dostávat oznámení a přihlásit se nebo se odhlásit od zasílání oznámení v nastaveních svého účtu.",
        "label": "Role uživatelů"
      }
    },
    "notificationLog": {
      "TYPE_EMAIL": "E-mail byl odeslán",
      "TYPE_SMS": "SMS byla odeslána"
    },
    "notificationToast": {
      "titles": {
        "error": "Chyba",
        "loading": "nakládání",
        "success": "Úspěch"
      },
      "transportCombining": {
        "error": "Chyba při slučování transportů",
        "loading": "Probíhá kombinování dopravy",
        "success": "Transporty sloučeny"
      }
    },
    "notifications": {
      "label": "Oznámení",
      "noNotificationsForRole": "Pro vybranou roli nejsou k dispozici žádná oznámení.",
      "selectRole": "Nejprve vyberte roli."
    },
    "offer": {
      "auction": "Aukce",
      "company": "Společnost",
      "creator": "Uživatel",
      "dateCreated": "Datum vytvoření",
      "deleted": "Odstraněno",
      "description": "Připomínky k nabídce",
      "enabled": "Povolená",
      "errors": {
        "auction": {
          "hasEnded": "Je nám líto, aukce na tuto objednávku již skončila. Nabídku jsme nemohli uložit."
        },
        "price": {
          "higherThanMaximum": "Cena je vyšší než maximální cena pro tuto objednávku stanovená objednatelem.",
          "lowerThanMinimum": "Cena je nižší než minimální cena pro tuto objednávku stanovená objednatelem.",
          "nonPositiveNumber": "Navržená nabídka musí být číslo větší než nula.",
          "reductionNotMet": "Je nám líto, ale podle požadavků {principalCompanyName} musí minimální částka snížení předchozí nabídky být {minReductionSize} {currencySymbol}."
        }
      },
      "hasWon": "Vítězná",
      "label": "Nabídka",
      "place": "místo",
      "price": "Vaše nabídka",
      "revoke": {
        "alert": "Opravdu chcete aktuální nabídku odvolat?",
        "fail": {
          "CannotRevokeOffer": "Tuto nabídku nelze odvolat."
        },
        "label": "Zamítnout aktuální nabídku",
        "success": "Nabídka byla úspěšně odvolána"
      },
      "save": "Uložit nabídku",
      "savingInProgress": "Ukládání nabídky…",
      "valid": "Platná"
    },
    "page": {
      "pageTitle": "Logistická platforma"
    },
    "partnership": {
      "adviceVerification": {
        "firstPartLabel": "Odešlete e-mail a ověřte data",
        "secondPartLabel": "před každým avízem"
      },
      "allowsPeriodicTransport": "Pravidelná avíza",
      "auxiliary": {
        "editionDisabled": "Nemůžete jej upravit.",
        "title": "Společnost {contractorName} působí jako dodavatel společnosti {principalCompanyName}.",
        "usersEditionDisabled": "Nemůžete upravovat jeho uživatele."
      },
      "contractor": "Partner",
      "contractorOfCompany": "společnosti {companyName}",
      "dateCreated": "Datum vytvoření",
      "logs": {
        "CONTRACTOR_GROUP_ADDED": "Byla přidána skupina dodavatelů.",
        "CONTRACTOR_GROUP_REMOVED": "Skupina dodavatelů byla odstraněna.",
        "DOCUMENT_DISABLED": "Dokument byl zakázán.",
        "DOCUMENT_SAVED": "Dokument byl uložen.",
        "DOCUMENT_UPDATED": "Dokument byl aktualizován.",
        "FILE_ADDED": "Soubor byl přidán.",
        "FILE_DELETED": "Soubor byl odstraněn.",
        "SECTION_CONFIRMED": "Oddíl byl potvrzen.",
        "SECTION_REJECTED": "Oddíl byl zamítnut.",
        "SECTION_SAVED": "Oddíl byl uložen.",
        "SECTION_UPDATED": "Oddíl byl aktualizován.",
        "STATUS_CHANGED": "Stav se změnil.",
        "TYPE_EMAIL": "Oznámení e-mailem"
      }
    },
    "periodicTransport": {
      "cannotChangeCompany": "Tuto společnost nelze odstranit – alespoň jedna společnost s povolenou účastí na pravidelných avízech musí být do této objednávky zapojena.",
      "cannotDeleteStep": "Tento krok nelze odstranit – alespoň jedna společnost s povolenou účastní na pravidelných avízech musí být do této objednávky zapojena.",
      "deleteAllConfirmMsg": "Chcete odstranit všechna budoucí pravidelná avíza? Příkaz „Zrušit“ odstraní pouze tuto objednávku",
      "errors": {
        "chooseAtLeastOneDay": "Vyberte nejméně jeden den.",
        "chooseFirstDayOfPeriodicTransport": "Vyberte den v týdnu první pravidelné přepravy",
        "endDateMustBeAfterStartDate": "Koncové datum nemůže být pozdější než počáteční datum",
        "incorrectRangeOption": "Nesprávný rozsah – možné hodnoty jsou „Denně“, „Týdně“ nebo „Měsíčně“.",
        "windowMustBeInFirstDayOfPeriodicTransport": "První den pravidelného avíza musí být stejné jako naplánované datum avíza.",
        "wrongDateRange": "Pro zvolenou možnost „{range}“ je maximální rozsah pravidelných avíz {interval}. Nastavte kratší rozsah dat pro pravidelná avíza."
      },
      "frequencies": {
        "DAILY": "Denně",
        "MONTHLY": "Měsíčně",
        "WEEKLY": "Týdně"
      },
      "frequency": "Četnost avíza",
      "label": "Pravidelné avízo",
      "updateAllConfirmMsg": "Chcete aktualizovat všechna budoucí pravidelná avíza? Příkaz „Zrušit“ uloží změny pouze v této objednávce"
    },
    "product": {
      "INDEX": {
        "name": "Název produktu",
        "name.autoCompletePlaceholder": "Začněte psát a vyhledejte název produktu."
      },
      "ORDER": {
        "name": "Typ objednávky",
        "name.autoCompletePlaceholder": "Začněte psát a vyhledejte typ objednávky."
      },
      "add": "Přidat produkt",
      "disableConfirm": "Opravdu chcete odstranit tento produkt?",
      "disabled": "Produkt {index} byl úspěšně odebrán.",
      "errors": {
        "index": {
          "tooLong": "Zadaný text je příliš dlouhý. Maximální délka je 255 znaků."
        },
        "name": {
          "tooLong": "Zadaný text je příliš dlouhý. Maximální délka je 255 znaků."
        },
        "shortName": {
          "tooLong": "Zadaný text je příliš dlouhý. Maximální délka je 255 znaků."
        }
      },
      "index": "Index",
      "index.autoCompletePlaceholder": "Začněte psát a vyhledejte produkt podle indexu.",
      "saved": "Produkt {index} byl správně uložen.",
      "shortName": "Krátký název",
      "shortName.autoCompletePlaceholder": "Začněte psát a vyhledejte zkrácený název produktu.",
      "unit": "Jednotka",
      "updated": "Produkt {index} byl správně aktualizován."
    },
    "products": {
      "INDEX": {
        "label": "Produkty",
        "table": "Tabulka produktů"
      },
      "ORDER": {
        "label": "Produkty",
        "table": "Tabulka produktů"
      }
    },
    "queues": {
      "disableConfirm": "Opravdu chcete tuto frontu zakázat?",
      "englishName": "Anglický název",
      "idx": "Objednávka",
      "updateSuccessMessage": "Nastavení fronty {queueName} byla aktualizována."
    },
    "queuesStatus": {
      "sendSmsModal": {
        "messagePlaceholder": "Zadejte zprávu",
        "title": "SMS řidičům z fronty:"
      },
      "tableHeader": "Souhrn stavu aktuálních front"
    },
    "ramp": {
      "backToWarehouse": "Zpět na sklad",
      "deleteDedicatedWindows": "Odstraňte nepoužitá vyhrazená časová okna",
      "disableConfirm": "Opravdu chcete tuto rampu zakázat?",
      "disabledMessage": "Rampa „{rampName}“ byla úspěšně odstraněna.",
      "enableConfirm": "Opravdu chcete tuto rampu povolit?",
      "enabledMessage": "Rampa „{rampName}“ byla úspěšně povolena.",
      "errors": {
        "cannotMoveWindowException": "Okno sem nelze přesunout.",
        "idx": {
          "idxTooBig": "Pořadové č. rampy nesmí být větší než počet ramp v tomto skladu.",
          "nonPositiveInteger": "Pořadové č. rampy musí být kladné celé číslo."
        },
        "shortName": {
          "sizeTooBig": "Maximální délka krátkého názvu je 10 znaků."
        },
        "wrongSwappedWindowSize": "Prohozená okna mají různé doby trvání."
      },
      "idx": "Objednávka",
      "label": "Rampa",
      "maxPallets": "Limit palet",
      "updateSuccessMessage": "Nastavení rampy byla aktualizována.",
      "wantToSwapWindow": "Chcete nahradit časové okno?"
    },
    "ramps": {
      "label": "Rampy"
    },
    "reports": {
      "contractors": {
        "header": "Generovat hlášení pro přepravy, které byly naloženy/vyloženy v rozmezí",
        "label": "Hlášení z časových oken"
      },
      "label": "Hlášení"
    },
    "resetPasswordWindow": {
      "enteredPasswordsIsNotIdentical": "Zadaná hesla musí být shodná.",
      "header": "Změna hesla k systému",
      "info": "Obnovení hesla vyžadují zásady systému.",
      "info2": "Nové heslo se musí lišit od dříve zadaného hesla a musí obsahovat 8 znaků (číslice, malá a velká písmena)."
    },
    "roleGroup": {
      "label": "Typ oprávnění",
      "placeholder": "Vyberte roli"
    },
    "route": {
      "Edition": "Edice",
      "addNewRate": "Přidat novou sazbu",
      "addresses": "Adresy",
      "bestOffer": "Nejlepší nabídka",
      "createTransport": "Vytvořit dopravu z trasy",
      "dataFromRoute": "Data z trasy",
      "details": "Podrobnosti trasy",
      "editRoute": "Upravit trasu",
      "errors": {
        "description": {
          "length": "Zadaný text je příliš dlouhý. Maximální délka je 255 znaků."
        },
        "name": {
          "length": "Zadaný text je příliš dlouhý. Maximální délka je 255 znaků."
        }
      },
      "label": "Trasa",
      "load": "Načítání",
      "name": "Název trasy",
      "namePlaceholder": "Zadejte název trasy",
      "newRoute": "Nová trasa",
      "noTransportTypeAvailable": "Aktuálně nemáte přístup k žádnému typu dopravy s ceníkem",
      "offer": {
        "carrier": "Dopravce",
        "confirmation": {
          "failure": "Bohužel se nám nepodařilo nabídku potvrdit. Obraťte se na správce systému.",
          "message": "Opravdu chcete potvrdit tuto nabídku?"
        },
        "confirmed": "Potvrzeno",
        "date": "Datum sazby",
        "deleteMessage": "Opravdu chcete tuto nabídku odstranit?",
        "edit": "Edice",
        "edition": "Edice",
        "number": "Číslo nabídky",
        "price": "Cena",
        "showLess": "Zobrazovat méně nabídek ({n})",
        "showMore": "Zobrazit více nabídek ({n})",
        "toggleTable": "Zobrazit nebo skrýt seznam nabídek",
        "validity": "Data vypršení platnosti"
      },
      "rate": "Sazba",
      "rates": {
        "label": "Sazby"
      },
      "termOfValidity": "Datum vypršení platnosti",
      "unload": "Vykládání"
    },
    "secondary-section": {
      "bankAccountNumber": "Číslo bankovního účtu",
      "bankFilePattern": {
        "label": "Šablona formuláře"
      },
      "errors": {},
      "internalNotes": "Interní poznámky",
      "label": "Sekundární údaje",
      "otherAttachments": "Další přílohy"
    },
    "section": {
      "accept": "Přijmout",
      "dateOfArchiving": "Datum archivace",
      "dateOfDeletion": "Datum vymazání",
      "dateOfExpiration": "datum vypršení platnosti",
      "deleted": "Smazáno",
      "disable": "Zakázat",
      "disabled": "Zakázané",
      "expired": "Vypršela platnost",
      "label": "oddíl",
      "reject": "Zamítnout",
      "requestVerification": "Ověření požadavku",
      "state": {
        "ACCEPTED": "Přijato",
        "ARCHIVED": "Archivováno",
        "DELETED": "Odstraněno",
        "DOCUMENT_ADDED": "Aktualizováno",
        "EXPIRED": "Vypršela platnost",
        "PENDING": "Čeká na vyřízení",
        "REJECTED": "Zamítnuto",
        "UPDATED": "Aktualizováno",
        "label": "Stav oddílu"
      },
      "successMessage": "Oddíl byl úspěšně uložen",
      "update": "Aktualizovat"
    },
    "section-action": {
      "noCommentError": "Uveďte prosím důvod zamítnutí",
      "rejectionComment": "Uveďte důvod zamítnutí tohoto oddílu"
    },
    "sectionType": {
      "BLOCKADES": "Aktivní blokace",
      "GROUPS": "Skupiny",
      "HISTORY": "Historie",
      "INSURANCE": "Pojištění",
      "INSURANCE_DOMESTIC_OCP": "Pojištění odpovědnosti dopravce pro vnitrostátní dopravu",
      "INSURANCE_INTERNATIONAL_OCP": "Pojištění odpovědnosti dopravce pro mezinárodní dopravu",
      "INSURANCE_OC": "Pojištění občanské odpovědnosti",
      "INSURANCE_OCS": "Pojištění občanskoprávní odpovědnosti nákladního dopravce",
      "LICENCE": "Licence",
      "LICENCE_DOMESTIC": "Tuzemská licence",
      "LICENCE_FORWARDING": "Licence na přepravu zboží zahrnuje forwarding",
      "LICENCE_INTERNATIONAL": "Mezinárodní licence",
      "MAIN_DATA_TYPE": "Hlavní údaje",
      "SECONDARY_DATA_TYPE": "Sekundární údaje",
      "USERS": "Uživatelé"
    },
    "service": {
      "arrival": "Příjezd",
      "company": "Společnost",
      "contractorCompanyPlaceholder": "Název společnosti",
      "createTitle": "Naplánovat službu",
      "departure": "Odjezd",
      "description": "Popis služby",
      "registrationNumber": "Registrační číslo",
      "selectCompany": "Vyberte společnost",
      "serviceman": "Jméno servisního technika",
      "title": "Služba #%@1"
    },
    "serviceNotWorking": {
      "content": "platforma je dočasně nedostupná. Zkuste to znovu později. Omlouváme se za nepříjemnosti.",
      "header": "Dámy a pánové,",
      "signature": "Tým Logintegra "
    },
    "services": {
      "add": "Naplánovat službu",
      "label": "Služby"
    },
    "settings": {
      "backToSettings": "Zpět na nastavení",
      "confirmDelete": "Opravdu chcete toto nastavení odstranit?",
      "create": "Přidat nastavení",
      "desc": {
        "ADDITIONAL_ADD_FILE_BUTTON": "Tlačítko Další pro přidání dokumentů do objednávkového formuláře",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW": "Další informace jsou uvedeny v tisknutelném zobrazení objednávkového formuláře",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW_EN": "Další informace jsou uvedeny v tisknutelném zobrazení objednávkového formuláře – anglická verze",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION": "Určuje, zda má být soubor přidán do přepravy po vítězství v aukci nebo po předání objednávky dopravci.",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION_NAME": "Název souboru připojeného k dopravě po vítězství v aukci nebo předání objednávky dopravci",
        "ADVICE_CREATED_EMAIL_FILE_NAME": "Verze e-mailu o přidání avíza",
        "ADVICE_CREATED_EMAIL_SUBJECT": "Předmět e-mailu o přidání avíza",
        "ADVICE_UPDATED_EMAIL_FILE_NAME": "Verze e-mailu o úpravě avíza",
        "ADVICE_VERIFICATION_EMAIL_ENABLED": "Určuje, zda budou pole související s odesíláním e-mailu ohledně ověření údajů avíza viditelná v podrobnostech společnosti.",
        "ALLOW_SAME_WAREHOUSE_IN_DIFFERENT_STEP": "Umožnit výběr stejného skladu v různých krocích jedné přepravy",
        "ALT_IDENTIFIER_LABEL": "Označení sloupce s číslem objednávky",
        "ALT_IDENTIFIER_LABEL_EN": "Označení sloupce s číslem objednávky – v angličtině",
        "ANNOUNCEMENTS_COUNTER_POLL_INTERVAL": "Frekvence obnovování dat v ms – čítač obecných oznámení",
        "ARCHIVING_JOB_INTERVAL": "Jak dlouho po potvrzení konečného stavu máme přesunout přepravu do archivu.",
        "ASSORTMENT_GROUP_LABEL": "Štítek pole formuláře se skupinou sortimentu",
        "ASSORTMENT_GROUP_LABEL_EN": "Štítek pole formuláře se skupinou skupiny sortimentu – v angličtině",
        "AUCTION_ENDED_WITHOUT_OFFERS_EMAIL_FILE_NAME": "Verze e-mailu o ukončení aukce bez nabídek",
        "AUTOMATIC_DELETING_DEDICATED_WINDOWS_INTERVAL": "Kdy je třeba vzhledem na jejich časové umístění v harmonogramu odstranit nevyužitá vyhrazená okna (je zvolena možnost „Automaticky odstraňovat nevyužitá vyhrazená okna“). Pokud je například nastavena hodnota „-2 h“, nevyužité vyhrazené okno od 16:00 bude přesunuto na 14:00.",
        "AUTOMATIC_DELETING_UNUSED_DEDICATED_WINDOWS_ENABLED": "Automaticky odstraňovat nepoužitá vyhrazená časová okna",
        "AUTOMATIC_WEIGHING": "Povolí funkci automatického vážení",
        "CAN_ARRIVAL_DATE_BE_IN_THE_PAST": "Určuje, zda může být datum příjezdu v minulosti.",
        "CAN_EXPORT_INDEXES_TO_XLS": "Povolí možnost exportovat indexy do souboru aplikace Excel",
        "CAN_REFRESH_MANUALLY": "Možnost ručního obnovování dat",
        "CHANGE_TRANSPORT_INTERVAL": "Do jakého okamžiku ve vztahu k odhadovanému datu nakládky/vykládky by mělo být možné změnit přepravu",
        "CLASSIFICATIONS_TAB_POLL_INTERVAL": "Frekvence obnovování dat v ms – záložka „Klasifikace“",
        "CLASSIFICATION_RESOURCE_COMPLETED_DELAY": "Určuje čas, po kterém by měl objekt zmizet z fronty na záložce „Fronty“ v případě, že dosud nebyl potvrzen stav Odjezd.",
        "CLASSIFIED_RESOURCE_DISAPPEARANCE_DELAY": "Určuje časovou prodlevu, po které přeprava ze záložky „Klasifikace“ zmizí.",
        "CONTRACTOR_NUMBER_LABEL": "Název pole s číslem dodavatele – česky",
        "CONTRACTOR_NUMBER_LABEL_EN": "Název pole s číslem dodavatele – anglicky",
        "CUSTOM_FIELD_SPLIT_VALUE": "Počet volitelných polí definovaných výše, která budou v přepravním formuláři rozdělena do dvou sloupců.",
        "DAYS_TO_RESET_PASSWORD": "Jak často, ve dnech, by měl uživatel vidět výzvu ke změně hesla.",
        "DAYS_TO_SHOW_ADVICE_HISTORY": "Z kolika posledních dní se mají v seznamu tipů při vytváření/úpravách avíza zobrazovat údaje řidičů?",
        "DEFAULT_ADVICE_FUTURE_DAYS_LIMIT": "Výchozí limit dní, který je k dispozici pro avízo přiřazené novým dodavatelům.",
        "DEFAULT_EMAIL_DELAY_INTERVAL": "Výchozí prodleva při odesílání e-mailů",
        "DEFAULT_NUMBER_OF_DISPLAYED_OFFERS": "Výchozí počet nabídek pro trasu v ceníku",
        "DEFAULT_TAB_AFTER_LOGIN": "Výchozí záložka, která se zobrazí po přihlášení.",
        "DELAY_TIME_TO_MOVE_ORDER_TO_ARCHIVE_AFTER_TASK_TIMEOUT": "Doba prodlevy, po které se objednávka automaticky přesune do archivu (pouze pro označené fáze)",
        "DISPLAY_INDEXES_IN_SCHEDULE": "Zobrazit seznam indexů v zobrazení harmonogramu",
        "DISPLAY_TRAILER_REG_NUMBER_IN_ADVICE_COLUMN": "Registrační číslo přívěsu místo registračního čísla nákladního vozidla ve sloupci „Avízo“",
        "DOCUMENTS_MONITORING_INTERVAL": "Velikost zpoždění při přidávání dokumentů, po kterých bude pošta odeslána zákazníkovi.",
        "DOCUMENT_SECTION_VISIBLE_IN_TRANSPORT_FORM": "Viditelnost oddílu dokumentu v přepravním formuláři",
        "EMAIL_NOTIFICATION_CONTAINS_PRODUCTS_SECTION": "Určuje, zda má být oddíl produktů viditelný v e-mailech",
        "EMAIL_NOTIFICATION_CONTAINS_UNLOAD_SECTION": "Určuje, zda má být oddíl vykládek viditelný v e-mailech",
        "ENABLE_TRANSPORT_ROW_HIGHLIGHTING": "Určuje, zda se má řádek přepravy po kliknutí zvýraznit",
        "ENABLE_TRANSPORT_TABLE_DAYS_LIMIT": "Povolí filtr tabulky aktivních objednávek, který skryje objednávky s časovými okny ze vzdálenější budoucnosti.",
        "END_OF_DAY_IN_WAREHOUSE_INTERVAL": "Čas konce dne ve skladu používaný pro výpočet časových oken avíz. Varování! Konec provozu skladu se může lišit. Příklad: Chcete-li nastavit konec dne na 6:00 následujícího dne, hodnota parametru musí být 30 h [30.01. 00:00 + 30 h = 31.01. 06:00]",
        "ERP_IDENTIFIER_LABEL": "Označení sloupce s číslem objednávky ERP",
        "ERP_IDENTIFIER_LABEL_EN": "Označení sloupce s číslem objednávky ERP – v angličtině",
        "FILE_TERMS_OF_CONDITIONS_ACCEPTED": "Odkaz na podmínky služby.",
        "FOOTER": "Odkaz na zápatí zobrazené na platformě",
        "FOREIGN_DELIVERY_MULTIPLIER": "Hodnota, kterou se násobí velikost vykládky v případě dodávek do zahraničí.",
        "HAS_ACCESS_TO_APPLIES": "Určuje, zda mají být doklady dopravce ověřovány",
        "HAS_ACCESS_TO_MAX_PALLET_PER_DAY": "Určuje, zda pro každý den povolit limit palet.",
        "HAS_ACCESS_TO_MAX_PALLET_PER_RAMP": "Určuje, zda pro každou rampu povolit limit palet.",
        "HAS_ACCESS_TO_NUMBER_OF_WINDOWS": "Určuje, zda se má u skladu zobrazit počet oken pro daný den. Závisí na začátku a konci dne ve skladu.",
        "HD_MODE_NEXT_DAY_SHIFT_TIME": "Určuje čas, kdy se má harmonogram v zobrazení HD přesunout na další den (HH:mm).",
        "HOW_MANY_DAYS_BEFORE_INSURANCE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Počet dní před koncem doby platnosti pojištění, kdy má být dopravci zaslána e-mailová připomínka.",
        "HOW_MUCH_TIME_BEFORE_START_WINDOW_EDITING_ORDER_SENDS_SMS": "Jak dlouho před začátkem časového okna vyvolá úprava avíza odeslání SMS.",
        "HYPERLINK_TO_LOGO_ON_NAVBAR": "Hypertextový odkaz na logo zobrazené na navigačním panelu po přihlášení",
        "INVITATION_EXPIRATION_INTERVAL": "Doba platnosti pozvání",
        "LATE_ADVICE_EMAIL_FILE_NAME": "Verze e-mailu o zpoždění",
        "LIMITED_INVITATION_MODE": "Uživatelé – s výjimkou správce, který může provádět vše – mohou jen zvát uživatele pro svoji roli a dodavatele.",
        "LINK_TO_APPLIES_MANUAL": "Odkaz na pokyny k žádosti",
        "LOADING_URL": "Adresa URL platformy",
        "LOGO_ON_LOGIN_PAGE": "Odkaz na logo zobrazené na přihlašovací stránce",
        "LOGO_ON_NAVBAR": "Odkaz na logo zobrazené na navigačním panelu po přihlášení",
        "MANAGE_TRANSPORT_INTERVAL": "Do jakého okamžiku vůči odhadovanému datu nakládky/vykládky má být možné vytvořit nebo změnit přepravní avízo",
        "MAX_CHARACTERS_IN_TRANSPORT_RELATION_COLUMN": "Počet maximálně zobrazených znaků povolených ve sloupci vztahů",
        "MIN_WEIGHT_TO_CREATE_TRANSPORT": "Minimální hmotnost pro automatické vytvoření přepravy",
        "MSH_ADVICE_URL": "Url pro odeslání údajů o poradenství do MSH",
        "MSH_CHECKPOINTS_URL": "Url pro odeslání dat kontrolních bodů do MSH",
        "MSH_COMPANIES_UPDATE_JOB_INTERVAL": "Pracovní interval pro stažení seznamu společností z MSH",
        "MSH_COMPANIES_UPDATE_URL": "Url pro stažení seznamu společností z MSH",
        "MSH_GET_RESOURCES_URL": "Adresa externího zdroje pro stahování dat zdrojů (ovladače, tahače, přívěsy)",
        "MSH_TRANSACTION_CARRIER_URL": "Url pro odeslání dat nosiče z transakce do MSH",
        "MSH_TRANSPORTS_UPDATE_JOB_INTERVAL": "Interval úlohy pro načtení seznamu transportů z MSH",
        "MSH_TRANSPORTS_UPDATE_URL": "Url pro načtení seznamu transportů z MSH",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED": "Název podmínek služby.",
        "NEW_ADVICE_EMAIL_RECIPIENTS": "Další e-mailové adresy k zaslání oznámení o novém avízu (odděleno čárkami, např. xyz@xyz.com,abc@abc.com)",
        "NEW_AUCTION_EMAIL_FILE_NAME": "Verze e-mailu o nové aukci",
        "NEW_TRANSPORT_EMAIL_FILE_NAME": "Verze e-mailu o nové objednávce",
        "ORDER_FORM_PRINTING_ENABLED": "Určuje, zda je v objednávkovém formuláři dostupná tisková verze",
        "ORDER_WAITING_FOR_CONFIRMATION_EMAIL_FILE_NAME": "Verze e-mailu o předání objednávky dopravci",
        "PATRON_IN_CONTRACTOR_DETAILS_ENABLED": "Určuje, zda je pole „Klient“ viditelné v detailech společnosti",
        "POLL_INTERVAL": "Frekvence obnovování dat v ms – tabulka a harmonogram přepravy",
        "QUEUES_TAB_POLL_INTERVAL": "Frekvence obnovování dat v ms – záložka „Fronty“ a „Stav“",
        "RESET_PASSWORD_IS_DISABLED": "Zakázat povinné resetování hesla.",
        "ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER": "Seznam rolí s povoleným doplňujícím filtrováním (moje objednávky, skupinové objednávky, všechny objednávky). Hodnoty uvedené za desetinnou čárkou, např. „ADMIN, GUARD“.",
        "ROLE_ADD_CONTRACTOR_GROUP": "Možnost přidat novou skupinu do oddílu skupiny v podrobnostech dodavatele",
        "ROLE_EDIT_CONTRACTOR_GROUP": "Možnost upravovat existující skupiny dodavatelů",
        "SCHEDULE_LEGEND_ENABLED": "Povolí popisek v harmonogramu",
        "SEND_CONTRACTOR_LATE_ADVICE_MAIL": "Určuje, zda se má odeslat zpožděný e-mail externím dodavatelům, pokud je tvůrce zakázky zadavatelem, nebo vytvářejícímu dodavateli, pokud je tvůrce zakázky dodavatelem.",
        "SEND_FUTURE_PERIODIC_TRANSPORTS_EDIT_MAIL_INTERVAL_IN_DAYS": "Počet dnů v budoucnosti, pro které musí být zasílána e-mailová oznámení v případě úpravy pravidelných avíz.",
        "SEND_SMS_AFTER_CREATING_ORDER": "Po vytvoření avíza odeslat SMS řidiči",
        "SEND_SMS_AFTER_EDITING_ORDER": "Po úpravě avíza odeslat SMS řidiči",
        "SHOULD_RENDER_ALL_TRANSPORT_TYPES": "Ve výchozím nastavení zobrazit všechny typy přepravy v tabulce aktivních objednávek",
        "SHOW_QUANTITY_SUM_CHECK_MESSAGE": "Zobrazí zprávu, pokud je v objednávce více než X palet.",
        "SHOW_TERMS_OF_CONDITIONS_ACCEPTED": "Pole pro přijetí podmínek služby musí být viditelné v oznamovacím formuláři",
        "SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION": "Zobrazí okno pro slučování přenosů, a to i v případě, že je možné pouze jedna možnost sloučení.",
        "SHOW_USERS_FOR_SUPPLIER": "Povolí seznam uživatelů v podrobnostech dodavatele/příjemce",
        "START_OF_DAY_IN_WAREHOUSE_INTERVAL": "Čas začátku dne ve skladu používaný k výpočtu časových oken avíz. Varování! Začátek provozu skladu se může lišit.",
        "TERMS_OF_SERVICE_FILE_EXT": "Přípona souboru s podmínkami služby, např. docx, pdf",
        "TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS": "Čas pro přijetí objednávky v případě automatického předávání objednávek",
        "TIME_TO_ACCEPT_TRANSPORT_INTERVAL": "Čas pro přijetí objednávky",
        "TIME_TO_RESTORE_ORDER_FROM_ARCHIVE": "Čas, do kdy lze objednávky obnovit z archivu.",
        "TIME_WINDOW_OFFSET_INTERVAL": "Počet minut, o kolik lze posouvat časová okna Možné hodnoty jsou 15, 30 nebo 60.",
        "TRANSPORT_FREE_SLOT_SEARCH_INTERVAL_IN_DAYS": "Interval (ve dnech), kdy musíme vyhledat prostor pro časové okno při vytváření pravidelného avíza.",
        "TRANSPORT_IDENTIFIER_TYPE": "Určuje hodnotu, při které bude mít uživatel s přístupem k rozhraní REST API přístup k přepravám. Dostupné možnosti: ID, ERP_IDENTIFIER, ALT_IDENTIFIER.",
        "TRANSPORT_TABLE_DAYS_LIMIT": "Doba (v dnech) od aktuálního data, po které budou objednávky v tabulce aktivních objednávek skryté.",
        "WAREHOUSE_LABEL": "Štítek pole formuláře uvádějící sklad",
        "WAREHOUSE_LABEL_EN": "Štítek pole formuláře uvádějící sklad – v angličtině",
        "WEEK_SCHEDULE_START_DATE_SHIFT": "Počet dní přidaných na začátek harmonogramu v zobrazení týdne",
        "WEIGHT_OF_PALLET": "Udává hmotnost palety v kilogramech.",
        "WEIGHT_SELECTOR": "Označení prvku s hmotností na stránce",
        "WEIGHT_URL": "Odkaz na aplikaci pro vážení",
        "WINDOW_CHANGE_MAIL_DELAY_INTERVAL": "Velikost zpoždění, po kterém bude odeslán e-mail, když přesunete časové okno.",
        "WINDOW_CONSTANT_TIME": "Konstantní hodnota v minutách přidávaná k době vykládky v oknech.",
        "XLS_ADDRESSES_ENABLED": "Do hlášení v XLS zahrnout sloupec „Adresy“",
        "XLS_DESCRIPTION_ENABLED": "Do hlášení v XLS zahrnout sloupec „Poznámky“",
        "XLS_TOP_ROW_ENABLED": "Zapnout záhlaví v hlášeních v XLS."
      },
      "description": "Popis",
      "edit": "Upravit nastavení",
      "editable": "Upravitelný",
      "groupTypeSettings": {
        "description": {
          "canSelectOnlyOneContractorsGroup": "Umožňuje v daném typu vybrat pouze jednu skupinu",
          "partnershipTypesWithAccess": "Určuje typy partnerství, pro které má být typ skupiny viditelný",
          "requiresContractorsGroup": "Určuje, zda je v typu skupiny vyžadován výběr alespoň jedné skupiny",
          "visible": "Určuje, zda má být typ skupiny viditelný při odesílání"
        },
        "label": "Nastavení typu skupiny",
        "labels": {
          "canSelectOnlyOneContractorsGroup": "Povinné vybrat pouze jednu skupinu",
          "partnershipTypesWithAccess": "Pro který typ partnerství by měl být typ skupiny viditelný",
          "requiresContractorsGroup": "Povinné vybrat alespoň jednu skupinu",
          "visible": "Viditelnost typu skupiny v oznámeních"
        }
      },
      "label": "Hlavní nastavení",
      "name": "Název",
      "packageTypes": {
        "CARTONS": {
          "label": "Cartons",
          "lowerCase": "cartons",
          "singular": "carton"
        },
        "CHEP_PALLETS": {
          "label": "Palety CHEP",
          "lowerCase": "palety CHEP",
          "singular": "Paleta CHEP"
        },
        "CUBIC_METERS": {
          "label": "Metry krychlové",
          "lowerCase": "metry krychlové",
          "singular": "Metr krychlový"
        },
        "DISPOSABLE_PALLETS": {
          "label": "Jednorázové palety",
          "lowerCase": "jednorázové palety",
          "singular": "Jednorázova paleta"
        },
        "EURO_PALLETS": {
          "label": "Palety EURO",
          "lowerCase": "palety EURO",
          "singular": "Paleta EURO"
        },
        "LOADING_PLACES": {
          "label": "Nákladní prostory",
          "lowerCase": "nákladní prostory",
          "singulár": "Místo nakládky"
        },
        "PALLETS": {
          "label": "Pallets",
          "lowerCase": "pallets",
          "singular": "pallet"
        },
        "PALLETS_WITH_RETURNED_GOODS": {
          "label": "Palety s vráceným zbožím",
          "lowerCase": "palety s vráceným zbožím",
          "singular": "Paleta s vráceným zbožím"
        },
        "PALLET_PLACES": {
          "label": "Paletové prostory",
          "lowerCase": "paletové prostory",
          "singulár": "Paletová místa"
        },
        "RETURNABLE_PALLETS": {
          "label": "Vratné palety",
          "lowerCase": "vratné palety",
          "singular": "Vratná paleta"
        },
        "SPATIAL_METERS": {
          "label": "Prostorové měřiče",
          "lowerCase": "prostorové měřiče",
          "singular": "Prostorový metr"
        },
        "THE_NUMBER_OF_ITEMS": {
          "label": "Umění",
          "lowerCase": "umění",
          "singular": "Kus"
        }
      },
      "restSettingLabel": "Zbývající nastavení",
      "roleGroups": {
        "actionIsPossible": "akce je možná",
        "actionType": "Oprávnění",
        "actionTypeDescription": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Toto oprávnění umožňuje přijímat a zamítat objednávky převáděné na dodavatele.",
          "ACCESS_CUSTOM_FIELD": "Určuje viditelnost volitelného pole v přepravním formuláři.",
          "ACCESS_INDEX": "Uživatel s touto rolí má přístup k oddílu indexu.",
          "ACCESS_TRANSPORT": "Povolí přístup k typu přepravy (viditelnost typu přepravy v objednávkách)",
          "ADD_ADVICE": "Povoluje možnost přidávat údaje oznámení.",
          "ADD_DOCUMENTS": "Umožňuje připojit k objednávce dokumenty. Přístup ke sloupci s dokumenty v tabulce je povinný.",
          "ADD_NOTES": "Umožňuje přidat do tabulky objednávek poznámku k přepravě. Přístup ke sloupci s poznámkami v tabulce je povinný.",
          "BID_AUCTION": "Povoluje možnost zúčastnit se aukce a podávání nabídek.",
          "CREATE_AUCTION": "Uživatel s tímto oprávněním může vytvořit aukci.",
          "CREATE_TRANSPORT": "Umožní vám vytvořit novou přepravu. Také vyžaduje oprávnění: „Přístup k typu přepravy“.",
          "DELETE_DOCUMENTS": "Umožňuje uživateli odstranit přiložený dokument k přepravě. Přístup ke sloupci s dokumenty v tabulce je povinný.",
          "DELETE_INDEX": "Povoluje odstraňování přidaných indexů přepravy.",
          "DELETE_TRANSPORT": "Uživatel může odstranit dříve vytvořené přepravy. Také vyžaduje oprávnění: „Přístup k typu přepravy“.",
          "DISABLE_DATA_FIELDS": "Určuje, zda by měly být údaje v přepravním formuláři zakázány.",
          "EDIT_DESCRIPTION_FIELD": "Povoluje možnost upravovat pole „Poznámky“ v podrobnostech objednávky. Také vyžaduje oprávnění: „Přístup k typu přepravy“.",
          "EDIT_INDEX": "Povoluje možnost upravovat indexy.",
          "MOVE_ADVICE_WINDOW": "Povoluje možnost posouvat časová okna avíza.",
          "PASS_TRANSPORT": "Povoluje možnost převodu přepravy na dodavatele.",
          "RESIZE_ADVICE_WINDOW": "Umožňuje změnit časová okna avíza. Také vyžaduje oprávnění: „Posunout časové okno avíza“.",
          "ROLE_ACCESS_AUCTION_DATA": "Umožňuje viditelnost aukčních dat",
          "ROLE_ACCESS_DELIVERY_DELIVERY_NUMBER": "Viditelnost čísla dodávky ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_DESCRIPTION": "Viditelnost popisu dodávky ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_FREIGHT_COST": "Viditelnost nákladů na dopravu ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_GROSS_WEIGHT": "Viditelnost čisté hmotnosti dodávky ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_NET_WEIGHT": "Viditelnost hrubé hmotnosti dodávky ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_PACKAGING_SECTION": "Přístup do obalové části při dodávkách",
          "ROLE_ACCESS_FREIGHT_DATA": "Umožňuje viditelnost sazby za přepravu",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "Viditelnost pole \"Další poznámky\"",
          "ROLE_ADD_DELIVERY_PACKAGING": "Přidání obalu při dodání",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Umožňuje automatický převod přepravy na následné dodavatele.",
          "ROLE_CAN_ADD_ROUTE": "Možnost přidat novou nabídku do ceníku",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Výběr více než 1 hodnoty v poli \"Umístění\"",
          "ROLE_CAN_CREATE_DELIVERY": "Umožňuje vytvářet depeše na kartě \"Plánování\"",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Vytvořte dopravu přímo z rozvrhu",
          "ROLE_CAN_DELETE_DELIVERY": "Umožňuje mazat depeše na kartě \"Plánování\"",
          "ROLE_CAN_DELETE_ROUTE": "Možnost mazat trasy v cenících",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "Oprávnění vám umožňuje exportovat sestavu personálních záznamů do souboru Excel",
          "ROLE_CAN_EDIT_DELIVERY": "Umožňuje upravovat depeše na kartě \"Plánování\"",
          "ROLE_CAN_EDIT_ROUTE": "Možnost editace tras v cenících",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Možnost zvýšit počet balíků v přepravě i přes nedostatek místa pro prodloužení časového okna podle algoritmu.",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Skupinová archivace přeprav",
          "ROLE_CAN_SCAN_BARCODES": "Umožňuje skenování čárových kódů pro vyhledávání přeprav",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "Umožňuje zaslání žádosti o poskytnutí avízo údajů prostřednictvím e-mailového avíza",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "Umožňuje zaslání požadavku na doplnění údajů v objednávce prostřednictvím e-mailového upozornění",
          "ROLE_CREATE_SIMILAR": "Vytvořit podobné přepravy",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Vytvořit podobné - možnost definovat počet kopií objednávky",
          "ROLE_CREATE_TRANSPORT_FROM_ROUTE": "Autorita umožňuje přejít do formuláře dopravy přímo z trasy, všechny údaje z trasy již budou vyplněny.",
          "ROLE_DELETE_DELIVERY_PACKAGING": "Vymazat obal při dodání",
          "ROLE_DETACH_DELIVERY": "Umožňuje odpojit dodávky od přepravy",
          "ROLE_EDIT_DELIVERY_PACKAGING": "Úprava obalu při dodání",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "Umožňuje upravit pole \"Další poznámky\"",
          "SWAP_ADVICE_WINDOWS": "Povoluje prohození umístění časových oken avíza.",
          "UPDATE_ADVICE": "Povoluje úpravy údajů oznámení.",
          "UPDATE_CUSTOM_FIELD": "Povoluje upravovat volitelná pole v přepravním formuláři.",
          "UPDATE_MAIN_DATA_SECTION": "Povoluje možnost upravovat přepravu. Platí také pro všechna pole kromě dat avíza, volitelných polí a pole „Poznámky“. Také vyžaduje oprávnění: „Přístup k typu přepravy“.",
          "chooseActionType": "Vybrat oprávnění"
        },
        "addPermission": "Přidat oprávnění",
        "authorityLabels": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Přijmout/zamítnout přepravu",
          "ACCESS_CUSTOM_FIELD": "Viditelnost volitelného pole",
          "ACCESS_INDEX": "Přístup do oddílu indexu",
          "ACCESS_TO_TRANSPORT_DETAILS": "Přístup k podrobnostem přepravy",
          "ACCESS_TRANSPORT": "Přístup k typu přepravy",
          "ADD_ADVICE": "Přidat avízo",
          "ADD_DOCUMENTS": "Přidat dokumenty",
          "ADD_NOTES": "Přidat poznámky do tabulky",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Přidávání časových oken pouze do vyhrazených oken",
          "BID_AUCTION": "Nabídková aukce",
          "CAN_DELETE_APPLIES_FILES": "Odstranit dokumenty ze žádosti dopravce",
          "CREATE_AUCTION": "Vytvořit aukci",
          "CREATE_TRANSPORT": "Vytvořit přepravu",
          "DELETE_DOCUMENTS": "Odstranit dokumenty",
          "DELETE_INDEX": "Odstranit index",
          "DELETE_TRANSPORT": "Odstranit přepravu",
          "DISABLE_DATA_FIELDS": "Skrytí údajů v přepravním formuláři",
          "EDIT_DESCRIPTION_FIELD": "Úpravy pole popisu",
          "EDIT_INDEX": "Aktualizovat index",
          "MOVE_ADVICE_WINDOW": "Posunout časové okno avíza",
          "PASS_TRANSPORT": "Převést přepravu",
          "RESIZE_ADVICE_WINDOW": "Změnit velikost okna avíza",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Možnost vytvořit avízo v časových oknech jakékoli společnosti",
          "ROLE_ACCESS_ALL_QUEUES": "Přístup ke všem frontám",
          "ROLE_ACCESS_ALL_RAMPS": "Přístup ke všem rampám/slotům v harmonogramu",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Možnost vytvořit avízo v časových oknech jakéhokoli oddělení",
          "ROLE_ACCESS_DELIVERY_DELIVERY_NUMBER": "Viditelnost čísla dodávky ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_DESCRIPTION": "Viditelnost popisu dodávky ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_FREIGHT_COST": "Viditelnost nákladů na dopravu ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_GROSS_WEIGHT": "Viditelnost hrubé hmotnosti dodávky ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_NET_WEIGHT": "Viditelnost čisté hmotnosti dodávky ve formuláři dodávky.",
          "ROLE_ACCESS_DELIVERY_PACKAGING_SECTION": "Přístup do obalové části při dodávkách",
          "ROLE_ACCESS_PRODUCT": "Přístup k produktu",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Viditelnost přístupu k místům a frontám",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Přístup k historii použití",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Zobrazit čítač časových oken na záložce harmonogramu",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Přístup k šablonám harmonogramu ",
          "ROLE_ADD_DELIVERY_PACKAGING": "Přidání obalu při dodání",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Přidání nové adresy do podrobností o společnosti",
          "ROLE_ADD_WINDOW_NOTES": "Přidání poznámek do šedého časového okna",
          "ROLE_ADVICE_EXCEL": "Stáhnout objednávky ve formátu Excel",
          "ROLE_ANNOUNCEMENTS_TAB": "Přístup k záložce Obecná oznámení",
          "ROLE_ARCHIVE_TAB": "Přístup k záložce Archiv",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Automatický převod dopravy",
          "ROLE_BLOCK_RAMPS": "Uzamknout harmonogram",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Přístup k archivním dokumentům v oddílech dopravních prostředků",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Viditelnost polí přepravního limitu pro společnost",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "Viditelnost pole \"Kontaktní osoba\" ve formuláři pro přepravu",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Přidat fakturační údaje",
          "ROLE_CAN_ADD_ROUTE": "Přidání nabídky do ceníku",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Výběr více než 1 hodnoty v poli \"Umístění\"",
          "ROLE_CAN_CONFIRM_ROUTE_OFFER": "Schválení nabídky v ceníku",
          "ROLE_CAN_CREATE_DELIVERY": "Vytváření dodávek na kartě \"Plánování\"",
          "ROLE_CAN_CREATE_MAX_PALLET": "Lze nastavit limit palety",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Přidání nabídky do ceníku",
          "ROLE_CAN_CREATE_TRANSPORT_FROM_SCHEDULE": "Vytvořte dopravu přímo z rozvrhu",
          "ROLE_CAN_DELETE_DELIVERY": "Mazání dodávek",
          "ROLE_CAN_DELETE_ROUTE": "Mazání tras z ceníků",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Odstranění nabídky z ceníku",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Možnost stažení vstupenky",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "Stahování záznamů osob v areálu závodu",
          "ROLE_CAN_EDIT_ADDRESSES": "Upravit adresy",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Edice pole s limitem transportů pro firmu",
          "ROLE_CAN_EDIT_DELIVERY": "Upravovat dodávky",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "Umožňuje upravovat údaje na faktuře v přepravní transakci",
          "ROLE_CAN_EDIT_ROUTE": "Úprava tras v cenících",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Úprava nabídky v ceníku",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "Možnost editace pole \"Kontaktní osoba\" ve formuláři pro přepravu",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Avízo nad limitem palety",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Doporučení přes limit palet na vyhrazených oknech",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Stáhněte si fakturační zprávu do souboru aplikace Excel",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "Stažení seznamu bodů do souboru Excel.",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Možnost přesunout okno mimo skupinu sortimentu",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Naplánování avíza v minulosti",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "E-mailová avíza - odeslání žádosti o avízo údajů",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "Oznámení e-mailem – odeslání žádosti o doplnění údajů",
          "ROLE_CAN_SYNCHRONISE_CARRIERS_DATA": "Synchronizace dat operátora",
          "ROLE_CARRIERS_TAB": "Přístup k záložce Dopravci",
          "ROLE_CHANGE_WAREHOUSE": "Vybrat v harmonogramu sklad",
          "ROLE_CLASSIFICATION_TAB": "Přístup k záložce Klasifikace",
          "ROLE_COMPANIES_TAB": "Přístup k záložce Společnosti",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Vytvořit skupinu sortimentu",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Vytváření blokovaných oken",
          "ROLE_CREATE_COMPANY": "Vytvořit novou společnost",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Přístup k nastavení volitelných polí",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Generování protokolu o neshodě",
          "ROLE_CREATE_DRIVER": "Vytvořit nového uživatele",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Vytvoření role ochránce",
          "ROLE_CREATE_PLACE": "Vytváření místa",
          "ROLE_CREATE_PRODUCT": "Vytvořit produkt",
          "ROLE_CREATE_QUEUE": "Vytváření fronty",
          "ROLE_CREATE_RAMP": "Vytvořit novou rampu",
          "ROLE_CREATE_ROLE_GROUP": "Možnost vytvořit novou skupinu rolí",
          "ROLE_CREATE_SIMILAR": "Vytvořit podobou přepravu",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Vytvořit podobné - možnost definovat počet kopií objednávky",
          "ROLE_CREATE_SQUARE": "Vytváření míst",
          "ROLE_CREATE_TRANSPORT_FROM_ROUTE": "Vytvoření dopravy z trasy",
          "ROLE_CREATE_TRUCK": "Vytvořit nový kamion",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Vytvoření role plánování expedice",
          "ROLE_CREATE_USER": "Vytvořit nového uživatele",
          "ROLE_CREATE_WAREHOUSE": "Vytvořit nový sklad",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Vytvořit zónu skladu",
          "ROLE_DELETE_COMPANY": "Odstranit společnost",
          "ROLE_DELETE_DELIVERY_PACKAGING": "Vymazat obal při dodání",
          "ROLE_DELETE_DRIVER": "Odstranit řidiče",
          "ROLE_DELETE_INVITATION": "Odstranění aktivní pozvánky",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Odstranění několika pravidelných avíz",
          "ROLE_DELETE_PRODUCT": "Odstranit produkt",
          "ROLE_DELETE_RAMP": "Odstranit rampu",
          "ROLE_DELETE_TRUCK": "Odstranit kamion",
          "ROLE_DELETE_USER": "Odstranit uživatele",
          "ROLE_DELETE_WAREHOUSE": "Odstranit sklad",
          "ROLE_DETACH_DELIVERY": "Odpojení dodávky od přepravy",
          "ROLE_DEV_TOOLS": "Další vývojářské nástroje",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Upravit skupinu sortimentu",
          "ROLE_EDIT_COMPANY": "Upravit společnost",
          "ROLE_EDIT_CONTRACTOR_GROUP": "Upravit skupinu protistran",
          "ROLE_EDIT_DELIVERY_PACKAGING": "Úprava obalu při dodání",
          "ROLE_EDIT_INCOMPLETE_LOADING": "Manipulace se zaškrtávacím políčkem „Neúplná nakládka“.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Úprava několika pravidelných avíz",
          "ROLE_EDIT_MY_OWN_COMPANY": "Možnost upravovat vlastní firemní údaje",
          "ROLE_EDIT_PLACE": "Úpravy místa",
          "ROLE_EDIT_PRODUCT": "Aktualizovat produkt",
          "ROLE_EDIT_QUEUE": "Úpravy fronty",
          "ROLE_EDIT_RAMP": "Upravit rampu",
          "ROLE_EDIT_SQUARE": "Úpravy míst",
          "ROLE_EDIT_USERS_NOTIFICATION": "Úprava vlastních oznámení",
          "ROLE_EDIT_WAREHOUSE": "Upravit sklad",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Upravit zónu skladu",
          "ROLE_EDIT_WORKFLOW": "Upravit nastavení stavu",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Viditelnost filtrů podle sloupců v tabulce aktivních objednávek",
          "ROLE_INVITATIONS_TAB": "Přístup k záložce Pozvánky",
          "ROLE_INVOICE_COLUMN": "Zobrazit sloupec faktury",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "Správa e-mailových oznámení s žádostí o ověření údajů",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Možnost spravovat obecná oznámení",
          "ROLE_MANAGE_APPLY_SECTIONS": "Správa formulářů žádostí dopravců",
          "ROLE_MANAGE_AUTHORITIES": "Možnost spravovat oprávnění skupiny rolí",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Správa viditelnosti sloupců",
          "ROLE_MANAGE_COMPANY_RAMP": "Přístup k úpravám rampy pro společnost",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Správa vyhrazených oken",
          "ROLE_MANAGE_DELIVERY_SETTINGS": "Řízení porodních zařízení",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Vytváření oken věnovaných firmě klienta",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Správa přístupu k místům a frontám",
          "ROLE_MANAGE_SETTINGS": "Správa nastavení",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Správa nastavení typu přepravy",
          "ROLE_MANAGE_TRUCK_TYPES": "Správa typů nákladních vozidel",
          "ROLE_MANAGE_USER_GROUPS": "Řízení skupin v rámci společnosti dodavatele",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Spravujte pozvánky rolí uživatelů",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Posouvání blokovaných oken",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Posouvání vyhrazených oken",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Přesun časových oken mezi zónami skladu",
          "ROLE_OWN_COMPANY_TAB": "Přístup k záložce Společnosti v účtu dodavatele",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Provádění akcí ve skladu",
          "ROLE_PLANNING_TAB": "Přístup na kartu Plánování",
          "ROLE_PRICE_LIST_TAB": "Přístup na záložku \"Ceníky\"",
          "ROLE_PRODUCTS_TAB": "Přístup k seznamu produktů",
          "ROLE_QUEUES_TAB": "Přístup k záložce Fronty",
          "ROLE_QUEUE_STATUS_TAB": "Přístup k záložce Stav fronty",
          "ROLE_READ_WINDOW_NOTES": "Viditelnost poznámek v šedém časovém okně",
          "ROLE_REJECT_CHECKPOINT": "Zamítnutí stavu",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Změna velikosti blokovaných oken",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Změna velikosti vyhrazených oken",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Možnost obnovit objednávky z archivu",
          "ROLE_REVOKE_CHECKPOINT": "Zrušení stavu ",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Možnost odesílat SMS oznámení všem řidičům v dané frontě",
          "ROLE_SETTINGS_TAB": "Přístup k záložce Nastavení",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Manipulace s volitelnými poli",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Zobrazit seznam souvisejících dodavatelů",
          "ROLE_SHOW_TRANSPORT_LOGS": "Přístup k historii přepravy ",
          "ROLE_SORT_TRANSPORT_TABLE": "Řazení tabulky přepravy",
          "ROLE_SUPER_USER": "Zvláštní uživatelská oprávnění",
          "ROLE_TRANSPORT_TABLE": "Přístup k záložce Přeprava",
          "ROLE_USERS_TAB": "Přístup k záložce Uživatelé",
          "ROLE_WAREHOUSE_TAB": "Přístup k záložce Harmonogram",
          "SWAP_ADVICE_WINDOWS": "Prohození umístění oken avíza",
          "UPDATE_ADVICE": "Aktualizovat avízo",
          "UPDATE_CUSTOM_FIELD": "Aktualizovat volitelné pole",
          "UPDATE_MAIN_DATA_SECTION": "Aktualizovat hlavní data přepravy"
        },
        "authorityRangeLabel": "Rozsah oprávnění",
        "authorityRangeLabels": {
          "ACTIVE": "Pokud objednávka ještě nebyla archivována.",
          "ACTIVE_AUCTION_BASED": "Závisí na aktivní aukci přiřazené objednávce.",
          "ADVICE_RESTRICTION_BASED": "Během zadaného časového rozsahu pro avízo.",
          "NO_ROUTE_OFFER": "Záleží na převzetí přepravy dopravcem za sazbu z ceníku.",
          "OWN_COMPANY_BASED": "Závisí na společnosti tvůrce této objednávky.",
          "TIME_BASED": "Závisí na čase",
          "UNLIMITED": "Bez dalších podmínek"
        },
        "authorityRangePhrases": {
          "ACTIVE": "pokud ještě objednávka nebyla archivována.",
          "ACTIVE_AUCTION_BASED": "pokud přeprava nemá probíhající dražbu.",
          "ADVICE_RESTRICTION_BASED": "během zadaného časového rozsahu pro avízo.",
          "NO_ROUTE_OFFER": "při absenci preferovaných nabídek nebo v případě, kdy přepravci s platnými nabídkami v ceníku nepřijali přepravu k realizaci.",
          "OWN_COMPANY_BASED": "V rámci společnosti tvůrce.",
          "TIME_BASED": "nejpozději {timeInterval} {AfterOrBefore} {timeReference}.",
          "UNLIMITED": "bez dalších podmínek."
        },
        "checkpoint": "Kontrolní bod",
        "conditions": "Podmínky",
        "create": {
          "copyFrom": "Kopírovat oprávnění z jiné skupiny rolí:",
          "info": "Oprávnění lze změnit po uložení skupiny rolí",
          "isGuard": "Role ochrany",
          "isGuardInfo": "Role se zjednodušeným potvrzením stavu",
          "label": "Vytvořit skupinu rolí",
          "name": "Název",
          "nameEn": "Name - EN",
          "noPartnershipInfo": "Žádný typ partnerství nevyvolá vytvoření hlavní skupiny rolí",
          "partnershipType": "Typ partnerství:",
          "usedForDeliveries": "Úloha plánování dispečinku",
          "usedForDeliveriesInfo": "Role pro plánování zásilek na kartě Plánování",
          "usedForMarcos": "Úloha pro integraci systému marcos",
          "usedForMarcosInfo": "Úloha pro integraci systému marcos"
        },
        "customFieldDefinition": "Volitelné pole",
        "dateAfterPhrase": "po",
        "dateBeforePhrase": "před",
        "deleteAllAuthoritiesTitle": "Tlačítko pro odebrání všech oprávnění ze skupiny {title}",
        "generalAuthorityDescriptions": {
          "ACCESS_TO_TRANSPORT_DETAILS": "Povoluje přístup k podrobnostem přepravy.",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Právo omezuje možnost přidání časového okna pouze na místo vyhrazeného okna dostupného uživateli, který přidává okno",
          "CAN_DELETE_APPLIES_FILES": "Možnost odstranit dokumenty připojené k žádosti dopravce.",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Možnost vytvořit avízo ve vyhrazených časových oknech jakékoli společnosti",
          "ROLE_ACCESS_ALL_QUEUES": "Přístup ke všem frontám.",
          "ROLE_ACCESS_ALL_RAMPS": "Přístup ke všem rampám/slotům v harmonogramu.",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Možnost vytvořit avízo ve vyhrazených časových oknech jakéhokoli oddělení",
          "ROLE_ACCESS_AUCTION_DATA": "Viditelnost údajů o aukcích",
          "ROLE_ACCESS_FREIGHT_DATA": "Viditelnost sazeb za přepravu",
          "ROLE_ACCESS_PRODUCT": "Přidá přístup k podrobnostem produktu.",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Oprávnění zviditelňuje přístup k místům a frontám. Když je toto oprávnění povoleno, bude položka viditelná v podrobnostech dodavatele.",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Přístup k historii žádosti dopravce.",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Zobrazit čítač časových oken na záložce harmonogramu",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Povoluje správu šablon časových oken v harmonogramu.",
          "ROLE_ACCESS_TRANSPORT_ADDITIONAL_DESCRIPTION": "Viditelnost pole \"Další poznámky\"",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Umožňuje uživateli přidávat nové adresy ve formuláři podrobností o společnosti.",
          "ROLE_ADD_WINDOW_NOTES": "Umožňuje přidávat poznámky k šedým časovým oknům.",
          "ROLE_ADVICE_EXCEL": "Umožňuje exportovat údaje objednávek do souboru Excel.",
          "ROLE_ANNOUNCEMENTS_TAB": "Povoluje přístup k záložce Obecná oznámení.",
          "ROLE_ARCHIVE_TAB": "Povoluje přístup k záložce „Archiv“.",
          "ROLE_BLOCK_RAMPS": "Možnost uzamknout okno v harmonogramu a možnost uzamknout celou rampu kliknutím na záhlaví rampy.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Umožňuje přístup k archivním dokumentům v detailu dopravního prostředku v záložce \"Dopravní prostředek\".",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Umožňuje viditelnost polí souvisejících s limitem přeprav pro firmu",
          "ROLE_CAN_ACCESS_TRANSPORT_SUPERVISOR": "Viditelnost pole 'Kontaktní osoba'",
          "ROLE_CAN_ADD_INVOICE_IN_TRANSACTION": "Umožňuje přidání fakturačních údajů do transakce z přepravní tabulky",
          "ROLE_CAN_ADD_ROUTE": "Možnost přidat novou nabídku do ceníku",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Umožňuje výběr více než 1 hodnoty v poli \"Umístění\" v podrobnostech objednávky. Vyžaduje rovněž oprávnění: \"Přístup k typu dopravy\"",
          "ROLE_CAN_CONFIRM_ROUTE_OFFER": "Možnost schválení nabídky doplněné do ceníku",
          "ROLE_CAN_CREATE_DELIVERY": "Umožňuje vytvářet dodávky na kartě \"Plánování\"",
          "ROLE_CAN_CREATE_MAX_PALLET": "Možnost nastavit limit palety.",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Možnost přidat novou nabídku do ceníku",
          "ROLE_CAN_DELETE_DELIVERY": "Umožňuje mazat dodávky",
          "ROLE_CAN_DELETE_ROUTE": "Možnost mazat trasy v cenících",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Možnost odstranit nabídku z ceníku",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Umožňuje stažení vstupenky",
          "ROLE_CAN_EDIT_ADDRESSES": "Možnost vytvářet nebo odstraňovat adresy na záložce „Společnost“.",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Umožňuje edici pole s limitem transportů pro firmu",
          "ROLE_CAN_EDIT_DELIVERY": "Umožňuje upravovat dodávky",
          "ROLE_CAN_EDIT_INVOICE_IN_TRANSACTION": "Umožňuje editaci fakturačních údajů v dopravní transakci prostřednictvím veřejného API",
          "ROLE_CAN_EDIT_ROUTE": "Možnost editace tras v cenících",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Možnost úpravy nabídky v ceníku",
          "ROLE_CAN_EDIT_TRANSPORT_PALLETS_WITHOUT_CHANGING_TIME_WINDOW_SIZE": "Umožňuje zvýšit počet balíků v přepravě, i když není prostor pro prodloužení časového okna podle algoritmu.",
          "ROLE_CAN_EDIT_TRANSPORT_SUPERVISOR": "Umožňuje editaci pole 'Kontaktní osoba'",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Možnost avíza ohledně limitu palety.",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Možnost upozornění na nadlimitní paletu v případě časových oken umístěných zcela na vyhrazených oknech",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Umožňuje exportovat data fakturačního přehledu do souboru aplikace Excel.",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": " Oprávnění vám umožňuje exportovat seznam bodů do souboru Excel.",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Možnost přesunout okno mimo skupinu sortimentu",
          "ROLE_CAN_MULTI_ARCHIVE_TRANSPORTS": "Skupinová archivace přeprav",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Možnost naplánovat avízo do minulosti",
          "ROLE_CAN_SCAN_BARCODES": "Skenování čárových kódů",
          "ROLE_CAN_SYNCHRONISE_CARRIERS_DATA": "Autorizace umožňuje synchronizaci dat operátora s externí službou",
          "ROLE_CARRIERS_TAB": "Povoluje přístup k záložce „Dopravci“.",
          "ROLE_CHANGE_WAREHOUSE": "Možnost změnit sklad v harmonogramu.",
          "ROLE_CLASSIFICATION_TAB": "Povoluje přístup k záložce „Klasifikace“.",
          "ROLE_COMPANIES_TAB": "Povoluje přístup k záložce „Společnosti“.",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Možnost vytvořit novou skupinu sortimentu.",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Umožňuje vytvářet v zobrazení harmonogramu šedá časová okna.",
          "ROLE_CREATE_COMPANY": "Toto oprávnění umožňuje vytvořit novou společnost bez odeslání pozvánky.",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Toto oprávnění vám poskytuje přístup k nastavením volitelných polí.",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Umožňuje generovat protokol o neshodě.",
          "ROLE_CREATE_DRIVER": "Možnost přidat nového řidiče.",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Možnost vytvoření role ochránce",
          "ROLE_CREATE_PLACE": "Role vám umožňuje vytvářet nová místa",
          "ROLE_CREATE_PRODUCT": "Povoluje možnost přidat nový produkt. Také vyžaduje oprávnění: „Přístup k produktu“.",
          "ROLE_CREATE_QUEUE": "Role umožňuje vytvářet nové fronty",
          "ROLE_CREATE_RAMP": "Možnost přidat novou rampu do stávajícího skladu. Požadovaná role: „Upravit sklad“.",
          "ROLE_CREATE_ROLE_GROUP": "Možnost vytvořit novou skupinu rolí",
          "ROLE_CREATE_SIMILAR": "Možnost vytvořit podobnou objednávku (kopie objednávky).",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Umožňuje definovat počet objednávek, které mají být vytvořeny",
          "ROLE_CREATE_SQUARE": "Role vám umožňuje vytvářet nová místa",
          "ROLE_CREATE_TRUCK": "Možnost přidat nový kamion.",
          "ROLE_CREATE_USED_FOR_DELIVERIRES_ROLE_GROUP": "Možnost vytvoření role plánování expedice",
          "ROLE_CREATE_USER": "Toto oprávnění umožňuje vytvořit nového uživatele bez odeslání pozvánky.",
          "ROLE_CREATE_WAREHOUSE": "Možnost přidat nový sklad. Požadovaná role: „Upravit sklad“. ",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Možnost vytvořit novou zónu skladu.",
          "ROLE_DELETE_COMPANY": "Možnost odstranit společnost.",
          "ROLE_DELETE_DRIVER": "Možnost odebrat řidiče. ",
          "ROLE_DELETE_INVITATION": "Možnost odstranit aktivní pozvání",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Možnost odstranit více pravidelných avíz",
          "ROLE_DELETE_PRODUCT": "Uživatel s tímto oprávněním může odstranit stávající produkt. Vyžaduje také oprávnění „Přístup k produktu“.",
          "ROLE_DELETE_RAMP": "Možnost odstranit rampu. Požadovaná role: „Upravit sklad“.",
          "ROLE_DELETE_TRUCK": "Možnost odstranit kamion.",
          "ROLE_DELETE_USER": "Možnost odstranit uživatele.",
          "ROLE_DELETE_WAREHOUSE": "Možnost odstranit sklad. Požadovaná role: „Upravit sklad“.",
          "ROLE_DEV_TOOLS": "Další vývojářské nástroje",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Možnost upravit skupinu sortimentu",
          "ROLE_EDIT_COMPANY": "Možnost upravovat údaje o společnosti dodavatele.",
          "ROLE_EDIT_INCOMPLETE_LOADING": "Možnost zaškrtnout nebo zrušit zaškrtnutí políčka „Neúplná nakládka“.",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Možnost upravovat více pravidelných avíz",
          "ROLE_EDIT_MY_OWN_COMPANY": "Možnost upravovat vlastní firemní údaje",
          "ROLE_EDIT_PLACE": "Role umožňuje upravit místo",
          "ROLE_EDIT_PRODUCT": "Umožňuje upravit stávající produkt. Také vyžaduje oprávnění: „Přístup k produktu“.",
          "ROLE_EDIT_QUEUE": "Role umožňuje upravit frontu",
          "ROLE_EDIT_RAMP": "Rampu lze upravovat.",
          "ROLE_EDIT_SQUARE": "Role umožňuje upravit místo",
          "ROLE_EDIT_TRANSPORT_ADDITIONAL_DESCRIPTION": "Umožňuje upravit pole \"Další poznámky\"",
          "ROLE_EDIT_USERS_NOTIFICATION": "Umožňuje uživateli upravovat oznámení přiřazená k účtu.",
          "ROLE_EDIT_WAREHOUSE": "Možnost upravovat sklad",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Možnost upravit zónu skladu.",
          "ROLE_EDIT_WORKFLOW": "Možnost upravovat podrobnosti nastavení stavu pro každý typ přepravy.",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Umožňuje přístup k filtrům nad sloupci v aktivní tabulce přepravy.",
          "ROLE_INVITATIONS_TAB": "Povoluje přístup k záložce „Pozvání“.",
          "ROLE_INVOICE_COLUMN": "Zobrazit sloupec faktury",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "Oprávnění umožňuje povolit/zakázat e-mailové oznámení s požadavkem na ověření dat avíza a umožňuje změnit čas odeslání e-mailu. Po povolení oprávnění bude položka viditelná v podrobnostech dodavatele.",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Povoluje přístup ke správě obecných oznámení. Požadovaná role: „Přístup k záložce Obecná oznámení“.",
          "ROLE_MANAGE_APPLY_SECTIONS": "Správa nastavení Správa formulářů žádostí dopravce. Možnost přijmout a zamítnout jednotlivé oddíly žádosti dopravce.",
          "ROLE_MANAGE_AUTHORITIES": "Možnost vytvářet, upravovat a odstraňovat oprávnění skupiny rolí (obecné a typy přepravy).",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Umožňuje spravovat viditelnost sloupců v zobrazení oprávnění podle typu přepravy.",
          "ROLE_MANAGE_COMPANY_RAMP": "Povoluje přístup k oddílu „Vydání rampy“ v podrobnostech dodavatele",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Poskytuje oprávnění k vytváření a odstranění vyhrazeného okna v zobrazení harmonogramu časových oken.",
          "ROLE_MANAGE_DELIVERY_SETTINGS": "Řízení porodních zařízení",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Možnost vytvoření vyhrazených oken pro klientskou společnost. Uživatel klientské společnosti bude moci do takového vyhrazeného okna přidat libovolné poradenství.",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Oprávnění poskytuje přístup k povolení/zákazu míst a front.",
          "ROLE_MANAGE_SETTINGS": "Povoluje správu nastavení",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Správa nastavení typu přepravy.",
          "ROLE_MANAGE_TRUCK_TYPES": "Správa typů nákladních vozidel",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Umožňuje přiřadit seznam rolí, které mají být pozvány pro konkrétní uživatelskou roli",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Umožňuje přesunout šedá časová okna.",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Umožňuje posouvat vyhrazená časová okna.",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Umožňuje přesouvat časová okna mezi zónami skladu.",
          "ROLE_OWN_COMPANY_TAB": "Povoluje přístup k záložce „Společnost“ v účtu dodavatele.",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Role k provádění akcí skladu",
          "ROLE_PLANNING_TAB": "Umožňuje přístup na kartu Plánování",
          "ROLE_PRICE_LIST_TAB": "Umožňuje přístup na kartu \"Ceníky\"",
          "ROLE_PRODUCTS_TAB": "Přidá přístup k záložce související s produktem.",
          "ROLE_QUEUES_TAB": "Povoluje přístup k záložce „Fronty“.",
          "ROLE_QUEUE_STATUS_TAB": "Povoluje přístup k záložce „Stav fronty“.",
          "ROLE_READ_WINDOW_NOTES": "Zapne viditelnost poznámek v šedých oknech pro uživatele s tímto oprávněním.",
          "ROLE_REJECT_CHECKPOINT": "Možnost zamítnout stav.",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Umožňuje změnit velikost šedých časových oken (blokovaná okna). Také vyžaduje oprávnění: „Posouvání blokovaných oken“.",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Umožňuje změnit velikost vyhrazených časových oken. Také vyžaduje oprávnění: „Posouvání vyhrazených oken“.",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Možnost obnovovat objednávky z archivu po automatické archivaci z důvodu zpoždění",
          "ROLE_REVOKE_CHECKPOINT": "Možnost vrátit stav.",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Povoluje možnost odeslat SMS oznámení všem řidičům v dané frontě (záložka Stav fronty).",
          "ROLE_SETTINGS_TAB": "Povoluje přístup k záložce „Nastavení“.",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Toto oprávnění umožňuje přidávat, upravovat a odstraňovat volitelná pole. Vyžaduje také oprávnění „Přístup k nastavení volitelných polí“.",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Toto oprávnění umožňuje zobrazit v podrobnostech dané společnosti seznam souvisejících dodavatelů. Vztahy jsou generovány prostřednictvím pozvánek.",
          "ROLE_SHOW_TRANSPORT_LOGS": "Zobrazit oddíl „Historie“ v přepravním formuláři.",
          "ROLE_SORT_TRANSPORT_TABLE": "Role umožňuje seřadit tabulku přepravy podle vybraného sloupce.",
          "ROLE_SUPER_USER": "Zvláštní uživatelská oprávnění.",
          "ROLE_TRANSPORT_TABLE": "Uživatelé s touto rolí mohou přistupovat k tabulce přepravy.",
          "ROLE_USERS_TAB": "Povoluje přístup k záložce „Uživatelé“. ",
          "ROLE_WAREHOUSE_TAB": "Povoluje přístup k záložce „Harmonogram“."
        },
        "generalPermissions": "Obecná oprávnění",
        "header": "Oprávnění pro skupinu rolí ",
        "invitationSection": "Uživatel může pozvat role",
        "label": "Nastavení oprávnění",
        "noAuthorities": "Žádná oprávnění.",
        "permissions": "Oprávnění",
        "successCreate": "Skupina rolí byla úspěšně vytvořena",
        "successDelete": "Oprávnění bylo úspěšně zakázáno",
        "successUpdate": "Změny byly úspěšně uloženy",
        "sureToDelete": "Opravdu chcete zakázat toto oprávnění?",
        "sureToDeleteAll": "Opravdu chcete pro tuto skupinu zakázat všechna oprávnění?",
        "timeIntervalLabel": "Interval",
        "timeReferenceLabel": "Časové propojení",
        "timeReferences": {
          "EARLIEST_TIME_WINDOW_START_AFTER_CHANGE": "začátek prvního kroku",
          "EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE": "začátek prvního kroku",
          "LATEST_TIME_WINDOW_STOP": "konec posledního kroku",
          "TRANSPORT_DATE_CREATED": "datum přepravy vytvořeno"
        },
        "usersList": "Seznam uživatelů"
      },
      "transportTypes": {
        "create": "Vytvořit typ přepravy",
        "englishName": "Anglický název",
        "group": "Skupina",
        "label": "Nastavení typů přepravy",
        "name": "Název",
        "permissions": "Oprávnění",
        "permissionsSaveSuccess": "Nastavení oprávnění jsme úspěšně uložili.",
        "selectRoleGroups": "Vyberte role, které budou moci vytvářet přepravy tohoto typu",
        "settings": "Nastavení typu přepravy",
        "settingsDescription": {
          "adviceRequiresPhoneNumber": "Určuje, zda pole: \"Číslo mobilního telefonu\" bude při vytváření avíza povinné",
          "adviceRestrictionInEveryStep": "Určuje, zda bude pole „Časový rozsah avíza“ viditelné v každém kroku.",
          "adviceSavedWithTransport": "Údaje avíza budou uloženy s přepravou, zadání přepravních údajů proběhne přes přepravní formulář",
          "allowLoadStepTypeChange": "Určuje, zda lze změnit krok nakládky v přepravním formuláři mezi fází ve skladu a fází u dodavatele.",
          "allowUnloadStepTypeChange": "Určuje, zda lze typ kroku vykládky v přepravním formuláři změnit mezi fází ve skladu a fází u dodavatele.",
          "allowsAltIdentifier": "Určuje viditelnost doplňkového identifikátoru objednávky (altIdentifier). Toto pole se zobrazí v přepravním formuláři pod názvem nastaveným v nastavení: „ALT_IDENTIFIER_LABEL“.",
          "allowsCarrier": "Určuje, zda se přepravy účastní společnost „Dopravce“. Určuje viditelnost pole „Dopravce“ v přepravním formuláři.",
          "allowsContractor": "Určuje, zda se přepravy účastní společnost „Dodavatel“. Určuje viditelnost pole „Dodavatel“ ve formuláři objednávky.",
          "allowsDriverDocumentNumber": "Určuje viditelnost pole „Číslo dokladu řidiče“",
          "allowsDriverName": "Určuje, zda pole: \"Jméno a příjmení řidiče\" bude při vytváření avíza viditelné",
          "allowsErpIdentifier": "Určuje viditelnost identifikátoru objednávky (erpIdentifier). Toto pole se zobrazí v přepravním formuláři pod názvem nastaveným v nastavení: „ERP_IDENTIFIER_LABEL\".",
          "allowsPeriodicTransport": "Určuje, zda bude k dispozici možnost pravidelného avíza. Tato možnost bude k dispozici pouze pro vybrané dodavatele/příjemce.",
          "allowsPhoneNumber": "Určuje, zda pole: \"Číslo mobilního telefonu\" bude při vytváření avíza viditelné",
          "allowsRodoCheckbox": "Určuje, zda je podmínka GDPR ve formuláři avíza viditelná a povinná",
          "allowsService": "Určuje, zda se přepravy účastní společnost „Servisman“. Určuje viditelnost pole „Servis“ v přepravním formuláři.",
          "allowsTrailer": "Určuje, zda pole: \"Registrační číslo přívěsu/návěsu\" bude při vytváření avíza viditelné",
          "allowsTransportSupervisor": "Určuje viditelnost pole \"Kontaktní osoba\" v přepravním formuláři",
          "altIdentifierIsCreatedAutomatically": "Určuje, zda bude automaticky vytvořeno pole identifikátoru přepravy (altIdentifier) ve formátu UID / XXXX / 2018, kde „XXXX“ je specifikováno v poli: „Střední část čísla altIdentifier“. Pokud je tato možnost povolená, nebudete moci identifikátor v přepravním formuláři upravit.",
          "calculatingTimeWindowSizeFromAlgorithm": "Určuje, zda má být algoritmus zadaný ve skladu použit k výpočtu velikosti časového okna",
          "calculatingTimeWindowSizeFromDelivery": "Určuje, zda použít data doručení k výpočtu velikosti časového úseku",
          "carRegistrationNumberTranslationKey": "Překladový klíč pro pole \"Registrační číslo vozidla\"",
          "carrierMustBeSelectedBeforeAdvice": "Avízo lze přidat do přepravy jen v případě, že k ní byl přiřazen dopravce.",
          "checkAmountOfCover": "Ověřte výši krytí při vytváření aukcí nebo předávání dopravci",
          "dateFilterProperty": "Určuje pole, po kterém budou objednávky v archivu ve výchozím nastavení filtrovány",
          "defaultAmountOfCover": "Výchozí hodnota pole: \"Množství obalu\"",
          "defaultServicemanCompany": "Vybraná servisní společnost se automaticky nastaví při vytváření nové přepravy.",
          "defaultTextForTransportAdditionalDescription": "Výchozí text v poli \"Dodatečné poznámky\"",
          "dimensionsVisibleInContractorStep": "Určuje viditelnost pole „Rozměry“ v přepravním formuláři (krok bez skladu)",
          "dimensionsVisibleInWarehouseStep": "Určuje viditelnost pole „Rozměry“ v přepravním formuláři (krok se skladem)",
          "duplicateStepCountryFieldToNewSteps": "Určuje, zda se má pole \"Země\" automaticky vyplnit v nově přidaném krokovacím zařízení na základě hodnoty z prvního neukládacího krokovacího zařízení",
          "generateTransportOrder": "Určuje, zda se při vytvoření přepravy vygeneruje přiřazený přepravní příkaz.",
          "generatingWeightReceiptEnabled": "Určuje možnost stáhnout pro přepravu doklad o hmotnosti Potvrzení lze stáhnout v oddílu „Vážení“ přepravního formuláře. Účet s rolí GUARD může lístek stáhnout také přímo z tabulky přepravy.",
          "hasCmrDocumentEnabled": "Určuje, že pokud má typ přepravy dokument CMR, bude k dispozici ke stažení v oddílu „Dokumenty“ a v tabulce přepravy ve sloupci dokumentů.",
          "hasDiscrepancyReportEnabled": "Určuje viditelnost oddílu „Hlášení o neshodě“ v přepravním formuláři",
          "hasEntrancePassDocumentEnabled": "Určuje, zda bude po přidání avíza vytvořena vstupní jízdenka v typu přepravy, bude k dispozici ke stažení v sekci \"Dokumenty\" a v tabulce dopravy ve sloupci doklady",
          "hasProductSpecificationDocumentEnabled": "Určuje, že pokud má typ přepravy dokument „Specifikace produktu“, bude k dispozici ke stažení v oddílu „Dokumenty“ a v tabulce přepravy ve sloupci dokumentů.",
          "hasVisibleSteps": "Určuje, zda budou pole související s kroky přepravy ve formuláři viditelná.",
          "idx": "Určuje pořadí, ve kterém se v seznamu zobrazují přepravy",
          "isAmountOfCoverVisible": "Viditelnost pole: \"Množství obalu\"",
          "isInternalReleaseNumberVisibleInContractorStep": "Určuje viditelnost pole „Číslo dodacího listu“ v přepravním formuláři (krok bez skladu)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Určuje viditelnost pole „Číslo dodacího listu“ v přepravním formuláři (krok se skladem)",
          "isLoadStepInWarehouseByDefault": "Určuje, zda bude ve výchozím nastavení krok nakládky při vytváření objednávky ve skladu (lze změnit, když parametr \"allowLoadStepTypeChange == true\")",
          "isTruckTypeVisible": "Nastavit pole \"Typ dopravy\" jako viditelné",
          "isUnloadStepInWarehouseByDefault": "Určuje, zda bude ve výchozím nastavení krok vykládky při vytváření objednávky ve skladu (lze změnit, když parametr \"allowUnloadStepTypeChange == true\")",
          "isVolumeVisibleInContractorStep": "Určuje viditelnost pole „Objem“ v přepravním formuláři (krok bez skladu)",
          "isVolumeVisibleInWarehouseStep": "Určuje viditelnost pole „Objem“ v přepravním formuláři (krok se skladem)",
          "isWarehouseZoneVisible": "Určuje viditelnost pole „Zóna skladu/Sortiment“ v přepravním formuláři",
          "isWeightVisibleInContractorStep": "Určuje viditelnost pole „Hmotnost“ v přepravním formuláři (krok bez skladu)",
          "isWeightVisibleInWarehouseStep": "Určuje viditelnost pole „Hmotnost“ v přepravním formuláři (krok se skladem)",
          "numberOfDaysAddedToFirstStep": "Specifies the number of days to be added to the planned date or time range in the transport form for the first step:",
          "numberOfDaysAddedToNextSteps": "Specifies the number of days to be added to the planned date or time range in the transport form for the second and each subsequent step:",
          "pastingFromExcelEnabled": "Možnost vkládání údajů avíza z Excelu",
          "purchaseOrderNumberVisibleInContractorStep": "Určuje viditelnost pole „Číslo objednávky“ v přepravním formuláři (krok bez skladu)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Určuje viditelnost pole „Číslo objednávky“ v přepravním formuláři (krok se skladem)",
          "quantitySumCheckValue": "Určuje počet palet, po kterém se zobrazí v přepravním formuláři potvrzovací zpráva",
          "requiresAddress": "Určuje, zda je nutné do kroku přepravy mimo sklad přidat adresu",
          "requiresAdvice": "Údaje avíza jsou povinné – vypnutí této možnosti znamená, že formulář avíza a podrobnosti avíza mohou zůstat prázdné",
          "requiresAdviceDateRestrictions": "Určuje, zda pole „Časový rozsah avíza“ bude při vytváření přepravy povinné a viditelné.",
          "requiresAltIdentifier": "Určuje, zda bude pole „altIdentifier“ při vytváření přepravy viditelné a povinné. Toto pole se zobrazí v přepravním formuláři pod názvem nastaveným v nastavení: „ALT_IDENTIFIER_LABEL“.",
          "requiresArrivalAndDepartureDate": "Určuje, zda bude pole „Datum vyzvednutí“/„Datum dodání“ viditelné a povinné (etapa s dodavatelem)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Určuje, zda jsou pole \"Ordered pickup date\"/\"Ordered delivery date\" viditelná ve formuláři dopravy (fáze se skladem)",
          "requiresCarRegistrationNumber": "Určuje, zda pole: \"Registrační číslo vozidla\" bude při vytváření avíza povinné",
          "requiresCarrier": "Určuje, zda pole „Dopravce“ bude při vytváření přepravy povinné.",
          "requiresContractor": "Při uložení přepravy musíte uvést \"Podrobnosti dodavatele/odběratele\". Pokud budou v přepravě viditelné kroky nesouvisející se skladem, lze v tomto oddílu zvolit dodavatele. V opačném případě budete moci vybrat dodavatele v hlavním formuláři přepravy.",
          "requiresDriverDocumentNumber": "Určuje, zda pole: \"Číslo dokladu řidiče\" bude při vytváření avíza povinné",
          "requiresDriverLanguage": "Určuje, zda je pole „Jazyk řidiče“ viditelné a povinné",
          "requiresDriverName": "Určuje, zda pole: \"Jméno a příjmení řidiče\" bude při vytváření avíza povinné",
          "requiresErpIdentifier": "Určuje, zda bude pole „erpIdentifier“ při vytváření přepravy viditelné a povinné. Toto pole se zobrazí v přepravním formuláři pod názvem nastaveným v nastavení: „ERP_IDENTIFIER_LABEL“.",
          "requiresService": "Určuje, zda pole „Servis“ bude při vytváření přepravy povinné.",
          "requiresStepsWeight": "Určuje, zda pole „Hmotnost“ bude při vytváření přepravy povinné.",
          "requiresTimeWindow": "Chcete-li přepravu uložit, musíte vybrat časové okno. To znamená, že po uložení přepravního formuláře uživatel přímo přejde do harmonogramu, kde vybere volné místo na časové okno.",
          "requiresTrailer": "Určuje, zda pole: \"Registrační číslo přívěsu/návěsu\" bude při vytváření avíza povinné",
          "requiresTransportSupervisor": "Povinnost pole \"Kontaktní osoba\"",
          "requiresTruckType": "Povinnost pole \"Typ dopravy\"",
          "requiresUniqueErpIdentifier": "Určuje, zda pole:\" erpIdentifier \"má mít jedinečné hodnoty. Smazané objednávky se při kontrole jedinečnosti neberou v úvahu.",
          "requiresWarehouse": "Chcete-li přepravu uložit, musíte vybrat sklad. Při výběru možnosti „Povinný výběr časového okna při vytváření přepravy“ bude také potřeba určit sklad.",
          "shouldCopyAdviceOnTransportCopy": "Určuje, zda mají být data avíza zkopírována s možností \"Vytvořit podobné\"",
          "showAddressFieldsInContractorSteps": "Určuje, zda mají být pole adresy viditelná v krocích dodavatelů.",
          "useResourcesFromExternalSource": "Zdrojová data (řidiči, tahače, přívěsy) stažená z externího zdroje",
          "usedForDeliveries": "Možnost vytvářet dodávky",
          "usedForMarcos": "Typ přepravy používaný pro integraci systému marcos",
          "windowColorDependsOnWarehouse": "Určuje, zda barva časového okna závisí jen na stavech spojených s daným oknem"
        },
        "settingsGroup": {
          "advice": "Obecná nastavení avíza",
          "adviceForm": "Nastavení formuláře avíza",
          "general": "Obecné nastavení",
          "identifier": "Nastavení související s identifikátorem přepravy",
          "index": "Nastavení indexů",
          "step": "Nastavení kroků přepravy",
          "transaction": "Nastavení forwardingové přepravy pro dopravce",
          "transport": "Obecné nastavení přepravy",
          "transportForm": "Nastavení vztahující se k přepravnímu formuláři"
        },
        "settingsLabel": {
          "adviceRequiresPhoneNumber": "Pole „Číslo mobilního telefonu“ je povinné",
          "adviceRestrictionInEveryStep": "Viditelnost pole „Časový rozsah avíza“ v každém kroku",
          "adviceSavedWithTransport": "Údaje avíza budou uložena s přepravou",
          "allowLoadStepTypeChange": "Viditelnost zaškrtávacího políčka „Sklad“ v kroku nakládky",
          "allowUnloadStepTypeChange": "Viditelnost zaškrtávacího políčka „Sklad“ v kroku vykládky",
          "allowsAltIdentifier": "Viditelnost doplňkového identifikátoru objednávky ve formuláři – „altIdentifier“",
          "allowsCarrier": "Do přepravy zahrnout společnost dopravce",
          "allowsContractor": "Do přepravy zahrnout společnost dodavatele",
          "allowsDriverDocumentNumber": "Nastavit pole „Číslo dokladu řidiče“ jako viditelné",
          "allowsDriverName": "Viditelnost jména řidiče ve formuláři avíza",
          "allowsErpIdentifier": "Viditelnost identifikátoru objednávky ve formuláři – „erpIdentifier“",
          "allowsPeriodicTransport": "Určuje, zda lze přidat pravidelná avíza",
          "allowsPhoneNumber": "Viditelnost telefonního čísla řidiče ve formuláři avíza",
          "allowsRodoCheckbox": "Povolení podmínky GDPR",
          "allowsService": "Do přepravy zahrnout společnost servis",
          "allowsTrailer": "Viditelnost pole: „Registrační číslo přívěsu/návěsu“",
          "allowsTransportSupervisor": "Viditelnost pole „Kontaktní osoba“ ve formuláři přepravy",
          "altIdentifierIsCreatedAutomatically": "Automatické vytvoření doplňkového čísla objednávky – „altIdentifier“",
          "calculatingTimeWindowSizeFromAlgorithm": "Vypočítat délku časového okna na základě algoritmu skladu",
          "calculatingTimeWindowSizeFromDelivery": "Výpočet velikosti okna na základě dodacích údajů",
          "carRegistrationNumberTranslationKey": "Překladový klíč pro pole \"Registrační číslo vozidla\" (TRUCK, VEHICLE)",
          "carrierMustBeSelectedBeforeAdvice": "Určuje, zda lze přidat avízo bez výběru dopravce",
          "checkAmountOfCover": "Ověřte výši krytí při vytváření aukcí nebo předávání dopravci",
          "dateFilterProperty": " Výchozí filtr archivu: ",
          "defaultAmountOfCover": "Výchozí hodnota pole: \"Množství obalu\"",
          "defaultServicemanCompany": "Výchozí typ služby společnost",
          "defaultTextForTransportAdditionalDescription": "Výchozí text v poli \"Dodatečné poznámky\"",
          "dimensionsVisibleInContractorStep": "Viditelnost pole „Rozměry“ v přepravním formuláři (krok bez skladu)",
          "dimensionsVisibleInWarehouseStep": "Viditelnost pole „Rozměry“ v přepravním formuláři (krok se skladem)",
          "documentIdentifierTranslationKey": "Překladový klíč pro pole \"Číslo dodacího listu\" field (DELIVERY_NOTE_NUMBER, DELIVERY_NOTE_NUMBER_SHORT, CUSTOMERS_ORDER_NUMBER, ISSUE_ORDER_NUMBER, REFERENCE_NUMBER)",
          "duplicateStepCountryFieldToNewSteps": "Určuje, zda se má pole \"Země\" automaticky vyplnit v nově přidaném krokovacím zařízení na základě hodnoty z prvního neukládacího krokovacího zařízení",
          "generateTransportOrder": "Určuje, zda se při vytvoření přepravy vygeneruje přiřazený přepravní příkaz.",
          "generatingTransportWeightReceiptEnabled": "Určuje, zda má být v typu generována účtenka o hmotnosti",
          "generatingWeightReceiptEnabled": "Možnost generovat doklad o hmotnosti",
          "hasCmrDocumentEnabled": "Určuje, zda má typ přepravy dokument CMR",
          "hasDiscrepancyReportEnabled": "Viditelnost oddílů: „Hlášení o neshodě“ v přepravním formuláři",
          "hasEntrancePassDocumentEnabled": "Určuje, zda bude vstupní lístek vytvořen po přidání avíza.",
          "hasProductSpecificationDocumentEnabled": "Určuje, zda má typ přepravy dokument „Specifikace produktu“",
          "hasVisibleSteps": "Viditelnost oddílu kroků v přepravním formuláři",
          "idx": "Idx",
          "isAmountOfCoverVisible": "Viditelnost pole: \"Množství obalu\"",
          "isInternalReleaseNumberVisibleInContractorStep": "Viditelnost pole „Číslo dodacího listu“ v přepravním formuláři (krok bez skladu)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Viditelnost pole „Číslo dodacího listu“ v přepravním formuláři (krok se skladem)",
          "isLoadStepInWarehouseByDefault": "Krok nakládky je ve skladu ve výchozím nastavení",
          "isTruckTypeVisible": "Nastavit pole \"Typ dopravy\" jako viditelné",
          "isUnloadStepInWarehouseByDefault": "Krok vykládky je ve skladu ve výchozím nastavení",
          "isVolumeVisibleInContractorStep": "Viditelnost pole „Objem“ v přepravním formuláři (krok bez skladu)",
          "isVolumeVisibleInWarehouseStep": "Viditelnost pole „Objem“ v přepravním formuláři (krok se skladem)",
          "isWarehouseZoneVisible": "Viditelnost pole „Skladová zóna/sortiment“ v přepravním formuláři",
          "isWeightVisibleInContractorStep": "Viditelnost pole „Hmotnost“ v přepravním formuláři (krok bez skladu)",
          "isWeightVisibleInWarehouseStep": "Viditelnost pole „Hmotnost“ v přepravním formuláři (krok se skladem)",
          "numberOfDaysAddedToFirstStep": "The number of days to be added to the planned date or time range in the transport form for the first step:",
          "numberOfDaysAddedToNextSteps": "The number of days to be added to the planned date or time range in the transport form for the second and each subsequent step:",
          "pastingFromExcelEnabled": "Možnost vkládání údajů avíza z Excelu",
          "purchaseOrderNumberVisibleInContractorStep": "Viditelnost pole „Číslo objednávky“ v přepravním formuláři (krok bez skladu)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Viditelnost pole „Číslo objednávky“ v přepravním formuláři (krok se skladem)",
          "quantitySumCheckValue": "Množství, po kterém se zobrazí varování",
          "requiresAddress": "Údaje o adrese jsou v případě kroku bez skladu povinné",
          "requiresAdvice": "Údaje avíza jsou povinné",
          "requiresAdviceDateRestrictions": "Nastavit pole „Časový rozsah avíza“ jako viditelné a povinné",
          "requiresAltIdentifier": "Pole „altIdentifier“ je povinné",
          "requiresArrivalAndDepartureDate": "Nastavit pole „Datum vyzvednutí“/„Datum dodání“ jako viditelné a povinné(etapa s dodavatelem)",
          "requiresArrivalAndDepartureDateInWarehouseStep": "Nastavit pole „Datum vyzvednutí“/„Datum dodání“ jako viditelné a povinné(fáze skladu)",
          "requiresCarRegistrationNumber": "\"Registrační číslo vozidla\" povinné pole",
          "requiresCarrier": "Pole „Dopravce“ je povinné",
          "requiresContractor": "Údaje „Dodavatel\\\\Příjemce“ jsou povinné",
          "requiresDriverDocumentNumber": "\"Číslo dokladu řidiče\" povinné pole",
          "requiresDriverLanguage": "Nastavit pole „Jazyk řidiče“ jako viditelné a povinné",
          "requiresDriverName": "\"Jméno a příjmení řidiče\" povinné pole",
          "requiresErpIdentifier": "Pole „erpIdentifier“ je povinné",
          "requiresService": "Pole „Servis“ je povinné",
          "requiresStepsWeight": "Pole „Hmotnost“ je povinné",
          "requiresTimeWindow": "Povinný výběr časového okna při vytváření přepravy",
          "requiresTrailer": "Pole „Registrační číslo přívěsu/návěsu“ je povinné",
          "requiresTransportSupervisor": "Povinnost pole \"Kontaktní osoba\"",
          "requiresTruckType": "Povinnost pole \"Typ dopravy\"",
          "requiresUniqueErpIdentifier": "Jedinečnost pole „erpIdentifier“",
          "requiresWarehouse": "Povinný výběr skladu při vytváření přepravy",
          "shouldCopyAdviceOnTransportCopy": "Určuje, zda mají být data avíza zkopírována s možností \"Vytvořit podobné\"",
          "shouldShowArrivalDatesInScheduleTooltip": "Určuje, zda se mají při přidávání časového okna zobrazovat časy vykládky",
          "showAddressFieldsInContractorSteps": "Viditelnost polí adresy v krocích dodavatelů",
          "supplierFieldTranslationKey": "Překladový klíč pro pole \"Dodavatel/Odběratel\" (KLIENT , SPOLEČNOST, KURÝR)",
          "useResourcesFromExternalSource": "Zdrojová data (řidiči, tahače, přívěsy) stažená z externího zdroje",
          "usedForDeliveries": "Možnost vytvářet dodávky",
          "usedForMarcos": "Typ přepravy používaný pro integraci systému marcos",
          "windowColorDependsOnWarehouse": "Určuje, zda barva časového okna závisí jen na stavech spojených s daným oknem",
          "workerFieldTranslationKey": "Překladový klíč pro pole \"Pracovníci\" (PRACOVNÍCI, HOSTÉ, OBSLUHA)"
        },
        "settingsSaveSuccess": "Nastavení typu přepravy jsme úspěšně uložili.",
        "transactionSetting": "Nastavení transakce",
        "visibleInLoadPackageQuantityInTransportTable": "Viditelnost v nakládací tabulce",
        "visibleInUnloadPackageQuantityInTransportTable": "Viditelnost vykládaného množství v tabulce",
        "workflow": "Pracovní postup"
      },
      "updateSuccessMessage": "Nastavení byla aktualizována.",
      "value": "Hodnota"
    },
    "square": {
      "SQUARE": {
        "addQueue": "Přidat frontu",
        "buffer": "Čekací zóna",
        "carsInFrontOfSmelter": "Auta před hutí",
        "classificationTab": "Klasifikace",
        "create": "Vytvořit místo na surovinu",
        "createPlace": "Přidat místo",
        "label": "Místa na suroviny",
        "placeLabel": "Místo",
        "queueLabel": "Fronta",
        "queueStatusTab": "Stav front",
        "queuesStatus": "Stav front (za hutí)",
        "queuesStatusTableHeader": "Souhrn stavu aktuálních front",
        "queuesTab": "Fronty",
        "square": "Místo",
        "unload": "Vykládka"
      },
      "createQueueAfterSave": "Po uložení místa nebude možné konfigurovat frontu.",
      "disableConfirm": "Opravdu chcete tento čtverec zakázat?",
      "updateSuccessMessage": "Nastavení místa {squareName} byla aktualizována."
    },
    "squareSetting": {
      "bufferSecondTableColumnVisibility": "Viditelnost sloupce (tabulka 2) „Čekací zóna“",
      "carRegistrationFirstTableColumnVisibility": "Viditelnost sloupce (tabulka 1) „Registrační číslo vozidla“",
      "hdsFirstTableColumnVisibility": "Viditelnost sloupce (tabulka 1) „Hydraulický jeřáb“",
      "label": "Nastavení míst a front",
      "orderNumberFirstColumnVisibility": "Viditelnost sloupce (tabulka 1) „Číslo objednávky“",
      "queueFirstTableColumnVisibility": "Viditelnost sloupce (tabulka 1) „Fronta“",
      "queueSecondTableColumnVisibility": "Viditelnost sloupce (tabulka 2) „Fronta“",
      "squareFirstTableColumnVisibility": "Viditelnost sloupce (tabulka 1) „Místo“",
      "squareSecondTableColumnVisibility": "Viditelnost sloupce (tabulka 2) „Místo“",
      "translationKey": "Tlačítko překladu",
      "unloadSecondTableColumnVisibility": "Viditelnost sloupce (tabulka 2) „Vykládka“"
    },
    "statuses": {
      "deleted": "Odstraněno",
      "finished": "Dokončeno",
      "initial": "Avízo",
      "movedToArchiveDueDelay": "Zpožděno",
      "waitingForConfirmation": "Čeká na potvrzení"
    },
    "statute": {
      "accept": {
        "disabled.title": "Zkontrolujte prosím prohlášení o přijetí.",
        "header": "Vážený uživateli,",
        "inTouch": "Rozumím, budeme v kontaktu.",
        "informed": "Rozumím, cítím se informován(a).",
        "label": "Rozumím a souhlasím s výše uvedeným.",
        "manual": "Přečetl(a) jsem si bezpečnostní příručku, rozumím jí a přijímám ji.",
        "paragraph_1": "1. Společnost LOGINTEGRA Sp. z o. o. se sídlem v Gdaňsku, Leborska 3b, KRS 0000426238, NIP 584-272-80-64, REGON 221707056 je podle GDPR a v rámci systému, jehož součástí je uživatel, správcem osobních údajů.  Kontaktní informace správce: telefon: +48 58 535 78 06, e-mail: info@logintegra.com. Vaše údaje mohou být sdíleny s jinými uživateli systému a dalšími níže uvedenými subjekty.",
        "paragraph_10": "10. Máte-li jakékoli dotazy, pochybnosti nebo požadavky ohledně vašich údajů, nejsnadnějším řešením je kontaktovat nás na e-mailové adrese: info@logintegra.com",
        "paragraph_2": "2. Osobní údaje uživatelů budou zpracovávány jen pro účely plnění smluv uzavřených mezi správcem a dalšími uživateli, plnění závazků a povinností souvisejících s plněním smluv a k plnění závazků vyplývajících z právních předpisů a dále v rozsahu, ve kterém uživatel se zpracováním údajů souhlasil. ",
        "paragraph_3": "3. Tento systém se používá ke spolupráci mezi společnostmi a k řízení logistického procesu společností uživatelů. Funguje na tom principu, že osobní údaje uživatele mohou být viditelné jiným uživatelům v systému a mohou jim být zasílány či předávány jinou cestou. Osobní údaje uživatelů mohou být předávány také v jiných případech stanovených právními předpisy, zejména: ",
        "paragraph_3_1": "daňovým orgánům – pokud je předávání osobních údajů povinné, jak stanoví daňové předpisy,",
        "paragraph_3_2": "orgánům veřejné moci, mimo jiné policejním a bezpečnostním složkám, soudům, státním zástupcům, orgánům sociálního zabezpečení, pokud je předávání osobních údajů povinné dle právních předpisů,",
        "paragraph_3_3": "důvěryhodným subdodavatelům poskytujícím naším jménem technickou podporu nebo hostingové služby,",
        "paragraph_3_4": "stranám poskytujícím účetní, daňové a právní služby.",
        "paragraph_4": "4. Osobní údaje uživatelů budou zpracovávány pouze po dobu nezbytnou pro účely plnění uvedené v odst. 2 nebo do okamžiku odvolání souhlasu. Některé kategorie údajů mohou být uchovávány déle, pokud takovou povinnost vyžadují právní předpisy, např. údaje ve vystavených fakturách budou správcem uchovávány až do uplynutí zákonné lhůty pro povinnou archivaci faktur pro daňové účely.",
        "paragraph_5": "5. Uživatel poskytuje osobní údaje dobrovolně, avšak vzhledem k povaze systému je nezbytné poskytnout určité kategorie údajů, aby jej uživatel mohl využívat. Pokud uživatel neposkytne veškeré potřebné osobní údaje v registračním formuláři nebo odvolá svůj souhlas s jejich zpracováním, nebude možné systém využívat.",
        "paragraph_6": "6. Každý uživatel, který je fyzickou osobou, má právo:",
        "paragraph_6_1": "na přístup ke svým osobním údajům,",
        "paragraph_6_2": "na opravu svých osobních údajů,",
        "paragraph_6_3": "na výmaz nebo omezení zpracování svých údajů,",
        "paragraph_6_4": "vznést námitku proti zpracování svých údajů a",
        "paragraph_6_5": "na předání svých osobních údajů,",
        "paragraph_6_6": "kdykoli odvolat souhlas se zpracováním osobních údajů, což nemá vliv na právoplatnost zpracování, které bylo provedeno v souladu se souhlasem před jeho odvoláním.",
        "paragraph_6_bottom": "Uživatel má také právo podat stížnost dozorovému úřadu, pokud se rozhodne, že zpracování jeho osobních údajů porušuje předpisy na ochranu osobních údajů.",
        "paragraph_7": "7. Podrobné záležitosti týkající se ochrany osobních údajů jsou upraveny v našich dokumentech",
        "paragraph_7_1": "zásady zpracování osobních údajů,",
        "paragraph_7_2": "bezpečnostní příručka,",
        "paragraph_7_3": "předpisy pro bezpečnost a poskytování služeb.",
        "paragraph_7_bottom": "Tyto dokumenty získáte kliknutím na jejich názvy.",
        "paragraph_8": "8. Náš systém využívá soubory cookie.",
        "paragraph_9": "9. Pamatujte, že v systému jsou zpracovávány osobní údaje. Po zadání jakýchkoli osobních údajů třetích stran, např. řidičů do systému, jste povinni zajistit dodržení povinností správce osobních údajů, jak jsou stanoveny v nařízení GDPR. Povinnosti mimo jiné zahrnují informování subjektu údajů o:",
        "paragraph_9_1": "totožnosti správce údajů a jeho kontaktních údajích,",
        "paragraph_9_2": "účelech zpracování, pro které jsou osobní údaje určeny, a o právním základu pro zpracování,",
        "paragraph_9_3": "případných příjemcích nebo kategoriích příjemců osobních údajů,",
        "paragraph_9_4": "době, po kterou budou osobní údaje uchovávány,",
        "paragraph_9_5": "existenci práva požadovat na správci přístup k údajům a jejich opravu nebo výmaz osobních údajů týkajících se subjektu údajů nebo omezení zpracování nebo práva vznést námitku proti zpracování údajů, jakož i práva na přenositelnost údajů,",
        "paragraph_9_6": "v případě, že je zpracování prováděno na základě souhlasu dané osoby – existence právo souhlas kdykoli odvolat bez dotčení zákonnosti zpracování na základě souhlasu před odvoláním,",
        "paragraph_9_7": "právo na podání stížnosti u dozorového úřadu",
        "paragraph_9_8": "bez ohledu na to, zda jsou osobní údaje poskytovány z důvodu zákonných nebo smluvních požadavků nebo zda jde o požadavek nezbytný pro uzavření smlouvy i toho, zda je subjekt údajů povinen poskytnout osobní údaje a na možné důsledky neposkytnutí takových údajů.",
        "paragraph_9_bottom": "Pamatujte, že je nutné zajistit plnění povinností správce. Pokud se dozvíte o možném porušení pravidel ochrany osobních údajů, ihned nás informujte.",
        "policy": "Přečetl(a) jsem si zásady zpracování osobních údajů, rozumím jim a přijímám je.",
        "statute": "Přečetl(a) jsem si předpisy pro bezpečnost a poskytování služeb, rozumím jim a přijímám je.",
        "subHeader": "od 25. května 2018 vstoupilo v platnost NAŘÍZENÍ EVROPSKÉHO PARLAMENTU A RADY (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) zveřejněné v Úředním věstníku Evropské unie L 119 z roku 2016, s. 1. Pro zachování srozumitelnosti obsahu bude výše uvedené nařízení dále označováno jako GDPR. V souladu s povinnostmi uloženými správcům a zpracovatelům osobních údajů vás tímto informujeme o několika důležitých souvisejících záležitostech. Dovolujeme si Vás požádat, abyste se s níže uvedeným pečlivě seznámili:"
      },
      "appliesManualLabel": "Otevřít pokyny k žádostem",
      "label": "Podmínky",
      "label.ablative": "Podmínky",
      "links": {
        "manual": "https://pl.logintegra.com/static/Manual.pdf",
        "policy": "https://pl.logintegra.com/static/Policy.pdf",
        "statute": "https://pl.logintegra.com/static/Statute-2018-05-24.pdf"
      },
      "policyLabel": "Zásady zpracování údajů",
      "reject": {
        "button": "Zrušit (tento účet bude zablokován)",
        "confirmation": "Opravdu? Váš účet bude zablokován."
      },
      "safetyManualLabel": "Bezpečnostní příručka"
    },
    "step": {
      "add": {
        "title": "Přidat další krok"
      },
      "address": "Adresa",
      "delete": {
        "confirmMessage": "Opravdu chcete odstranit tento krok z přepravy?",
        "confirmMessageWithIndexes": "Opravdu chcete odstranit tento krok z přepravy? Všechny související indexy budou společně s tímto krokem odstraněny.",
        "title": "Odstranit krok z této přepravy"
      },
      "dimensions": {
        "DEFAULT.label": "Rozměry",
        "DIMENSIONS.label": "Rozměry"
      },
      "documentIdentifier": {
        "CUSTOMERS_ORDER_NUMBER.label": "Číslo objednávky zákazníka",
        "DEFAULT.label": "ID dokumentu",
        "DELIVERY_NOTE_NUMBER.label": "ID dokumentu",
        "DELIVERY_NOTE_NUMBER_SHORT.label": "ID",
        "ISSUE_ORDER_NUMBER.label": "Číslo objednávky",
        "REFERENCE_NUMBER.label": "Referenční číslo"
      },
      "errors": {
        "arrivalDate": {
          "isInPast": "Datum příjezdu nemůže být v minulosti."
        },
        "company": {
          "isRequired": "Tento typ přepravy vyžaduje smluvní společnost. Pokud nevidíte žádné společnosti, založte partnerství."
        },
        "dimensions": {
          "length": "Zadaný text je příliš dlouhý. Maximální délka je 255 znaků."
        },
        "maxAdviceDate": {
          "beforeWindowStop": "Daný rozsah nelze nastavit, protože přidané časové okno avíza již bude mimo jeho rozsah. Nejprve přesuňte časové okno.",
          "earlierThanMinAdviceDate": "Maximální možný čas avíza nelze nastavit dříve než minimální čas.",
          "inThePast": "Aby bylo možné avízo vytvořit, konec časového rozsahu avíza musí být v budoucnu.",
          "timeHasPassed": "Povolený čas pro avízo již uplynul. Obraťte se na správce."
        },
        "minAdviceDate": {
          "afterWindowStart": "Daný rozsah nelze nastavit, protože přidané časové okno avíza již bude mimo jeho rozsah. Nejprve přesuňte časové okno."
        },
        "outsideOfInterval": "Přípustný interval je {windowInterval} minut, protože se jedná o interval, o který můžete posouvat časová okna ve zvoleném skladu.",
        "outsideWarehouseWorkHours": "Zvolený rozsah je mimo pracovní dobu skladu: {workStart}-{workEnd}.",
        "purchaseOrderNumber": {
          "length": "Zadaný text je příliš dlouhý. Maximální délka je 255 znaků."
        },
        "warehouse": {
          "sameWarehousesInTransfer": "Přenos nelze vytvořit v jednom skladu."
        }
      },
      "label": "Krok",
      "load": {
        "place": "Místo nakládky",
        "term": "Datum vyzvednutí"
      },
      "maxAdviceDate": "Maximální možná doba avíza",
      "minAdviceDate": "Minimální možná doba avíza",
      "purchaseOrderNumber": {
        "DEFAULT.label": "Číslo SD",
        "SD_NUMBER.label": "Číslo SD"
      },
      "quantityLoadAndUnloadSumCheckMessage": "Opravdu chcete vytvořit avízo pro více než jeden přívěs se zbožím?",
      "quantityLoadSum": "Počet palet na nakládku",
      "quantityLoadSumCheckMessage": "Jste si jisti, že při nakládce avizujete více než jeden přívěs se zbožím?",
      "quantityUnloadSum": "Počet palet na vykládku",
      "quantityUnloadSumCheckMessage": "Jste si jisti, že při vykládce avizujete více než jeden přívěs se zbožím?",
      "segmentLdm": "Nakládací metr",
      "segmentPayload": "Užitečné zatížení",
      "timeRangeForAdvice": "Časový rozsah avíza",
      "timeRangeForAdviceDoesNotApply": "Pouze pro informaci: Nevztahuje se na vás",
      "timeRangeForLoad": "Časový rozsah nakládky",
      "timeRangeForUnload": "Časový rozsah vykládky",
      "timeWindow": "Časové okno",
      "unload": {
        "place": "Místo vykládky",
        "term": "Datum dodání"
      },
      "unloadStepArrivalDates": "Data a časy vykládky"
    },
    "table": {
      "loadMore": "Načíst více dat",
      "loading": "Nakládáme přepravní zakázky",
      "numberOfTransports": "{n} přeprav",
      "scanBarcode": "Skenování čárových kódů",
      "scanBarcodeError": "Při skenování čárového kódu došlo k chybě. Zkontrolujte skenovací zařízení a zkuste to znovu, nebo se obraťte na správce.",
      "transports": {
        "scrollToType": "Přejděte do oddílu přepravy tohoto typu.",
        "selectType": "Vybrat typ",
        "showAll": "Zobrazit veškerou přepravu",
        "showAllActive": "Zobrazit všechny aktivní objednávky",
        "showLimited": "Zobrazit omezené objednávky",
        "showMine": "Zobrazit pouze moje přepravy",
        "showWithinGroup": "Zobrazit přepravu v mé skupině"
      }
    },
    "tabs": {
      "announcements": "Obecná oznámení",
      "archive": "Archiv",
      "carriers": "Dopravci",
      "companies": "Společnosti",
      "invitations": "Pozvánky",
      "planning": "Plánování",
      "priceLists": "Ceníky",
      "report": "Statistika",
      "schedule": "Harmonogram",
      "settings": "Nastavení",
      "superAdmin": "ADMIN",
      "transports": "Přepravy",
      "users": "Uživatelé"
    },
    "task": {
      "alias": "Alias",
      "altCheckpointColor": "Barva alternativního kontrolního bodu",
      "altCheckpointName": "Název alternativního kontrolního bodu CZ",
      "altCheckpointNameEn": "Alternative checkpoint name EN",
      "associations": {
        "CONTRACTOR": "Dodavatel",
        "WAREHOUSE": "Sklad",
        "WAREHOUSE_AND_CONTRACTOR": "Sklad a dodavatel",
        "label": "Úloha přidružená k"
      },
      "beforeConfirmation": {
        "willChangeColorTo": "a příslušný řádek tabulky a časové okno přejde do stavu",
        "willChangeStateTo": "Až do potvrzení této fáze bude přeprava ve stavu"
      },
      "blockade": {
        "customFieldsRestriction": "Kontrolní bod lze potvrdit jen po vyplnění a uložení polí v přepravním formuláři:",
        "documentRestriction": "Kontrolní bod lze potvrdit jen po přidání dokumentů k přepravě"
      },
      "canBeConfirmAfterAddingDocumentsForTransport": "Lze povrdit po přidání dokumentů do přepravy",
      "canBeConfirmedByGuard": "Může potvrdit ostraha",
      "canBeRejected": "Kontrolní bod lze zamítnout",
      "canBeRevoked": "Kontrolní bod lze odvolat",
      "canConfirmPreviousConfirm": "Opravdu chcete potvrdit odjezd pro tuto objednávku? Stavy vykládky ještě nebyly schváleny.",
      "checkpointName": "Název kontrolního bodu CZ",
      "checkpointNameEn": "Checkpoint name EN",
      "checkpointSortId": "Pořadí stupně v třídění",
      "checkpointSortIdSettingLabel": "Pořadí etapy při řazení stavů v přepravní tabulce",
      "color": "Barva kontrolního bodu",
      "confirmCheckpointAfterCarrierSelect": "Automatické potvrzení po přiřazení dopravce",
      "customModalComponentName": "Název komponenty použitý jako pop-up modal, pokud má úloha povolené nastavení openCustomModal (viz výše)",
      "defaultStagePrefix": "Potvrzeno",
      "disableConfirm": "Opravdu chcete zakázat tuto úlohu?",
      "editLabel": "Upravit úlohu: {ID} – {transportType}",
      "errors": {},
      "expectedDateMode": {
        "reference": "nejpozději"
      },
      "expectedDateReference": "Reference",
      "group": {
        "0": "Příprava",
        "1": "Nakládky a vykládky",
        "2": "Končí"
      },
      "label": "Fáze",
      "markIfWasDelayed": {
        "disabled": {
          "label": "Označit zpoždění",
          "title": "Kliknutím na tuto ikonu nastavíte trvalý červený rámeček u všech zpožděných oken."
        },
        "enabled": {
          "label": "Označit zpoždění",
          "title": "Kliknutím na tuto ikonu nastavíte trvalý červený rámeček u všech zpožděných oken. Nastavení změníte kliknutím na ikonu."
        }
      },
      "moveTransportToArchiveIfDelayed": "Automatický přenos přepravy do archivu v případě zpoždění",
      "newSubTasksCheckpoints": "Budou vygenerovány nové kontrolní body",
      "openCustomModal": "Chcete-li potvrdit tuto úlohu, budete muset potvrdit vyskakovací okno modal",
      "recreateMasterTaskSubTasks": "Po potvrzení znovu vytvořte všechny dílčí úkoly hlavní úlohy této úlohy (hlavní úloha :: {n}).",
      "reference": {
        "after": {
          "earliestStepStart": "začátek první nakládky/vykládky",
          "latestStepStop": "Konec první nakládky/vykládky",
          "minAdviceDate": "počáteční datum časového rozmezí pro avízo",
          "stepStart": "začátek",
          "stepStop": "konec",
          "transportCreated": "vytvoření přepravy"
        },
        "before": {
          "earliestStepStart": "začátek první nakládky/vykládky",
          "latestStepStop": "konec první nakládky/vykládky",
          "minAdviceDate": "počáteční datum časového rozmezí pro avízo",
          "stepStart": "začátek",
          "stepStop": "konec",
          "transportCreated": "vytvoření přepravy"
        }
      },
      "saveSubTasks": "Uložení všech dílčích úkolů",
      "settingsLabel": "Nastavení úlohy",
      "shouldBeApprovedWithAdvice": "Automatické potvrzení stavu po přidání avíza",
      "shouldBeConfirmedBy": "musí potvrdit",
      "shouldBeConfirmedByRelatedCheckpoint": "[DELIVERY] Určuje kontrolní bod, který bude schválen při schválení odpovídajícího kontrolního bodu s příznakem `shouldConfirmRelatedCheckpint`",
      "shouldBeSavedAsApproved": "Automatické potvrzení stavu po vytvoření objednávky",
      "shouldConfirmRelatedCheckpoint": "[DELIVERY] Určuje kontrolní bod, který po schválení také schválí odpovídající kontrolní body s příznakem `shouldBeConfirmedByRelatedCheckpoint`",
      "shouldSendLpToMSHMessages": "Definuje kontrolní bod, po kterém pošleme zprávu do mikroslužby MSH",
      "shouldSendLpToSapMessages": "[DELIVERY] Definuje kontrolní bod, po kterém pošleme zprávu do mikroslužby MSP",
      "sign": "Před/po",
      "stageName": "Název stavu před potvrzením fáze – CZ",
      "stageNameEn": "The name of the status before confirming stage - EN",
      "stepType": {
        "load": "nakládka",
        "placeholder": "Typ akce",
        "unload": "vykládka"
      },
      "taskIsNotSavedHint": "Tato úloha ještě nebyla uložena. Přidanou úlohu můžete upravovat po kliknutí na tlačítko Uložit.",
      "timeWindowCssClassName": "Další css třída pro časové okno",
      "tracked": {
        "disabled": {
          "label": "",
          "title": "Očekávaný čas potvrzení fáze bude pouze obrazová informace – v případě neschválení nezměníme barvu řádku nebo okna a neodešleme oznámení o zpoždění. Kliknutím na ikonu změňte nastavení."
        },
        "enabled": {
          "label": "Dohled nad přesností",
          "title": "Pokud nebude včas schválena, přejde přeprava do stavu „pozdě“ – řádek v tabulce a časové okno se změní na červené a budou odeslány e-maily s informací o zpoždění. Kliknutím na ikonu změňte nastavení."
        }
      },
      "unit": "Jednotka",
      "userHasToAddCheckpointConfirmationDate": "Je vyžadováno datum potvrzení stavu",
      "weighingIncluded": "Úloha vážení"
    },
    "tasks": {
      "empty": "Nejsou zde žádné úlohy."
    },
    "terms-of-service": {
      "errors": {
        "unchecked": "Chcete-li odeslat požadavek na ověření, musíte přijmout PODMÍNKY SLUŽBY"
      },
      "label": {
        "acquiant": "Přečetl(a) jsem si: ",
        "confirmed": "byly přijaty {confirmationDate} uživatelem {user}.",
        "documents": "Podmínky služby:"
      },
      "name": "PODMÍNKY SLUŽBY",
      "shortName": "PODM. SL."
    },
    "time-window": {
      "details": {
        "carRegistrationNumber": "Vozidlo",
        "deliveryIncoterms": "Delivery terms/incoterms",
        "deliveryNumbers": "Dodací čísla",
        "deliveryUnloadAddress": "Název zákazníka",
        "deliveryUnloadDate": "Datum dodání",
        "erpIdentifier": "Obj. č.",
        "trailerRegistrationNumber": "Přívěs"
      },
      "errors": {
        "start": {
          "isInPast": "Dané datum nemůže být v minulosti.",
          "outsideOfAdviceRange": "Toto datum nelze zvolit, protože časové okno bude mimo časový rozsah avíza."
        },
        "stop": {
          "earlierThanStart": "Konec časového okna musí být později než začátek.",
          "outsideOfAdviceRange": "Toto datum nelze zvolit, protože časové okno bude mimo časový rozsah avíza."
        }
      }
    },
    "timeWindow": {
      "cancelCreation": "Zrušit (fixní pozice časového okna)",
      "errors": {
        "start": {
          "notConsistentWithWarehouseOffset": "Počáteční čas není v souladu s nastaveními skladu. Možné okno začíná pro zvolenou hodinu: {possibleWindowStarts}",
          "outsideWarehouseWorkHours": "Zvolené datum je mimo pracovní dobu skladu: {workStart}-{workEnd}.",
          "startsEarlierThenPreviousWindowStops": "Začátek časového okna nemůže být dřívější než konec předchozího časového okna v transportu."
        },
        "stop": {
          "notConsistentWithWarehouseOffset": "Koncový čas není v souladu s nastaveními skladu. Možné okno končí pro zvolenou hodinu: {possibleWindowStops}",
          "outsideWarehouseWorkHours": "Zvolené datum je mimo pracovní dobu skladu: {workStart}-{workEnd}."
        },
        "time": {
          "outsideOfAdviceRange": "Toto časové okno zde nelze přidat, protože bude mimo časový rozsah avíza: {minAdviceDate} – {maxAdviceDate}."
        },
        "warehouseHasNoSpaceThisDay": "V den <datum> nejsou k dispozici žádné časové intervaly. Vyberte si prosím jiné datum.",
        "wrongWarehouse": "Nelze vložit časové okno do skladu přiřazeného přepravě"
      },
      "ramp": "Rampa",
      "start": "Počátek časového okna",
      "stop": "Konec časového okna"
    },
    "transaction": {
      "accept": "Přijmout",
      "autoPass": "Úspěšné aut.",
      "autoPassConfirmation": "Opravdu chcete pro tuto přepravu povolit automatické přeposílání následujícím přepravcům?",
      "autoRouteOfferPassError": "Chyba při automatickém předání objednávky",
      "carrier": "Dopravce",
      "carrierMustAcceptTransport": "Předání vyžaduje přijetí dopravce",
      "carrierMustAcceptTransportHint": {
        "checked": "Po předání objednávky může vybraný dopravce objednávku přijmout nebo zamítnout. Po potvrzení přijetí bude dopravce přiřazen k této objednávce.",
        "unchecked": "Vybraný dopravce bude k objednávce přiřazen ihned."
      },
      "chooseCarrier": "Vybrat dopravce",
      "currency": "Měna",
      "dateCreated": "Vytvořeno v",
      "errors": {
        "expectedConfirmationDate": {
          "isInPast": "Dané datum nemůže být v minulosti."
        },
        "invoicePrice": {
          "wrongNumberFormat": "Cena musí být číslo"
        }
      },
      "expectedConfirmationDate": "Termín pro potvrzení",
      "invoiceCurrency": "Měna faktury",
      "invoiceNumber": "Číslo faktury",
      "invoicePrice": "Fakturovaná cena",
      "isRejected": "Zamítnuto",
      "label": "Transakce",
      "offer": "Nabídka",
      "pass": "Úspěšné",
      "price": "Cena",
      "rate": "Sazba",
      "reject": "Zamítnout",
      "rejectedByAllCarriers": "Žádný dopravce objednávku nepřijal",
      "rejectedByCarrier": "Odmítnuto",
      "transport": "Přeprava",
      "updateDisabledInfo": {
        "route": "Sazba je z trasy {routeId}, takže ji nelze upravit."
      },
      "waitingForAcceptanceInfo": "Čekání na přijetí",
      "withInvoice": "s fakturou",
      "withoutInvoice": "bez faktury"
    },
    "transactionSetting": {
      "description": {
        "canChooseCarrierFromTransportTable": "Určuje, zda bude v přepravní tabulce v řádku objednávky k dispozici klíč \"Transfer\" (s odpovídajícím nastavením možností AuthorityRestriction).",
        "carrierAutoAcceptsTransport": "Určuje, zda má být objednávka automaticky přiřazena dopravci, pokud nedojde k potvrzení nebo odmítnutí odeslané objednávky po uplynutí lhůty pro potvrzení.",
        "defaultCurrency": "Určuje výchozí měnu vybranou ve formuláři Přeprava na dopravce.",
        "defaultInvoiceCurrency": "Určuje výchozí měnu vybranou na formuláři faktury.",
        "priceLabel": "Definuje výchozí štítek nákladu",
        "requireCarrierConfirmation": "Určuje, zda dopravce vyžaduje potvrzení o přijetí objednávky při jejím předání dopravci. Pokud je příznak \"Předání vyžaduje potvrzení dopravce\" TRUE, určuje výchozí hodnotu pole \"Předání vyžaduje potvrzení dopravce\".",
        "requiresReasonField": "Povinná povaha pole \"Důvod\"",
        "showAndRequireReasonFieldIfPriceIsZero": "Viditelnost a povinnost pole „Důvod“, pokud je v poli „Náklad“ zadána hodnota 0.",
        "showCarrierConfirmationCheckbox": "Určuje, zda má být na formuláři předání viditelné zaškrtávací políčko: \"Předání vyžaduje potvrzení od dopravce.\"",
        "showExpectedConfirmationDate": "Určuje, zda se ve formuláři převodu zobrazí pole \"Lhůta pro potvrzení\".",
        "showPriceField": "Určuje, zda se ve formuláři převodu zobrazí pole \"Náklad\".",
        "showPriceFieldForDeliveries": "Viditelnost pole \"Doprava\" pro každou dodávku",
        "showReasonField": "Viditelnost pole \"Důvod\""
      },
      "label": {
        "canChooseCarrierFromTransportTable": "Zobrazení klíče \"Transfer\" v přepravní tabulce v řádku objednávky",
        "carrierAutoAcceptsTransport": "Automatické přiřazení dopravce v případě nepotvrzení/odmítnutí",
        "defaultCurrency": "Výchozí měna transakce",
        "defaultInvoiceCurrency": "Výchozí měna faktury",
        "priceLabel": "Výchozí štítek nákladu",
        "requireCarrierConfirmation": "Vyžadovat potvrzení o přijetí od dopravce",
        "requiresReasonField": "Povinná povaha pole \"Důvod\"",
        "showAndRequireReasonFieldIfPriceIsZero": "Viditelnost a povinnost pole „Důvod“, pokud je v poli „Náklad“ zadána hodnota 0.",
        "showCarrierConfirmationCheckbox": "Viditelnost pole \"Převod vyžaduje potvrzení dopravcem\"",
        "showExpectedConfirmationDate": "Viditelnost pole \"Termín pro potvrzení\"",
        "showPriceField": "Viditelnost pole \"Náklad\"",
        "showPriceFieldForDeliveries": "Viditelnost pole \"Doprava\" pro každou dodávku",
        "showReasonField": "Viditelnost pole \"Důvod\""
      },
      "priceLabel": {
        "FREIGHT": "Náklad",
        "FREIGHT_FOR_CARRIER": "Doprava pro dopravce",
        "PRICE": "Cena",
        "RATE": "Hodnotit"
      },
      "saveSuccess": "Správně jsme uložili nastavení přenosu"
    },
    "transport": {
      "CARTONS": "Kartony",
      "CHEP_PALLETS": "Palety CHEP",
      "DISPOSABLE_PALLETS": "Jednorázové palety",
      "Delivery": "Dodání",
      "EURO_PALLETS": "Palety EURO",
      "LOADING_PLACES": "Počet nakládacích míst",
      "PALLETS": "Palety",
      "PALLET_PLACES": "Paletová místa",
      "THE_NUMBER_OF_ITEMS": "Umění",
      "addNewCompany": {
        "assignmentCompany": "Chcete tuto společnost přiřadit jako dodavatele přepravy?",
        "cooperatingCompanies": "Již spolupracujete se společností podobného názvu",
        "emailInfo": "Pozvánka ke spolupráci bude zaslána na zadanou e-mailovou adresu.",
        "emailRequired": "Chcete-li odeslat pozvánku ke spolupráci, musíte zadat e-mailovou adresu",
        "existingCompany": "Společnost se zvoleným daňovým identifikačním číslem v naší databázi již evidujeme",
        "existingCompanyAndPartnership": "Společnost s daňovým identifikačním číslem {taxIdNumber} je v systému již registrována a patří vašim kolegům",
        "label": "Přidat novou společnost",
        "sendInvitation": "Odeslat pozvánku ke spolupráci",
        "wrongEmail": "Zadaná e-mailová adresa je nesprávná."
      },
      "additionalDescription": "Další poznámky",
      "advice": "Avízo",
      "amountOfCover": "Množství obalu",
      "archived": "Archivováno",
      "arrivalDate": "Datum vyzvednutí",
      "biggerVehicleRequired": "Nutné použití většího vozidla - ",
      "canNotEditAfterProcessStarts": "Upravovat lze jen data v avízu, které jste vytvořili, a jen před příjezdem řidiče.",
      "canNotEditBecauseOfOtherContractors": "Nelze upravovat data avíza s několika dodavateli.",
      "carrier": {
        "label": "Dopravce"
      },
      "carrierCompany": "Společnost dopravce",
      "carrierCompanySelectedBy": "Dopravce byl vybrán společností {companyName}",
      "combinedWeight": "Celková hmotnost přeprav: ",
      "company": {
        "autoCompletePlaceholder": "Začněte psát a vyhledejte společnost"
      },
      "completed": "Dokončeno",
      "createTitle": "Plánování dodání",
      "createdBy": "Vytvořil",
      "createdFromRoute": "na základě trasy",
      "createdMessage": "Vytvořeno {date}, autor {person} ({company}).",
      "dateOfAdvice": "Datum avíza",
      "dateOfArchiving": "Datum archivace",
      "dateOfDeletion": "Datum odstranění",
      "delete": "Odstranit přepravu",
      "deleteTransport": "Opravdu chcete odstranit tuto přepravu?",
      "departureDate": "Datum dodání",
      "description": "Poznámky",
      "details": "Podrobnosti dodání",
      "documentType": "Typ dokumentu",
      "documents": {
        "dragAndDrop": "Přetáhněte dokumenty nebo klikněte",
        "empty": "K této dodávce nebyly dosud přidány žádné dokumenty.",
        "header": "Dokumenty k dodávce {identifier}",
        "label": "Dokumenty"
      },
      "earliestTimeWindowStart": "Začátek nejdřívějšího časového okna",
      "edit": "Upravit přepravu",
      "erpIdentifier": "Číslo objednávky",
      "erpIdentifier.placeholder": "např. 110011",
      "errors": {
        "carrierCompany": {
          "info": "Tento typ přepravy vyžaduje přepravní společnost. Pokud žádné společnosti'nevidíte, založte partnerství."
        },
        "contractors": {
          "notUnique": "Uveďte jedinečný seznam dodavatelů"
        },
        "dateOfAdvice": {
          "isInPast": "Dodání nelze plánovat do minulosti"
        },
        "erpIdentifier": {
          "minLength": "Identifikátor ERP musí obsahovat nejméně 3 znaky"
        },
        "firstContractor": {
          "info": "Tento typ přepravy vyžaduje smluvní společnost. Pokud žádné společnosti'nevidíte, založte partnerství."
        }
      },
      "firstSavedStart": "První zaznamenané datum začátku časového okna",
      "generateLOPReport": "Stáhněte si zprávu se seznamem bodů",
      "group": "Skupina dodávky",
      "history": "Historie objednávek",
      "incompleteLoading": "Neúplné naložení",
      "indexes": {
        "overwritePreviousIndexes": "Přepsat předchozí indexy",
        "pasteFromExcel": "Vložit z aplikace Excel"
      },
      "indicesTransportNumbers": {
        "label": "Typ objednávky"
      },
      "initialStart": "Počáteční datum začátku časového okna",
      "isCombinedTransport": "Sdílená doprava s: ",
      "isRequiredFor": "Je vyžadován pro: ",
      "isWaitingFor": "Čeká na: ",
      "label": "Přeprava",
      "latestTimeWindowStop": "Konec nejpozdějšího časového okna",
      "load": "Nakládka",
      "logs": {
        "carrierCompany": "Společnost dopravce",
        "dateOfArchiving": "Doprava byla archivována",
        "dateOfDeletion": "Doprava byla odstraněna",
        "initialStartInfo": "Časové okno bylo posunuto z {initialDate} hodin na {startDate} hodin",
        "label": "Historie změn",
        "name": "Soubor",
        "ramp": "Rampa",
        "start": "Počátek časového okna",
        "stop": "Konec časového okna"
      },
      "maxSegmentLdm": "Maximální počet měřičů zatížení:",
      "maxSegmentWeight": "Maximální užitečné zatížení:",
      "multiArchive": {
        "alert": "Nemáte oprávnění k archivaci přenosů s následujícími čísly: ",
        "confirm": "Opravdu chcete archivovat následující transporty: ",
        "error": "Neočekávaná chyba při pokusu o hromadnou archivaci přenosů. ",
        "label": "Skupinová archivace"
      },
      "needTwoVehicles": "Je nutné rozdělit objednávku na dva vozy",
      "noFilesForOldBrowsers": "V tomto zobrazení nelze přidávat soubory pomocí prohlížeče. V případě potřeby přidejte soubor v tabulce.",
      "optimalVehicleSelected": "Bylo vybráno optimální vozidlo",
      "otherRequirements": "Další požadavky",
      "packs": {
        "boxes": "Krabice",
        "label": "Obal",
        "pallets": "Palety",
        "pallets.abbr": "p."
      },
      "periodicTransportMessage": "Objednávka spojená s pravidelným avízem č. {periodicTransportInternalIndex}.",
      "print": "Zobrazení tisku",
      "quantities": {
        "CARTONS": "Počet krabic",
        "CHEP_PALLETS": "Počet palet CHEP",
        "CUBIC_METERS": "Počet metrů krychlových",
        "DISPOSABLE_PALLETS": "Počet jednorázových palet",
        "EURO_PALLETS": "Počet EURO palet",
        "LOADING_PLACES": "Počet nakládacích míst",
        "PALLETS": "Počet palet",
        "PALLETS_WITH_RETURNED_GOODS": "Počet palet s vráceným zbožím",
        "PALLET_PLACES": "Počet paletových míst",
        "RETURNABLE_PALLETS": "Počet prázdných vratných palet",
        "SPATIAL_METERS": "Počet prostorových metrů",
        "THE_NUMBER_OF_ITEMS": "Množství",
        "shortName": {
          "CARTONS": "Cartons",
          "CHEP_PALLETS": "CHEP",
          "DISPOSABLE_PALLETS": "Jednorázové",
          "EURO_PALLETS": "EURO",
          "LOADING_PLACES": "Nákladní prostory",
          "PALLETS": "Pallets",
          "PALLETS_WITH_RETURNED_GOODS": "Vrací",
          "PALLET_PLACES": "Paletové prostory",
          "RETURNABLE_PALLETS": "Vratné palety",
          "THE_NUMBER_OF_ITEMS": "Umění"
        }
      },
      "quantity": "Počet palet",
      "quantity.placeholder": "např. 33",
      "relations": {
        "bind": "Vytvořit relaci",
        "combineClearingAlert": "Opravdu chcete kombinovat vybrané přepravy? Potvrzením se vymažou časová okna a avíza pro vybrané přepravy.",
        "combinedTransports": "Kombinované přepravy: ",
        "combining": "\"Kombinování přeprav\" (TRANSPORT_COMBINING)",
        "defaultLabel": "Vybrané přenosy můžete kombinovat s typem relace: ",
        "deletedSuccessfully": "Relace Transoprt byla úspěšně odstraněna",
        "hasToWaitFor": "\"Doprava čeká\" (HAS_TO_WAIT_FOR)",
        "mainTransport": "Hlavní doprava: ",
        "maxWeight": "Maximální hmotnost je: ",
        "noTypeForSelection": "Omlouváme se, ale vybrané transporty nepovolují žádné relace",
        "overweight": "Nelze kombinovat přepravy z důvodu překročení maximální hmotnosti",
        "relatedTransports": "Související přepravy: ",
        "removeRelation": "Smazat relaci",
        "title": "Přepravní vztahy"
      },
      "restoredSuccessfully": "Objednávka byla úspěšně obnovena",
      "scheduledDateOfAdvice": "Plánované datum avíza",
      "selectCountry": "Země",
      "selectSupplier": "Vybrat dodavatele \\\\ příjemce",
      "selectTransportType": "Vyberte typ dodání",
      "selectUnloadStartDate": "Min. datum vykládky",
      "selectUnloadStartDateTitle": "Vyberte minimální datum vykládky.",
      "selectUnloadStopDate": "Max. datum vykládky",
      "selectUnloadStopDateTitle": "Vyberte maximální datum vykládky.",
      "selectWarehouse": "Vyberte sklad",
      "selectedCombinedTransportsWindows": "Okna přiřazená transportům ",
      "selectedWindows": "Okna přiřazená této přepravě {transportIdentifier}",
      "serviceCompany": "Servis",
      "smallerVehiclePossible": "Možné použití menšího vozidla - ",
      "task": "Fáze",
      "title": "Přeprava {ident}",
      "transportSupervisor": {
        "autoCompletePlaceholder": "Začněte psát a vyhledejte kontaktní osobu",
        "label": "Kontaktní osoba"
      },
      "truckType": "Typ dopravy",
      "type": {
        "ALL": "Vše",
        "COLLECTION": "Vyzvednutí",
        "DELIVERY": "Dodání",
        "SERVICE": "Kurýr, služba, atd.",
        "SIMPLIFIED_DELIVERY": "Zjednodušené dodání",
        "TRANSFER_BETWEEN_WAREHOUSES": "Přeprava mezi sklady",
        "TRANSPORT": "Přeprava"
      },
      "unload": "Vykládka",
      "volume": "Objem",
      "warehouse": "Sklad",
      "weight": "Hmotnost",
      "weight.placeholder": "např. 23",
      "weightSection": {
        "downloadWeightReceipt": "Stáhnout doklad o hmotnosti",
        "entryWeight": "Vážení 1",
        "errors": {
          "cannotGetWeight": "Spojení s hmotností se nezdařilo. Zkuste to znovu později. Pokud problém přetrvává, obraťte se na správce systému.",
          "trySaveCheckpointWithoutWeight": "Tato fáze vyžaduje zvážení. Spojení s hmotností se nezdařilo. Chcete potvrdit kontrolní bod s hmotností 0?"
        },
        "exitWeight": "Vážení 2",
        "label": "Hmotnost",
        "netWeight": "Čistá hmotnost",
        "notSynchronized": "Hmotnost není synchronizována se serverem",
        "unit": "kg",
        "weighAgain": "Zvážit znovu"
      },
      "wzDocument": "Číslo dodacího listu",
      "wzNumber": "Číslo dodacího listu"
    },
    "transportPanel": {
      "ADVICE": "Avízo",
      "DISCREPANCY_REPORT": "Hlášení o neshodě",
      "DOCUMENTS": "Dokumenty",
      "LOGS": "Historie změn",
      "WEIGHT": "Hmotnost",
      "selectPlaceholder": "Vybrat panel"
    },
    "transportSupervisorTranslationKeys": {
      "CONTACT_PERSON": "Kontaktní osoba",
      "HOST_PERSON": "Přijímač",
      "INVITEE": "Pozvánka na",
      "ORDERER": "Objednavatel",
      "PERSON_TO_NOTIFY": "Osoba, která má být informována"
    },
    "transportsToExcel": {
      "XLSGenerateMessage": "Po vygenerování zprávy o objednávce bude tato zaslána na e-mailovou adresu osoby, která zprávu generuje",
      "activeArchiveType": "Aktivní/archivovat objednávky",
      "advancedExportType": "Advanced",
      "advancedExportTypeDescription": "Pokročilá verze má dva listy. První list obsahuje stejné údaje jako ve verzi Basic kromě informací o stavu přepravy. Druhý list obsahuje všechny informace týkající se potvrzení stavu.",
      "alsoExportActiveOrders": "Exportovat také aktivní objednávky",
      "basicExportType": "Basic",
      "basicExportTypeDescription": "Základní verze má všechna data na jednom listu.",
      "billingExportType": "Přehled fakturace",
      "billingExportTypeDescription": "Tato verze obsahuje fakturační údaje.",
      "errors": {
        "XLSGenerateEmailErrorMessage": "Hlášení objednávek se zasílá na e-mailovou adresu osoby, která generuje hlášení, vyplňte prosím e-mail v záložce Můj účet",
        "XLSGenerateErrorMessage": "Zkuste to znovu za několik minut nebo nás kontaktujte na adrese info@logintegra.com"
      },
      "export": "Export",
      "exportType": "Verze souboru",
      "noTypeChosen": "Vyberte alespoň jeden typ přepravy",
      "ownColumnExportType": "Sloupce z tabulky",
      "ownColumnExportTypeDescription": "Sloupcová verze tabulky obsahuje pouze informace viditelné v přepravní tabulce",
      "title": "Parametry souboru Excel",
      "transportTypes": "Typy objednávek"
    },
    "truck": {
      "addTruck": "Přidat kamion",
      "description": "Popis",
      "disableConfirm": "Opravdu chcete odstranit tento kamion?",
      "errors": {
        "registrationNumber": {
          "regex": "Registrační číslo musí mít délku mezi 4 a 50 znaky a může obsahovat jen písmena, čísla a znaky . , ; / \\\\ & _"
        },
        "trailer": {
          "regex": "Registrační číslo musí mít délku mezi 4 a 50 znaky a může obsahovat jen písmena, čísla a znaky . , ; / \\\\ & _"
        }
      },
      "hds": "Hydraulický jeřáb",
      "label": "Kamiony",
      "registrationNumber": "Registrační číslo",
      "timeWindow": "Časové okno",
      "trailer": "Přívěs/návěs",
      "truckType": "Typ"
    },
    "truckType": {
      "errors": {
        "truckTypeName": {
          "length": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        },
        "truckTypeNameEn": {
          "length": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        }
      },
      "form": {
        "height": "Vnitřní výška [m]",
        "idx": "Idx",
        "length": "Vnitřní délka [m]",
        "maxLoad": "Maximální nosnost [kg]",
        "name": "Typ vozidla",
        "nameEn": "Typ vozidla - EN",
        "title": {
          "add": "Přidat nový typ vozidla",
          "edit": "Upravit vozidlo"
        },
        "transportTypes": "Typy transportu dla których dostępny będzie typ pojazdu",
        "width": "Vnitřní šířka [m]"
      },
      "settingButtonLabel": "Typy vozidel",
      "table": {
        "height": "Vnitřní výška [m]",
        "length": "Vnitřní délka [m]",
        "maxLoad": "Maximální nosnost [kg]",
        "name": "Typ vozidla",
        "width": "Vnitřní šířka [m]"
      }
    },
    "unit": {
      "KG": "kg",
      "LITERS": "litry",
      "PIECES": "kusy",
      "label": "Jednotka",
      "placeholder": "Vyberte jednotku"
    },
    "user": {
      "accountCreatedBy": "Účet vytvořil",
      "accountUpdated": "Změny účtu byly uloženy.",
      "activeNotifications": "Aktivní oznámení",
      "availableQueues": "Dostupné fronty",
      "availableRamps": "Dostupné rampy",
      "availableWarehouses": "Dostupné sklady",
      "contractorGroupName": "Skupina",
      "createUser": "Vytvořit uživatele",
      "deleteConfirm": "Opravdu chcete odstranit tohoto uživatele?",
      "deletedMessage": "Odstraněno {date} ze společnosti {company} uživatelem {person}.",
      "deletedUser": "Uživatel byl odstraněn",
      "editUser": "Upravit uživatele",
      "email": "E-mail",
      "errors": {
        "mobilePhoneNumberLengthError": "Telefonní číslo by mělo mít 9 číslic.",
        "mobilePhoneNumberRegexError": "Telefonní číslo se může skládat pouze z číslic."
      },
      "groupName": "Divize",
      "invitedBy": "Pozván {date} uživatelem {person}.",
      "list": "Seznam uživatelů",
      "mobilePhoneNumber": "Telefon",
      "myAccount": "Můj účet",
      "name": "Jméno a příjmení",
      "noActiveNotifications": "Žádná aktivní oznámení",
      "noAvailableRamps": "Žádné dostupné rampy",
      "registrationDate": "Datum registrace",
      "roleDescription": "Popis role",
      "selectRole": "Vyberte roli",
      "userGroup": {
        "allTransports": "Všechny přepravy",
        "myGroupsTransports": "Přepravy mojí skupiny",
        "myTransports": "Moje přepravy"
      },
      "username": "Přihlášení"
    },
    "users": {
      "label": "Uživatelé"
    },
    "warehouse": {
      "addRamp": "Přidat rampu",
      "adviceHasBeenSavedInfo": "Avízo bylo uloženo: ",
      "autoCompletePlaceholder": "Při hledání skladu, ulice apod. začněte psát",
      "backToDeliveries": "Zpět na tabulku dodání",
      "configureAlgorithmAfterSave": "Algoritmus pro výpočet délky časového okna lze nastavit po uložení skladu.",
      "configureAssortmentGroupsAfterSave": "Skupiny sortimentu lze nastavit po uložení skladu.",
      "confirmDeleteDedicateWindow": "Opravdu chcete odebrat vyhrazené okno pro společnost {company}?",
      "create": "Vytvořit sklad",
      "createRampsAfterSave": "Skladovací zóny a rampy bude možné konfigurovat po uložení skladu.",
      "createTransportInfo": "Vyberte volný prostor v harmonogramu a vytvořte typ přepravy {transportTypeName}.",
      "disableConfirm": "Opravdu chcete tento sklad zakázat?",
      "disabledMessage": "Sklad „{warehouseName}“ byl úspěšně odstraněn.",
      "enableConfirm": "Opravdu chcete tento sklad povolit?",
      "errors": {
        "idx": {
          "invalidIdxNumber": "Číslo IDX skladu musí být mezi 0 a {numberOfWarehouses}."
        },
        "snapToClosestWindowInterval": {
          "info": "Doplňte maximální vzdálenost mezi okraji, aby se časové okno automaticky navázalo. Možné hodnoty jsou 15, 30 nebo 60."
        },
        "timeWindowOffsetInterval": {
          "info": "Počet minut, kterým lze doplňovat časová okna"
        },
        "windowConstantTime": {
          "info": "K době vykládky v oknech byla přidána konstantní doplňovací hodnota v minutách."
        },
        "workEndHour": {
          "endBeforeStart": "Zavírací hodina skladu musí být po otvírací hodině.",
          "invalidNumber": "Zadejte číslo od 1 do 24."
        },
        "workStartHour": {
          "invalidNumber": "Zadejte číslo od 0 do 23."
        }
      },
      "hdOff": "Vypnout HD",
      "hint": "Chcete-li vybrat čas avíza, klikněte na požadovanou volnou (světle šedou) hodinu.",
      "idx": "Sériové číslo skladu.",
      "indexesDetailsTitle": "Kliknutím zobrazíte indexy",
      "integrationCode": "Kód skladu pro integraci MSP",
      "label": "Sklad",
      "numberOfRamps": "Počet ramp",
      "reserveFreePlace": "Vyhraďte si volný prostor v harmonogramu na {initialStart}.",
      "selectCompany": "Po kliknutí na volné místo vytvořte pro společnost vyhrazené okno.",
      "selectCountry": "Vyberte zemi",
      "selectDepartment": "Nebo vyberte oddělení",
      "selectNewPlaceForWindow": "Vyberte nové místo pro vybrané okno.",
      "selectWindowInfo": "Vyberte volný prostor v harmonogramu a přidejte přepravní avízo {identifier}.",
      "selectWindowWarning": "Avízo můžete naplánovat nejpozději do {date}.",
      "selectedWindowsCombinedTransports": "Vyberte volné místo v jízdním řádu a přidejte doporučení pro kombinovanou dopravu.",
      "slider": {
        "title": "Změna velikosti řádků v harmonogramu."
      },
      "snapToClosestWindowInterval": "Maximální vzdálenost mezi okraji pro automatické navázání časových oken. Navážeme jen jedno okno -> které umístíme jako nové",
      "sumOfPallets": "Součet palet",
      "templates": {
        "copyFrom": "Zkopírovat šablony ze dne",
        "datesWithErrorMessage": "Pozor: Vzhledem ke změně času na letní nebo z letního nebylo možné uložit některá okna v těchto dnech: {windows}.",
        "header": "Šablony časových oken skladu",
        "label": "Šablony",
        "saveFor": "uložit šablonu pro",
        "savedMessage": "Šablona byla úspěšně uložena."
      },
      "timeWindowDefaultLengthInMinutes": "Výchozí velikost časového okna v minutách",
      "timeWindowLengthLongerThanWarehouseWorkingHours": "Časový interval může být delší než pracovní doba skladu.",
      "timeWindowMustOccupyEntireRamp": "Časové okno musí ve zvolený den obsadit celou rampu.",
      "timeWindowMustOccupyEntireRampDisclaimer": "Časové okno v tomto skladu obsadí celou rampu.",
      "timeWindowOffsetInterval": "Počet minut, do kterého lze posouvat časová okna.",
      "transportTypes": "Typy přepravy, pro které bude sklad k dispozici.",
      "updateSuccessMessage": "Nastavení skladu {warehouseName} byla aktualizována.",
      "warehouseLocationLabel": "Umístění",
      "warehouseLocationNextLocation": "Stěhujete se do jiného skladu?",
      "weighingIncluded": "Vážení se provádí ve skladu.",
      "windowConstantTime": "Konstantní hodnota v minutách přidávaná k době vykládky v oknech.",
      "windowIsLoad": "N",
      "windowIsUnload": "V",
      "windowNoteLabel": "Obsah poznámky",
      "windowNoteRemoveWarning": "Chcete odstranit okno s poznámkou: {note}?",
      "windowNoteTitle": "Zadejte poznámku",
      "workEndHour": "Zavírací hodina",
      "workStartHour": "Otvírací hodina",
      "workTime": "Pracovní hodiny",
      "youAreEditingThisWindow": "upravujete toto okno",
      "youAreInWarehouse": "Nacházíte se ve skladu"
    },
    "warehouse-zone": {
      "errors": {}
    },
    "warehouseZone": {
      "addZone": "Přidat zónu skladu",
      "deliveryDefaultZone": "[DELIVERY] Výchozí zóna, do které jsou směrovány dopravní prostředky, které neodpovídají žádnému vzoru",
      "deliveryModeOfTransport": "[DELIVERY] Dopravní prostředky směřující do této skladovací zóny oddělené středníkem, např.: Plandeka Wysoka;Plandeka standard;Plandeka standard EXW",
      "disableConfirm": "Opravdu chcete zakázat tuto zónu?",
      "englishName": "Anglický název zóny",
      "errors": {},
      "hint": "{warehouseZoneLabel} lze změnit přesunutím časového okna v harmonogramu.",
      "idx": "Pořadí zóny",
      "label": "Zóna skladu",
      "selectWarehouse": "Nejprve vyberte {warehouseLabel}",
      "updateSuccessMessage": "Nastavení zóny bylo změněno správně"
    },
    "warehouses": {
      "label": "Sklady"
    },
    "weightLog": {
      "ENTRY_WEIGHT": "Vstupní hmotnost",
      "ENTRY_WEIGHT_AGAIN": "Vstupní hmotnost (opakované vážení)",
      "EXIT_WEIGHT": "Výstupní hmotnost",
      "EXIT_WEIGHT_AGAIN": "Výstupní hmotnost (opakované vážení)"
    },
    "worker": {
      "GUESTS": "Hosté",
      "SERVICEMEN": "Servicemen",
      "WORKERS": "Pracovníci",
      "addGuest": "Přidání nového hosta",
      "addServiceman": "Přidání nového servicemana",
      "addWorker": "Přidání nového pracovníka",
      "autoCompletePlaceholder": "Začněte psát a najděte zaměstnance",
      "deleteWorkerMsg": "Opravdu chcete tohoto pracovníka odstranit?",
      "editGuest": "Upravit hosta",
      "editServiceman": "Upravit servicemana",
      "editWorker": "Upravit pracovníka",
      "errors": {
        "phoneNumber": {
          "regex": "Číslo musí obsahovat 9 až 12 číslic."
        }
      },
      "name": "Jméno a příjmení",
      "phoneNumber": {
        "GUESTS": "Telefonní číslo hosta",
        "SERVICEMEN": "Telefonní číslo servisního technika",
        "WORKERS": "Telefonní číslo zaměstnance"
      },
      "trained": "Vyškolení",
      "workersList": "Seznam pracovníků"
    },
    "workflows": {
      "addSubTask": "Přidání dílčího úkolu",
      "addTask": "Přidat úlohu",
      "finalStageNamesLabel": "Po potvrzení všech fází musí přeprava přejít do stavu",
      "label": "Pracovní postup",
      "noTasks": "Této části procesu nebyla přiřazena žádná úloha",
      "saveSuccessMessage": "Pracovní postup jsme úspěšně uložili.",
      "updateDisclaimer": "Poznámka: změny v čase se projeví jen u NOVÝCH přeprav."
    },
    "wrongUrl": "Nemáte přístup nebo požadovaná stránka neexistuje"
  };
  _exports.default = _default;
});