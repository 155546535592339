define("apollo/pods/delivery/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator", "apollo/models/custom-field-pair"], function (_exports, _emberData, _model, _modelValidator, _customFieldPair) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hasMany,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    deliveryNumber: attr('string'),
    netWeight: attr('number'),
    grossWeight: attr('number'),
    incoterms: attr('string'),
    parentDeliveryNumber: attr('string'),
    description: attr('string'),
    documentType: attr('string'),
    archived: attr('boolean'),
    modeOfTransport: attr('string'),
    freightCost: attr('number'),
    loadStep: belongsTo('step', {
      async: true,
      deepEmbedded: ['warehouse', 'address']
    }),
    unloadStep: belongsTo('step', {
      async: true,
      deepEmbedded: ['warehouse', 'address']
    }),
    freightCostCurrency: belongsTo('currency', {
      async: true
    }),
    invoiceNumber: attr('string'),
    deliveryCustomFieldValues: hasMany('deliveryCustomFieldValue', {
      async: false
    }),
    packaging: hasMany('deliveryPackaging', {
      async: true,
      deepEmbedded: ['packageType']
    }),
    realLoadDate: attr('date'),
    realUnloadDate: attr('date'),
    unloadContactPersonDetails: attr('string'),
    isFromSap: attr('boolean'),
    transportType: belongsTo('transportType', {
      async: true
    }),
    deliverySetting: belongsTo('delivery-setting', {
      async: true
    }),
    validations: {
      deliveryNumber: {
        presence: {
          message: 'blank'
        }
      },
      incoterms: {
        presence: {
          message: 'blankSelect'
        }
      },
      packaging: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireAtLeastOnePackaging')) {
              return model.get('packaging.length') > 0;
            }

            return true;
          },
          message: 'minimumOnePackage'
        }, {
          validation: function (key, value, model) {
            if (model.get('deliverySetting.showSimplePackagingSection')) {
              return model.get('packaging').every(p => p.validate({
                only: ['quantity', 'packageType']
              }));
            }

            if (model.get('packaging.length') === 0) {
              return true;
            }

            if (model.get('deliverySetting.requireStackingFactor')) {
              model.get('packaging').forEach(p => p.validate());
            } else {
              model.get('packaging').forEach(p => p.validate({
                except: ['stackingFactor']
              }));
            }

            return model.get('packaging').every(p => p.get('errors.length') === 0);
          },
          message: ''
        }]
      },
      grossWeight: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireGrossWeight')) {
              return Number(value) > 0;
            }

            return true;
          },
          message: 'positiveNumber'
        }]
      },
      netWeight: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireNetWeight')) {
              return Number(value) > 0;
            }

            return true;
          },
          message: 'positiveNumber'
        }]
      },
      loadStep: {
        custom: [{
          validation: (key, value, model) => {
            const step = model.get('loadStep');
            return model.validateStep(step);
          },
          message: 'blankSelect'
        }]
      },
      unloadStep: {
        custom: [{
          validation: (key, value, model) => {
            const stepData = model.get('unloadStep');
            return model.validateStep(stepData);
          },
          message: 'blankSelect'
        }]
      },
      freightCost: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireFreightCost')) {
              return /^\d+$/.test(value);
            }

            return true;
          },
          message: 'blank'
        }]
      },
      freightCostCurrency: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('deliverySetting.requireFreightCost')) {
              return !!value;
            }

            return true;
          },
          message: 'blankSelect'
        }]
      }
    },
    customFieldPairs: Ember.computed('deliveryCustomFieldValues', function () {
      const dcfv = this.get('deliveryCustomFieldValues');
      return dcfv.map(cf => {
        const definition = cf.get('definition'); // cfy wyświetlamy z pomocą komponentu `custom-field-value-formatted`, który jako value
        // oczekuje całego customfielda, zeby zmapować wartość za pomocą value lub option

        const value = cf;
        return _customFieldPair.default.create({
          definition,
          value
        });
      });
    }),
    validateStep: function (step) {
      const warehouseValid = !!step.get('warehouse.id');
      const addressValid = !!step.get('address.id') || step.get('address.content') && step.get('address.content').validate();
      return warehouseValid || addressValid;
    },
    enabledPackaging: Ember.computed('packaging.@each.enabled', function () {
      return this.get('packaging').filterBy('enabled');
    }),
    enabledPackagingWeight: Ember.computed('enabledPackaging.@each.weight', function () {
      const pckgs = this.get('enabledPackaging');
      let count = 0;
      pckgs.forEach(it => {
        count += Number(it.weight);
      });

      if (count === 0) {
        return '-';
      } else {
        return count;
      }
    }),
    enabledPackagingQuantity: Ember.computed('enabledPackaging.@each.quantity', function () {
      const pckgs = this.get('enabledPackaging');
      let count = 0;
      pckgs.forEach(it => {
        count += Number(it.quantity);
      });

      if (count === 0) {
        return '-';
      } else {
        return count;
      }
    }),
    enabledPackagingLdm: Ember.computed('enabledPackaging.@each.ldm', function () {
      const pckgs = this.get('enabledPackaging');
      let count = 0;
      pckgs.forEach(it => {
        count += Number(it.ldm);
      });

      if (count === 0) {
        return '-';
      } else {
        return count;
      }
    })
  });

  _exports.default = _default;
});