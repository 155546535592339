define("apollo/pods/transaction-setting/model", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    transportType: belongsTo('transportType', {
      async: true
    }),
    showCarrierConfirmationCheckbox: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    requireCarrierConfirmation: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    carrierAutoAcceptsTransport: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    canChooseCarrierFromTransportTable: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    defaultCurrency: attr('string', {
      defaultValue() {
        return 'PLN';
      }

    }),
    defaultInvoiceCurrency: attr('string', {
      defaultValue() {
        return 'PLN';
      }

    }),
    priceLabel: attr('string', {
      defaultValue() {
        return 'FREIGHT';
      }

    }),
    showPriceFieldForDeliveries: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    showReasonField: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    requiresReasonField: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    showAndRequireReasonFieldIfPriceIsZero: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    showPriceField: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    showExpectedConfirmationDate: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    showUnitAmountType: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    requireUnitAmountType: attr('boolean', {
      defaultValue() {
        return false;
      }

    })
  });

  _exports.default = _default;
});