define("apollo/pods/transaction/modals/edit-freight/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    title: Ember.computed('', function () {
      return this.get('intl').t('transaction.label');
    }),
    transaction: Ember.computed('model.transaction', function () {
      return this.get('model.transaction');
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    priceUpdateDisabled: Ember.computed('transaction.routeOffer.id', function () {
      return !!this.get('transaction.routeOffer.id');
    }),
    actions: {
      editFreight: async function () {
        const transaction = this.get('transaction');

        if (!transaction.validate({
          only: 'price'
        })) {
          return;
        }

        const data = JSON.stringify({
          freight: transaction.get('price'),
          currencyId: transaction.get('currency.id'),
          unitAmountTypeId: transaction.get('unitAmountType.id')
        });
        const path = this.get('apolloApiService').APOLLO_API.TRANSACTIONS.EDIT_FREIGHT;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            id: transaction.get('id')
          }, {
            body: data
          });

          if (response.ok) {
            this.set('errors', []);
            (0, _jquery.default)('#main-modal').data('skip-cancel-action', true);
            this.send('hideModal');
          } else {
            const error = await response.json();
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },
      cancel: function () {
        console.debug('Wycofujemy zmiany w okienku edycji frachtu...');
        this.get('transaction').rollbackAttributes(); // ember nie umie wycofywać zmian w relacjach :/

        this.set('transaction.currency', this.get('model.initialCurrency'));
      },
      close: function () {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});