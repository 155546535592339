define("apollo/pods/transaction-unit-amount-types/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal-service'),
    actions: {
      add: function () {
        const transactionUnitAmountType = this.get('store').createRecord('transactionUnitAmountType');
        this.get('modalService').showModal(this.get('modalService').MODALS.TRANSACTION_UNIT_AMOUNT_TYPE.FORM, {
          transactionUnitAmountType
        });
      },
      edit: function (transactionUnitAmountType) {
        this.get('modalService').showModal(this.get('modalService').MODALS.TRANSACTION_UNIT_AMOUNT_TYPE.FORM, {
          transactionUnitAmountType
        });
      }
    }
  });

  _exports.default = _default;
});