define("apollo/pods/transaction-unit-amount-types/modals/form/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    modalTitle: Ember.computed('model.transactionUnitAmountType.id', function () {
      return this.get('model.transactionUnitAmountType.id') ? this.get('intl').t('common.edit') : this.get('intl').t('common.add');
    }),
    actions: {
      save() {
        const self = this;
        const transactionUnitAmountType = this.get('model.transactionUnitAmountType');

        if (!transactionUnitAmountType.validate()) {
          self.set('errors', []);
          return;
        }

        transactionUnitAmountType.save().then(() => {
          self.set('errors', []);
          self.send('refreshRoute');
          self.send('hideModal');
        }).catch(response => {
          console.debug(response);
          response.errors ? this.set('errors', response.errors) : this.set('errors', [this.get('intl').t('common.defaultError')]);
        });
      },

      cancel() {
        this.get('model.transactionUnitAmountType').rollbackAttributes();
        this.send('hideModal');
      }

    }
  });

  _exports.default = _default;
});