define("apollo/pods/transaction-unit-amount-types/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_MANAGE_TRANSACTION_UNIT_AMOUNT_TYPES']),
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: async function () {
      return Ember.RSVP.hash({
        transactionUnitAmountTypes: await this.store.findAll('transaction-unit-amount-type')
      });
    },
    actions: {
      refreshRoute: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});