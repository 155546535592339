define("apollo/pods/components/unit-amount-type-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    unitAmountTypes: Ember.A([]),

    async init() {
      this._super(...arguments);

      const types = await this.store.findAll('transaction-unit-amount-type');
      this.set('unitAmountTypes', types);
    }

  });

  _exports.default = _default;
});