define("apollo/pods/cmr-setting/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    transportType: belongsTo('transportType'),
    appendedDocsText: attr('string'),
    appendedDocsSpecificationNumber: attr('boolean'),
    appendedDocsSpecificationCustomField: belongsTo('customFieldDefinition'),
    featuresAndNumbersText: attr('string'),
    featuresAndNumbersCustomField: belongsTo('customFieldDefinition'),
    quantityText: attr('string'),
    quantityCustomField: belongsTo('customFieldDefinition'),
    packageTypeText: attr('string'),
    weightGrossCustomField: belongsTo('customFieldDefinition'),
    senderInstructionText: attr('string'),
    senderInstructionCustomFields: hasMany('customFieldDefinition'),
    showPrincipalName: attr('boolean'),
    showWarehouseName: attr('boolean'),
    validations: {
      senderInstructionText: {
        custom: [{
          validation: function (key, value) {
            if (!value) {
              return true;
            }

            const maxLinesCount = 8;
            const linesCount = value.split('\n').length;
            return linesCount <= maxLinesCount;
          },
          message: 'maxLineCountIs8'
        }]
      }
    }
  });

  _exports.default = _default;
});